import './extracSpeficPeriod.css'
import { useParams } from "react-router-dom"
import { FormCustom } from "../../components/Form"
import { Button, Form } from "react-bootstrap"
import { z } from "zod"
import { FormProvider, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { CardExtract, ICardExtractProps } from './components/card-extract/CardExtract'
import { getExtractSpeficPeriod } from '../../api/extractSpeficPeriod/extractSpeficPeriod'
import { IExtractSpecificPeriodParams } from '../../interfaces/extractSpeficPeriod/extractSpeficPeriod.interface'
import {  useRef, useState } from 'react'
import {  downloadImage, getDateLocale, tranformeValideStringDate } from '../../utils/Helper'
import { useExtracSpeficPeriod } from './hook/extracSpeficPeriod'
import html2canvas from "html2canvas"


const schemaExtractSpeficPeriod = z.object({
  end_date: z.coerce.string(),
  start_date: z.coerce.string()
})

export type IExtraactSpeficPeriodFormData = z.infer<typeof schemaExtractSpeficPeriod>

export const ExtractSpeficPeriod: React.FC = () => {
  const { groupId } = useParams()
  const { getDataByResponse } = useExtracSpeficPeriod()
  const exportRef = useRef<HTMLDivElement>(null);
  const form = useForm<IExtraactSpeficPeriodFormData>({
    resolver: zodResolver(schemaExtractSpeficPeriod)
  })

  const { handleSubmit, getValues, watch } = form

  const onSubmit = (data: IExtraactSpeficPeriodFormData) => {
    getExtract({
      end_date: tranformeValideStringDate(new Date(data.end_date)),
      id_group: +groupId!,
      start_date: tranformeValideStringDate(new Date(data.start_date))
    })
  }

  const [extract, setExtract] = useState<ICardExtractProps[]>([])

  const handleCapture = async () => {
    if (exportRef.current) {
      const canvas = await html2canvas(exportRef.current);
      const dataURL = canvas.toDataURL();

      downloadImage(dataURL, 'captured_image.png')
    }
  }

  const getExtract = ({ end_date, start_date }: IExtractSpecificPeriodParams) => {
    getExtractSpeficPeriod({
      end_date: end_date,
      id_group: +groupId!,
      start_date: start_date
    }).then((response) => {
      setExtract(getDataByResponse(response.data))
    })
  }

  const startDate = watch('start_date')
  const endDate = watch('end_date')

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div ref={exportRef} className="extract_container">
          <h1>Relatório por período</h1>
          {
            (startDate !== '' && endDate !== '') && (
              <h3>Período em exibição:  {getDateLocale(startDate, 'pt-BR')} até {getDateLocale(endDate, 'pt-BR')}</h3>
            )
          }
          <div className="extract_container__form">
            <Form.Group>
              <Form.Label htmlFor="start_date">Data inicial</Form.Label>
              <FormCustom.Control type="date" id="start_date" field="start_date" />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="end_date">Data final</Form.Label>
              <FormCustom.Control type="date" field="end_date" />
            </Form.Group>
          </div>

          <div className="extract_container_btn_send">
            <Button variant="primary" type="submit">Gerar relatório</Button>
          </div>

          <div className='extract_container_extract'>
            {
              extract.map((item, index) => (
                <CardExtract
                  cols={item.cols}
                  data={item.data}
                  title={item.title}
                  subtitle={item.subtitle}
                  descriptions={item.descriptions}
                  key={index}
                />
              ))
            }
          </div>

          {
            extract.length > 0 && (
              <div>
                <Button
                  variant="primary"
                  type="submit"
                  className="float-end mb-3 mr-1 m-1"
                  onClick={handleCapture}
                >
                  Imprimir/Exportar
                </Button>
              </div>
            )
          }
        </div>
      </Form>
    </FormProvider>
  )
}