import React, { useEffect } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { phoneMask } from "../../utils/Helper";
import { FormCustom } from ".";

interface IFormPhoneProps extends FormControlProps {
  field: string
  showError?: boolean
}

export const FormPhone: React.FC<IFormPhoneProps> = ({
  field,
  showError = true,
  ...rest
}) => {

  const {
    register,
    watch,
    setValue,
    formState: { errors}
  } = useFormContext()

  const phoneWatch = watch(field)

  useEffect(() => {
    if (phoneWatch) {
      setValue(field, phoneMask(phoneWatch))
    }
  }, [phoneWatch])

  return (
    <>
      <Form.Control
        className="rounded-4"
        isInvalid={errors[field] ? true : false}
        {...register(field)}
        {...rest}
      />
      {
        showError ? (
          <FormCustom.ErrorMessage field={field} />
        ) : ''
      }
    </>
  )
}