import { Fragment } from 'react'
import { useDashboardContext } from '../../../../../../contexts/Dashboard'
import './Ranking.css'
import { getCurrentYear, getHexdecimalColor } from '../../../../../../utils/Helper'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const Ranking: React.FC = () => {
  const { ranking } = useDashboardContext()
  const { t } = useTranslation()

  const scoreYear = Object.entries(ranking).map(([key, value]) => {
    return {
      month: key,
      value: value
    }
  })

  return (
    <div className="ranking-container">
      <h2>Meu Ranking / Pontuação</h2>

      <div className="ranking-container__table">
        <h3>{getCurrentYear()}</h3>
        <hr />
        {
          scoreYear.map((item, index) => (
            <Fragment key={index} >
              <div className="ranking-container__table__items">
                <span>{t(item.month)}</span>
                <div className='ranking-container__table__items--puctions'>
                  <span>{item.value}</span>
                  <div className='ranking-container__table__items--cicle' style={{ backgroundColor: getHexdecimalColor(item.value as unknown as number) }}></div>
                </div>
              </div>

              <hr />
            </Fragment>
          ))
        }
      </div>

      <div className='ranking-container__table__extact'>
        <div className='ranking-container__table__extact__header'>
          <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/div.icon-box-img.svg`} alt="icon" />
          <span>Extrato</span>
        </div>
        <Link   to="extract-spefic-period">
         <button className='ranking-container__table__extact--btn__specific-period'>Período Específico</button>
        </Link>
        <Link to="pontuacao-mensal">
          <button className='ranking-container__table__extact--btn__score-extract'>Extrato de pontuação</button>
        </Link>
      </div>
    </div>
  )
}