import { useCallback, useEffect, useState } from "react";
import { listAllGroupResponse } from "../interfaces/list/list.interface";
import { listAllGroup } from "../api/list/list";
import { ISelectDefault } from "../interfaces/form/form.interface";
import useAuth from "../contexts/Auth";



export function useGroup() {
    const { infoUser } = useAuth()

    const [groups, setGroups] = useState<listAllGroupResponse[]>([])
    const [selectGroup, setSelectGroup] = useState<ISelectDefault[]>([])
    const [selectGroupDefault, setSelectGroupDefault] = useState<ISelectDefault[]>([])


    const fetchGroups = useCallback( async () => {
        const groups = await listAllGroup()

        if (groups) {
            setGroups(groups.data)

            const selectGroup = groups.data.map((group: listAllGroupResponse) => {
                return {
                    label: group.name,
                    value: group.id
                }
            })

            const defaultGroup = infoUser.group.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })

            setSelectGroup(selectGroup)
            setSelectGroupDefault(defaultGroup)
        }
    }, [])
    
    useEffect(() => {
        fetchGroups()
    }, [])

    return { groups, selectGroup, selectGroupDefault }
}