import { ErrorMessage } from './ErroMessage'
import { FormControl } from './FormControl'
import { FormCurrency } from './FormCurrency'
import { FormCurrencyDefault } from './FormCurrencyDefault'
import { FormDocuments } from './FormDocuments'
import { FormLang } from './FormLang'
import { FormPhone } from './FormPhone'
import {FormPhoto} from './FormPhoto'
import { FormSelect } from './FormSelect'
import { FormStatus } from './FormStatus'

export const FormCustom = {
  ErrorMessage,  
  Select: FormSelect, 
  Control: FormControl,
  Currency: FormCurrency,
  Phone: FormPhone,
  Documents: FormDocuments,
  Lang: FormLang,  
  Photo: FormPhoto,
  Status: FormStatus,
  CurrencyWithouDecimal: FormCurrencyDefault
}