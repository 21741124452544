import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { showIndication, updateIndication } from "../../../api/indications/indications"
import { useEffect, useState } from "react"
import { IIndicationShow } from "../../../interfaces/indications/indications.interface"
import { FormCustom } from "../../../components/Form"
import { useGroup } from "../../../hooks/useGroup"
import { useUserByGroup } from "../../../hooks/useUserByGroup"
import { FormProvider, useForm } from "react-hook-form"
import { z } from "zod"
import { getOnlyNumberPhone, phoneMask, tranformeValideStringDate } from "../../../utils/Helper"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "react-i18next"
import { useShowIndication } from "../../../hooks/useShowIndication"

const updateIndicationSchema = z.object({    
    company: z.string({ required_error: 'empresa é obrigatório' }),
    contact: z.string({ required_error: 'contato é obrigatório' }),
    phone: z.string({ required_error: 'telefone é obrigatório' }).transform((value) => getOnlyNumberPhone(value)),
    email: z.string({ required_error: 'email é obrigatório' }).email({message: 'email deve ser valido'}),
    obervations: z.string({ required_error: 'observações é obrigatório' }),
    id_group_indications: z.coerce.number({ required_error: 'grupo é obrigatório' }).positive(),
    id_user_indications: z.coerce.number({ required_error: 'usuário é obrigatório' }).positive(),
    indications_date: z.coerce.date({ required_error: 'data é obrigatório' }),
})

export type UpdateIndicationFormData = z.infer<typeof updateIndicationSchema>


export const ShowIndication: React.FC = () => {

    const { id_indication } = useParams()
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { indication } = useShowIndication({ id_indication: +id_indication! })
    const { selectGroup } = useGroup()
          
    const updateIndicationFormData = useForm<UpdateIndicationFormData>({
        resolver: zodResolver(updateIndicationSchema)
    })

    const handleGoBack = () => {
        navigate('../')
    }

    const {
        watch,
        setValue,
        handleSubmit,
        setFocus,
    } = updateIndicationFormData
   
    const id_group_indications = watch('id_group_indications')
    const phone = watch('phone')

    const { selectUser } = useUserByGroup({ id_group: id_group_indications })
    
    const isDisabled = () => {
        return indication?.business?.status !== 'INDICATION'        
    }

    useEffect(() => {    
        setValue('company', indication.company)
        setValue('contact', indication.contact)
        setValue('phone', phoneMask(indication.phone))
        setValue('email', indication.email)
        setValue('obervations', indication.observations)
        setValue('id_group_indications', indication.id_group_indication)
        setFocus('indications_date')

        setTimeout(() => {
            setValue('id_user_indications', indication.id_user_indication)
        }, 2000);
    }, [indication.company])

    const onSubmit = async (data: UpdateIndicationFormData) => {
        await updateIndication(indication.id, data)        
    }

    useEffect(() => {
        setValue('phone', phoneMask(phone))
    }, [phone])    

 
    return (
       <div className="m-2">
            <Container fluid>
                <FormProvider {...updateIndicationFormData}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="border border-secondary border-2 rounded p-4">
                            <Form.Group>
                                <Form.Label>Empresa</Form.Label>
                                <FormCustom.Control disabled={isDisabled()} className="w-25" field="company"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Contato</Form.Label>
                                <FormCustom.Control disabled={isDisabled()} className="w-25" field="contact"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Telefone</Form.Label>
                                <FormCustom.Control disabled={isDisabled()} className="w-25" field="phone"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>E-mail</Form.Label>
                                <FormCustom.Control disabled={isDisabled()} className="w-25" field="email"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Data da indicação</Form.Label>
                                <FormCustom.Control disabled={isDisabled()} defaultValue={indication.indication_date ? tranformeValideStringDate(new Date(indication.indication_date)) : ''} className="w-25" type="date" field="indications_date"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Observação</Form.Label>
                                <FormCustom.Control disabled={isDisabled()} className="w-25" as="textarea" field="obervations" style={{ height: '100px' }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Indicado para: </Form.Label>
                            </Form.Group>
                            <Form.Group>                
                                <Form.Label>Group </Form.Label>
                                <FormCustom.Select disabled={isDisabled()} className="w-25" field="id_group_indications" options={selectGroup}/>                
                            </Form.Group>  
                            <Form.Group>
                                <Form.Label>Membro indicado </Form.Label>
                                <FormCustom.Select disabled={isDisabled()} className="w-25" field="id_user_indications" options={selectUser}/>                
                            </Form.Group>
                        </Row>
                        <Row className="justify-content-md-end">
                            <Col xl={3} sm={3} md={3} className="mt-3">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="float-end mb-3 mr-1 m-1"
                                    disabled={isDisabled()}
                                >
                                {t("submit")}
                                </Button>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    className="float-end mb-3 mr-1 m-1"
                                    onClick={handleGoBack}
                                >
                                    {t("goback")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </FormProvider>          
            </Container>
       </div>

    )
}