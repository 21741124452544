import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import { IGetPermission } from '../../interfaces/permission/IGetPermission'
import { IPermission } from '../../interfaces/permission/IPermission'
import { IGetRoleById } from '../../interfaces/role/IGetRoleById'
import { IGetRoles } from '../../interfaces/role/IGetRoles'
import { ISaveRole } from '../../interfaces/role/ISaveRole'
import { IDeleteRole, IUpdateRole } from '../../interfaces/role/IUpdateRole'
import { IRolePermissionsResponse, IRoleResponse } from '../../interfaces/role/Role.interface'
import api from '../Api'

export const getRoles = async ({ groupId, meta }: IGetRoles) => {
    return await api.get<IResponseGeneric<IRoleResponse>>(`group/${groupId}/role${meta}`)
}

export const getAllRoles = async ({ groupId }: IGetRoles) => {
    return await api.get<IRoleResponse[]>(`group/${groupId}/role/all`)
}

export const getRoleById = async ({ groupId, roleId }: IGetRoleById) => {
    return await api.get(`group/${groupId}/role/${roleId}`)
}

export const getPermissions = async ({ groupId }: IGetPermission) => {
    return await api.get<IPermission[]>(`group/${groupId}/permission`)
}

export const getPermissionsWithRole = async ({ groupId, roleId }: IGetRoleById) => {
    return await api.get<IRolePermissionsResponse>(`group/${groupId}/role/${roleId}/permission`)
}

export const saveRole = async ({ groupId, name, key, permissions }: ISaveRole) => {
    return await api.post(`group/${groupId}/role`, { name, key, permissions })
}

export const updateRole = async ({ groupId, roleId, key, name, permissions }: IUpdateRole) => {
    return await api.put(`group/${groupId}/role/${roleId}`, { key, name, permissions })
}

export const deleteRole = async ({ groupId, roleId }: IDeleteRole) => {
    return await api.delete(`group/${groupId}/role/${roleId}`)
}


