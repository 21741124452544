import React from 'react'
import { useAuth } from '../contexts/Auth'
import AuthRoutes from './AuthRoutes'
import NonAuthRoutes from './NonAuthRoutes'

const Routes: React.FC = () => {
    const { signed } = useAuth()
    return signed ? <AuthRoutes /> : <NonAuthRoutes />
}

export default Routes