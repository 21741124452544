import "./homeScreen.css";
import React, { useEffect, useState } from "react";
import { UploadFile } from "../../../components/Form/UploadFile";
import { IDashboardImagesResponse } from "../../../interfaces/dashboard/dashboard.interface";
import { destroyImages, getImages } from "../../../api/dashboard/dashboard";
import { useParams } from "react-router-dom";

export const HomeScreen: React.FC = () => {
  const { groupId } = useParams()  
  const [images, setImages] = useState<IDashboardImagesResponse[]>([])
  
  const fetchImages = async () => {    
    getImages(+groupId!).then((response) => {            
      setImages(response.data)      
    })        
  }
  
  const handleDelete = (id: number) => {
    destroyImages(+groupId!, id).then(() => {
      fetchImages()
    })    
  }

  const save = (id: number) => {
    fetchImages()
  }

  useEffect(() => {
    fetchImages()
  }, [])

  return (
    <div className="home-screen-container vh-100">
      <h1>Incluir imagens na Tela Inicial</h1>
      <p>Suas imgens</p>

      <div className="home-screen__images__wrapper">
        {
          images.map((image) => (

            <div key={image.id} className="home-screen__images__wrapper__card">
              <div className="home-screen__images__wrapper__card--img">
                <img src={image.url} alt="img" />
              </div>
              <div className="home-screen__images__wrapper__card--action">
                <img onClick={() => handleDelete(image.id)} src={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/trash.svg`} alt="trash" />
              </div>
            </div>

          ))
        }

        <UploadFile eventResponse={save} />
      </div>
    </div>
  )
}