import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap"
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from "react-hook-form"
import { FormCustom } from "../../../components/Form"
import { destroy, getReason, saveReason } from "../../../api/reason/reason"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { IResponseGeneric } from "../../../interfaces/generic/generic.interface"
import { IReasonResponse } from "../../../interfaces/reason/reason.interface"
import { useTranslation } from "react-i18next"
import ConfirmationModal from "../../../components/custom/ConfirmationModal"
import { ITableComponentCols } from "../../../components/Table/TableComponent"
import { TableCustomComponent } from "../../../components/Table"
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent"
import { ITableSearchFormData } from "../../../components/Table/TableSearchComponent"
import { searchFactory } from "../../../factory/searchFactory"
import useGuard from "../../../contexts/Guard"

const createReasonSchema = z.object({
  name: z.string().nonempty('Motivo é obrigatório')
})

type ICreateReasonFormData = z.infer<typeof createReasonSchema>

export const CreateReason: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { CheckPermissionWithArray } = useGuard()
  const [reason, setReason] = useState<IResponseGeneric<IReasonResponse>>({} as IResponseGeneric<IReasonResponse>)
  const [showDelete, setShowDelete] = useState(false)
  const { groupId } = useParams<string>()
  const [rowSelected, setRowSelected] = useState<IReasonResponse>({} as IReasonResponse)
  const [activeTab, setActiveTab] = useState<number>(0);
  const [paramsSearch, setParamsSearch] = useState('')

  const columns: ITableComponentCols[] = [
    {
      title: 'Motivos', render: (row: IReasonResponse) => (
        <span>{row.name}</span>
      )
    },
    {
      title: 'Ações',
      permissions: ['reason.delete', 'reason.update'],
      render: (row: any) => (
        <>
          <div>
            {CheckPermissionWithArray(['reason.update']) && (
              <Button
                variant="primary"
                type="button"
                className="w-20 me-4"
                onClick={() => {
                  navigate(`${row.id}`);
                }}
              >
                {t('actionEdit')}
              </Button>
            )}

            {CheckPermissionWithArray(['reason.delete']) && (
              <Button
                variant="danger"
                type="button"
                onClick={() => {
                  setRowSelected(row)
                  setShowDelete(true)
                }}
              >
                {t('actionDelete')}
              </Button>
            )}
          </div>
        </>
      )
    }
  ]

  const handleGoBack = () => {
    setActiveTab(0);
  }

  const createReasonForm = useForm<ICreateReasonFormData>({
    resolver: zodResolver(createReasonSchema)
  })

  const onSubmit = (data: ICreateReasonFormData) => {
    saveReason({
      groupId: +groupId!,
      name: data.name
    }).then(() => {
      getReasonALl()
      reset()
    })
  }

  const {
    register,
    handleSubmit,
    reset,
  } = createReasonForm

  const getReasonALl = async () => {
    const params = searchFactory().createUrlParams({
      page: 1,
    })

    const { data } = await getReason({
      groupId: +groupId!,
      params: params
    })

    setReason(data)
  }

  const reasonSearch = async ({ search }: ITableSearchFormData) => {
    const params = searchFactory().createUrlParams({
      page: 1,
      name: search
    })
    setParamsSearch(search)
    const { data } = await getReason({
      groupId: +groupId!,
      params: params
    })

    setReason(data)
  }
  const reasonPaginator = async ({ page }: IPaginationsCallback) => {

    const params = searchFactory().createUrlParams({
      page,
      name: paramsSearch
    })

    const { data } = await getReason({
      groupId: +groupId!,
      params: params
    })

    setReason(data)
  }

  const onDelete = () => {
    destroy({
      groupId: +groupId!,
      idReason: rowSelected.id
    }).then(() => {
      handleCloseModal()
      getReasonALl()
    })
  }

  const handleCloseModal = () => {
    setShowDelete(false)
  }

  useEffect(() => {
    getReasonALl()
  }, [groupId])

  return (
    <Container fluid className="p-4 vh-100">
      <Row>
        <Col>
          <Tabs
            activeKey={activeTab}
            onSelect={(index) => setActiveTab(+index!)}
          >
            <Tab
              eventKey={0}
              title='Listagem de Motivos'
            >
              <TableCustomComponent.Root>
                <Col>
                  <TableCustomComponent.Search
                    callback={reasonSearch}
                  />
                </Col>
                <Col>
                  <TableCustomComponent.Table
                    striped
                    data={reason.data}
                    cols={columns}
                  />
                </Col>
                <Col>
                  <TableCustomComponent.Pagination
                    {...reason.meta}
                    callback={reasonPaginator}
                  />
                </Col>
              </TableCustomComponent.Root>
            </Tab>
            {CheckPermissionWithArray(['reason.store']) && (
              <Tab
                eventKey={1}
                title='Criar Motivos'
              >
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormProvider {...createReasonForm}>
                    <Row style={{ height: '70vh' }}>
                      <Form.Group>
                        <Form.Label htmlFor="rason">Motivo</Form.Label>
                        <Form.Control type="text" id='rason' {...register('name')} />
                        <FormCustom.ErrorMessage field="name" />
                      </Form.Group>
                    </Row>
                  </FormProvider>

                  <Row className="justify-content-md-end">
                    <Col xl={3} sm={3} md={3} className="mt-3">
                      <Button
                        variant="primary"
                        type="submit"
                        className="float-end mb-3 mr-1 m-1"
                      >
                        {t("submit")}
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="float-end mb-3 mr-1 m-1"
                        onClick={handleGoBack}
                      >
                        {t("goback")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Tab>
            )}
          </Tabs>
        </Col>
      </Row>

      <ConfirmationModal
        show={showDelete}
        message={t("messageConfirmationModal")}
        onConfirm={onDelete}
        onDecline={handleCloseModal}
      />
    </Container>
  )
}