import React from 'react'
import './SystemSupport.css'

export const SystemSupport: React.FC = () => {
  return (
    <div className='system-support-container'>
      <div className='system-support-container__header'>
        <span>Suporte ao sistema</span>
        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/suporte-tecnico.svg`} alt="icon" />
      </div>
      <div className='system-support-container__footer'>
        <p>Em caso de problema, envie o um e-mail para:</p>
        <p><span>Email: </span><a href="mailto:nexus@itaccept.zohodesk.com">nexus@itaccept.zohodesk.com</a></p>
      </div>
    </div>
  )
}