import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useAuth from "../../../contexts/Auth";
import { TitlePage } from "../components/TitlePgae";
import { ButtonPrintScreen } from "../../../components/custom/ButtonPrintScreen";
import { IRankingReport, RankingType } from "../../../interfaces/report/IRanking";
import { getRankMonthlyReport } from "../../../api/report/report";
import { ItemRank } from "./ItemRank/ItemRank";
import { LoadingReport } from "../components/LoadingReport";
import useGuard from "../../../contexts/Guard";

export const RankingReport = () => {
  const { t } = useTranslation()
  const { infoUser } = useAuth()
  const { CheckPermissionWithArray } = useGuard()

  const exportRef = useRef<HTMLDivElement>(null);
  const rankTypeFilterRef = useRef<RankingType>(RankingType.ACTUAL_MONTHLY);

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listRankMonthly, setListRankMonthly] = useState<IRankingReport | null>(null)

  const rankTypes = Object.entries(RankingType).map(([key, value]) => {
    return { key, value }
  }) 

  const getReport = async () => {
    try {
      setIsLoading(true)

      const { data } = await getRankMonthlyReport({
        groupId: infoUser.group_selected.id,
        type: rankTypeFilterRef.current
      })

      setListRankMonthly(data.data)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeRankType = (e: ChangeEvent<HTMLSelectElement>) => {
    rankTypeFilterRef.current = e.target.value as RankingType
    getReport()
  }

  useEffect(() => {
    getReport()
  }, [])

  return (
    <Container fluid  ref={exportRef}>
       <Row>
        <TitlePage title="report_ranking_monthly" />
      </Row>

      <Row>
        <Col>
          <FormGroup className="d-flex flex-row align-items-baseline mt-2">
            <Form.Select
              onChange={onChangeRankType}
              className="rounded-4"
              style={{ width: '200px' }}
              disabled={isLoading} 
            >
              {
                rankTypes.map(type => (
                  <option 
                    key={type.key} 
                    value={type.value}
                    selected={type.value === rankTypeFilterRef.current}
                  >
                    { t(type.value) }
                  </option>
                ))
              }
            </Form.Select>

            <Form.Label className="fs-6 ms-2">{t('select_type_report')}</Form.Label>
          </FormGroup>
        </Col>
      </Row>

      <div>
        {
          isLoading && <LoadingReport />
        }

        {
          !isLoading && listRankMonthly && (
            <Row className="d-flex mt-4">
              {
                <Col lg={5} key={listRankMonthly.period}>
                  <ItemRank 
                    description={listRankMonthly.period}
                    rankItems={listRankMonthly.items}
                  />
                </Col>
              }
            </Row>
          )
        }
      </div>

      {
        CheckPermissionWithArray(['generate_report']) && !isLoading && listRankMonthly && (
          <Row className="pb-5">
            <Col lg={2} className="me-auto">
              <ButtonPrintScreen
                text={t('printExport')}
                refElement={exportRef}
                fileNameDownload="captured_image.png"
              />
            </Col>
          </Row>
        )
      }
    </Container>
  );
};
