import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

interface ErrorMessageProps {
  field: string
}

function get(obj: Record<any, any>, path: string) {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);

  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);

  return result
};

export function ErrorMessage({ field }: ErrorMessageProps) {
  const { t } = useTranslation()

  const { formState: { errors } } = useFormContext()

  const fieldError = get(errors, field)
  if (!fieldError) {
    return null
  }

  return (
    <Form.Text style={{ color: 'red' }}>{t(fieldError.message?.toString())}</Form.Text>
  )
}