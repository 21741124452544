import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FormCustom } from "../../../components/Form";
import { z } from 'zod'
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod'
import { showReason, updateReason } from "../../../api/reason/reason";
import { useNavigate, useParams } from "react-router-dom";
import { IReasonResponse } from "../../../interfaces/reason/reason.interface";
import { useTranslation } from "react-i18next";


const editReasonSchema = z.object({
  name: z.string().nonempty('form_name_required')
})

type IEditReasonFormData = z.infer<typeof editReasonSchema>

export const EditReason: React.FC = () => {
  const { t } = useTranslation()
  const [reason, setReason] = useState<IReasonResponse>({} as IReasonResponse)
  const { groupId, reasonId } = useParams()
  const navigate = useNavigate()

  const editReasonForm = useForm<IEditReasonFormData>({
    resolver: zodResolver(editReasonSchema)
  })

  const {
    register,
    handleSubmit,
    setValue
  } = editReasonForm

  const onSubmit = (data: IEditReasonFormData) => {
    updateReason({
      groupId: +groupId!,
      idReason: +reasonId!,
      name: data.name
    })
  }

  const getReasonShow = () => {
    showReason({
      groupId: +groupId!,
      idReason: +reasonId!
    }).then((response) => {
      setValue('name', response.data.name)
      setReason(response.data)
    })
  }

  const handleGoBack = () => {
    navigate(`../`)
  }

  useEffect(() => {
    getReasonShow()
  }, [groupId])

  return (
    <Container fluid className="p-4">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...editReasonForm}>
          <Form.Group style={{ height: '80vh' }}>
            <Form.Label htmlFor="reason">Motivo</Form.Label>
            <Form.Control type="text" id='reason' {...register('name')} />
            <FormCustom.ErrorMessage field="name" />
          </Form.Group>
          <Row className="justify-content-md-end">
            <Col xl={3} sm={3} md={3} className="mt-3">
              <Button variant="primary" type="submit" className="float-end mb-3 mr-1 m-1">
                {t('submit')}
              </Button>
              <Button variant="secondary" type="button" className="float-end mb-3 mr-1 m-1" onClick={handleGoBack}>
                {t('goback')}
              </Button>            
            </Col >
          </Row >
        </FormProvider>

      </Form>
    </Container>
  )
}