import { useTranslation } from 'react-i18next';
import { FormCustom } from '../../../../components/Form';
import { IPunctuationUpdateConfigConfigs } from '../../../../interfaces/score/score.interface';
import './point-card.css'
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import useGuard from '../../../../contexts/Guard';

export interface IPointCardProps {
  title: string;
  configs: IPunctuationUpdateConfigConfigs[]
  indexField: number
  recurrence: number | null
}

export const PointCard: React.FC<IPointCardProps> = ({
  configs,
  indexField,
  recurrence,
  title,
}) => {
  const { t } = useTranslation();
  const {setValue } = useFormContext();
  const { CheckPermissionWithArray } = useGuard()

  const formatedInput = (value: string, path: string) => {
    const currency = +value.replace(/\D+/g, '')
    setValue(path, currency)
  }

  const canUpdate = (): boolean => {
    return CheckPermissionWithArray(['point.update'])
  }

  return (

    <div className='point-container'>
      <div className='point-container__form'>
        <div className='point-container__left'>
          <p>{t(title)}</p>
        </div>
        <div className='point-container__points'>
          {
            title === 'TRAINING_MEETING' ? (
              <Fragment>
                <p>Se o membro tiver X ou mais</p>
                <p>Pontuar com Y pontos</p>
              </Fragment>
            ) : (
              <Fragment>
                <p>Se o membro tiver até X</p>
                <p>Pontuar com Y pontos</p>
              </Fragment>
            )            
          }
          

          {
            title !== 'BUSINESS_CLOSED' && configs.map((config, index) => (
              <Fragment key={index}>
                <FormCustom.Control disabled={!canUpdate()} type='number' field={`config.${indexField}.configs.${index}.punctuation_x`} />
                <FormCustom.Control disabled={!canUpdate()} type='number' field={`config.${indexField}.configs.${index}.punctuation_y`} />
                <FormCustom.ErrorMessage field={`config.${indexField}.configs.${index}.punctuation_x`} />
                <FormCustom.ErrorMessage field={`config.${indexField}.configs.${index}.punctuation_y`} />
              </Fragment>

            ))
          }

          {
            title == 'BUSINESS_CLOSED' && configs.map((config, index) => (
              <Fragment key={index}>
                <FormCustom.CurrencyWithouDecimal 
                  onChange={
                    (e) => {
                      formatedInput(e.target.value, `config.${indexField}.configs.${index}.punctuation_x`)
                    }
                  } 
                  defaultValue={config.punctuation_x.toString()}
                  field={`config.${indexField}.configs.${index}.punctuation_x`} 
                  disabled={!canUpdate()}
                />
                <FormCustom.Control disabled={!canUpdate()} type='number' field={`config.${indexField}.configs.${index}.punctuation_y`} />
                <FormCustom.ErrorMessage field={`config.${indexField}.configs.${index}.punctuation_x`} />
                <FormCustom.ErrorMessage field={`config.${indexField}.configs.${index}.punctuation_y`} />
              </Fragment>

            ))
          }
        </div>
      </div>

      {
        recurrence &&
        <>
          <div className='point-container__title'>
            <p>Quantos meses os pontos irão perdurar</p>
          </div>
          <div className='point-container__recurrence'>
            <div className='point-container__recurrence__title'>
              <p>Recorrência</p>
            </div>
            <div className='point-container__recurrence__form'>
              <FormCustom.Control  disabled={!canUpdate()} field={`config.${indexField}.recurrence`} />
            </div>
          </div>
        </>
      }
    </div>
  )
}