import { useState, useEffect } from "react";
import { IResponseGeneric } from "../../../interfaces/generic/generic.interface";
import { IIndicationsResponse } from "../../../interfaces/indications/indications.interface";
import { litIndications } from "../../../api/indications/indications";
import { searchFactory } from "../../../factory/searchFactory";
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent";
import { ITableSearchFormData } from "../../../components/Table/TableSearchComponent";

export const useIndications = (group_id: number) => {
  const [indications, setIndications] = useState<IResponseGeneric<IIndicationsResponse>>(
    {} as IResponseGeneric<IIndicationsResponse>  
  );
  const [searchParams, setSearchParams] = useState('')

  const fetchIndicationsSearch = async (params: ITableSearchFormData) => {
    setSearchParams(params.search)
    const urlParams = searchFactory().createUrlParams({
      contact: params.search,
      page: 1,
      perPage: 10
    });

    const { data } = await litIndications(group_id, urlParams);

    if (data) {
      setIndications(data);
    }
  }

  const fetchIndicationsPaginations = async (params: IPaginationsCallback) => {
    const newParams = {...params, contact: searchParams}
    const urlParams = searchFactory().createUrlParams(newParams);
    const { data } = await litIndications(group_id, urlParams);

    if (data) {
      setIndications(data);
    }
  }

  const fetchIndications = async () => {
    const params = searchFactory().createUrlParams({ 
      page: 1,
      perPage: 10,
    });

    const { data } = await litIndications(group_id, params);
    setIndications(data);
  };

  useEffect(() => {   
    fetchIndications();
  }, [group_id]);

 
  return { 
    indications,
    fetchIndicationsPaginations,
    fetchIndicationsSearch,
    fetchIndications
   };
};
