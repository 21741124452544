import { useEffect, useState } from "react"
import { showIndication } from "../api/indications/indications"
import { IIndicationShow } from "../interfaces/indications/indications.interface"

interface IShowIndicationProps {
    id_indication: number
}

export function useShowIndication({ id_indication }: IShowIndicationProps) {
    const [indication, setIndication] = useState<IIndicationShow>({} as IIndicationShow)

    const fetchIndication = async (id_indication: number) => {
        if (!id_indication) return

        const indication = await showIndication(id_indication)

        if (indication) {
            setIndication(indication.data)
        }
    }

    useEffect(() => {
        fetchIndication(id_indication)
    }, [id_indication])

    return { indication }
}