import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useGuard from '../../contexts/Guard'
import { Container, Form, Row, Tab, Tabs } from "react-bootstrap"
import { ListIndications } from "./components/ListIndication"
import { FormProvider, useForm } from "react-hook-form"
import { CreateIndication } from "./components/CreateIndication"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { listAllGroup, listUserByGroup } from "../../api/list/list"
import { IListUserByGroupResponse, listAllGroupResponse } from "../../interfaces/list/list.interface"
import { ISelectDefault } from "../../interfaces/form/form.interface"
import { storeIndications } from "../../api/indications/indications"
import { blockContributionDate, getOnlyNumberPhone, sendToast } from "../../utils/Helper"
import { useIndications } from "./hook/UseIndications"


const createIndicationSchema = z.object({
    id_group: z.coerce.number({ required_error: 'grupo é obrigatório' }).positive(),
    company: z.string({ required_error: 'empresa é obrigatório' }).nonempty({ message: 'empresa é obrigatório' }),
    contact: z.string({ required_error: 'contato é obrigatório' }).nonempty({ message: 'contato é obrigatório' }),
    phone: z.string({ required_error: 'telefone é obrigatório' }).nonempty({ message: 'telefone é obrigatório' }).transform((value) => getOnlyNumberPhone(value)),
    email: z.string({ required_error: 'email é obrigatório' }).nonempty({ message: 'email é obrigatório' }).email({ message: 'email deve ser valido' }),
    obervations: z.string({ required_error: 'observações é obrigatório' }),
    id_group_indications: z.coerce.number({ required_error: 'grupo é obrigatório' }).positive(),
    id_user_indications: z.coerce.number({ required_error: 'usuário é obrigatório' }).positive(),
    indications_date: z.coerce.date({ required_error: 'data é obrigatório' }).refine((data) => blockContributionDate(data), { message: 'Não é possível criar indicações com mais de 1 mês de atraso' }),
})

export type CreateIndicationFormData = z.infer<typeof createIndicationSchema>

const Indications: React.FC = () => {

    const { infoUser, CheckPermissionWithArray } = useGuard()
    const { groupId } = useParams()
    const [activeTab, setActiveTab] = useState<number>(0);
    const [groups, setGroups] = useState<ISelectDefault[]>([])
    const [user, setUser] = useState<ISelectDefault[]>([])
    const {
        indications,
        fetchIndicationsPaginations,
        fetchIndicationsSearch,
        fetchIndications
    } = useIndications(+groupId!)

    const getUser = (id_group: number) => {
        listUserByGroup(id_group).then((response) => {
            const users = response.data.map((user: IListUserByGroupResponse) => {
                return {
                    label: user.user_name,
                    value: user.user_id
                }
            })
            setUser(users)
        })
    }

    const getGroup = () => {
        listAllGroup().then((response) => {
            const groups = response.data.map((group: listAllGroupResponse) => {
                return {
                    label: group.name,
                    value: group.id
                }
            })
            setGroups(groups)
        })
    }

    const createIndications = useForm<CreateIndicationFormData>({
        resolver: zodResolver(createIndicationSchema),
    })

    const {
        handleSubmit,
        watch,
        reset,
        setValue,
    } = createIndications

    const id_group_indications = watch('id_group_indications')

    const onSubmit = (data: CreateIndicationFormData) => {

        if (data.id_user_indications === infoUser.user.id) {
            sendToast({ message: 'Você não pode se indicar!', type: 'error' })
            return
        }

        storeIndications(data).then((response) => {
            if (response) {
                reset()
                fetchIndications()
            }
        })
    }

    const handleGoBack = () => {
        setActiveTab(0)
    }

    useEffect(() => {
        if (!groupId) return
        setValue('id_group', +groupId!)
        getGroup()
    }, [groupId])

    useEffect(() => {
        if (!id_group_indications) return
        getUser(id_group_indications)
        setValue('id_group', +groupId!)
    }, [id_group_indications])

    return (
        <div className="m-2">
            <Container fluid>
                <Row className="border border-secondary border-2 rounded p-4">
                    <Tabs
                        activeKey={activeTab}
                        defaultActiveKey={0}
                        onSelect={(index) => setActiveTab(+index!)}
                        className="mb-3"
                    >
                        <Tab eventKey={0} title="Listagem de Indicações">
                            <ListIndications
                                data={indications.data}
                                meta={indications.meta}
                                callbackPagination={fetchIndicationsPaginations}
                                callbackSearch={fetchIndicationsSearch}
                            />
                        </Tab>

                        {CheckPermissionWithArray(['indications.store']) && (
                            <Tab eventKey={1} title="Criar Indicação">
                                <FormProvider {...createIndications}>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <CreateIndication handleGoBack={() => handleGoBack()} group={groups} users={user} />
                                    </Form>
                                </FormProvider>
                            </Tab>
                        )}
                    </Tabs>
                </Row>
            </Container>
        </div>
    )
}

export default Indications