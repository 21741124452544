import { Modal, ModalProps } from "react-bootstrap"

interface IModalRootProps extends ModalProps {
    show: boolean,
    children: React.ReactNode
}

export const ModalRoot: React.FC<IModalRootProps> = ({
    show,
    children,
    ...props
}) => {
    return (
        <Modal 
            show={show}
            {...props}
        >
        {children}
        </Modal>
    )
}