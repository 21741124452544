import React, { useState, useEffect, useCallback } from 'react'
import { updateRole, getPermissionsWithRole } from "../../../api/role/Role"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IPermission } from '../../../interfaces/permission/IPermission'
import { FormCustom } from '../../../components/Form'
import './styles/createRole.css'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { IRoleResponseWithPermission } from '../../../interfaces/role/Role.interface'
import useGuard from '../../../contexts/Guard'

const editRoleFormSchema = z.object({
    name: z.string().nonempty('form_name_required'),
    key: z.string().nonempty('Chave deve ser obrigatório'),
    permissions: z.array(
        z.object({
            id_permission: z.coerce.number(),
        })
    ).min(1, 'deve escolher pelo menos uma permissão!')
})

type IEditRoleFormData = z.infer<typeof editRoleFormSchema>

const EditRole: React.FC = () => {
    const { t } = useTranslation()
    const { groupId, roleId } = useParams()
    const navigate = useNavigate()
    const { CheckPermissionWithArray } = useGuard()

    const [permissions, setPermissions] = useState<IPermission[]>([])
    const [role, setRole] = useState<IRoleResponseWithPermission>({} as IRoleResponseWithPermission)
    const canUpdatePermission = CheckPermissionWithArray(['role.update'])
    const editRoleForm = useForm<IEditRoleFormData>({
        resolver: zodResolver(editRoleFormSchema)
    })

    const {
        handleSubmit,
        register,
        control,
        setValue
    } = editRoleForm

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'permissions'
    })

    const appendValue = (e: any) => {

        if (e.checked) {
            append({
                id_permission: +e.id
            })
            return
        }

        if (!e.checked) {
            const index = fields.findIndex((item) => item.id_permission == e.id)
            remove(index)
        }
    }

    const getDataPermissions = useCallback(() => {
        getPermissionsWithRole({ groupId: Number(groupId), roleId: Number(roleId) }).then((response) => {
            setPermissions(response.data.permissions)
            setRole(response.data.roles)
        })
    }, [groupId, roleId])

    const setValueEditForm = () => {
        const permissions = role.permissions?.map((item) => {
            return {
                id_permission: item.id
            }
        })

        setValue('name', role.name)
        setValue('key', role.key)
        setValue('permissions', permissions)

    }

    const setPermissionsChecked = (permission: IPermission): boolean => {
        const findPermission = role.permissions.find((item) => item.id === permission.id)
        return findPermission ? true : false
    }

    const onSubmit = (e: IEditRoleFormData) => {
        updateRole({
            groupId: +groupId!,
            key: e.key,
            name: e.name,
            roleId: +roleId!,
            permissions: e.permissions.map((item) => {
                return {
                    id: item.id_permission
                }
            })
        }).then((response) => {
            console.log(response)
        })
    }

    const handleGoBack = () => {
        navigate('../')
    }

    useEffect(() => {
        getDataPermissions()
    }, [getDataPermissions])

    useEffect(() => {
        setValueEditForm()
    }, [permissions, role])

    return (

        <Container fluid>
            <FormProvider {...editRoleForm}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label htmlFor='role'>Nome</Form.Label>
                                <Form.Control disabled={!canUpdatePermission} type='text' id='role' {...register('name')} />
                                <FormCustom.ErrorMessage field='name' />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label htmlFor='key'>Chave</Form.Label>
                                <Form.Control disabled={!canUpdatePermission} type='text' id='key' {...register('key')} />
                                <FormCustom.ErrorMessage field='key' />
                            </Form.Group>
                        </Col>
                    </Row>

                    <h2 className='mt-3'>
                        {t('permissions')}
                    </h2>

                    <Form.Group controlId='group' className='input-checkbok'>
                        {permissions.map((item, i) => {                                                                                                                
                            return (
                                <Form.Check
                                    key={i}
                                    className={`item-${i}`}                                    
                                    type='checkbox'
                                    label={item.name}
                                    id={item.id.toString()}
                                    defaultChecked={setPermissionsChecked(item)}
                                    onChange={(e) => appendValue(e.target)}
                                    disabled={!canUpdatePermission}
                                />
                            )
                        })}
                    </Form.Group>

                    <Button
                        type='submit'
                        className="float-end mb-3 mr-1 m-1"
                        disabled={!canUpdatePermission}
                    >
                        Salvar
                    </Button>
                    <Button
                        variant="secondary"
                        type="button" 
                        className="float-end mb-3 mr-1 m-1"
                        onClick={handleGoBack}>
                        {t('goback')}
                    </Button>
                </Form>
            </FormProvider>
        </Container>

    )
}

export default EditRole