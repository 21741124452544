import React, { useState, useEffect } from 'react'
import { getRoles, getPermissions, saveRole, deleteRole } from "../../../api/role/Role"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Col, Container, Form, Row, Tab, Tabs } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IRoleResponse } from '../../../interfaces/role/Role.interface'
import { IResponseGeneric } from '../../../interfaces/generic/generic.interface'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { IPermission } from '../../../interfaces/permission/IPermission'
import './styles/createRole.css'
import { FormCustom } from '../../../components/Form'
import { ITableComponentCols } from '../../../components/Table/TableComponent'
import { TableCustomComponent } from '../../../components/Table'
import { IPaginationsCallback } from '../../../components/Table/PaginationComponent'
import { ITableSearchFormData } from '../../../components/Table/TableSearchComponent'
import { searchFactory } from '../../../factory/searchFactory'
import useGuard from '../../../contexts/Guard'

const createReasonFormSchema = z.object({
    name: z.string().nonempty('Nome deve ser obrigatório'),
    key: z.string().nonempty('Chave deve ser obrigatório'),
    permissions: z.array(
        z.object({
            id: z.coerce.number(),
        })
    ).min(1, 'deve escolher pelo menos uma permissão!')
})

type ICreaTeReasonFormData = z.infer<typeof createReasonFormSchema>

const CreateRole: React.FC = () => {
    const { t } = useTranslation()
    const { groupId } = useParams()
    const navigate = useNavigate()
    const { CheckPermissionWithArray } = useGuard()


    const [role, setRole] = useState<IResponseGeneric<IRoleResponse>>({} as IResponseGeneric<IRoleResponse>)
    const [activeTab, setActiveTab] = useState<number>(0);
    const [permissions, setPermissions] = useState<IPermission[]>([])

    const createReasonForm = useForm<ICreaTeReasonFormData>({
        resolver: zodResolver(createReasonFormSchema)
    })

    const [paramsSearch, setParamsSearch] = useState('')

    const {
        register,
        handleSubmit,
        control,
        reset
    } = createReasonForm

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'permissions'
    })

    const getRole = async () => {
        const params = searchFactory().createUrlParams({
            page: 1
        })

        const { data } = await getRoles({
            groupId: +groupId!,
            meta: params
        })

        setRole(data)
    }

    const searchRole = async ({ search }: ITableSearchFormData) => {
        const params = searchFactory().createUrlParams({
            page: 1,
            name: search
        })
        setParamsSearch(search)
        const { data } = await getRoles({
            groupId: +groupId!,
            meta: params
        })

        setRole(data)
    }
    const paginatorRole = async ({ page }: IPaginationsCallback) => {
        const params = searchFactory().createUrlParams({
            page,
            name: paramsSearch
        })

        const { data } = await getRoles({
            groupId: +groupId!,
            meta: params
        })

        setRole(data)
    }

    const getPermission = () => {
        getPermissions({
            groupId: +groupId!
        }).then((response) => {
            setPermissions(response.data)
        })
    }

    const handleGoBack = () => {
        setActiveTab(0)
    }

    const columns: ITableComponentCols[] = [
        {
            title: 'Permissão', render: (row: IRoleResponse) => (
                <span>{row.name}</span>
            )
        },
        {
            title: 'Ações',
            render: (row: IRoleResponse) => (
                <>
                    <div>
                        <Button
                            variant="primary"
                            type="button"
                            className="w-20 me-4"
                            onClick={() => {
                                navigate(`${row.id}`);
                            }}
                        >
                            {t('actionEdit')}
                        </Button>

                        {CheckPermissionWithArray(['role.delete']) && (
                            <Button
                                variant="danger"
                                type="button"
                                onClick={() => {
                                    handleDeleteRole(row)
                                }}
                            >
                                {t('actionDelete')}
                            </Button>
                        )}


                    </div>
                </>
            )
        }
    ]

    const handleDeleteRole = (data: IRoleResponse) => {
        deleteRole({
            groupId: +groupId!,
            roleId: data.id
        }).then(() => {
            getRole()
        })
    }

    const appendValue = (e: any) => {
        if (e.checked) {
            append({
                id: +e.id
            })
            return
        }

        if (!e.checked) {
            const index = fields.findIndex((item) => item.id == e.id)
            remove(index)
        }
    }

    const onSubmit = (data: ICreaTeReasonFormData) => {
        saveRole({
            key: data.key,
            name: data.name,
            permissions: data.permissions,
            groupId: +groupId!
        }).then(() => {
            reset()
            getRole()
        })
    }

    useEffect(() => {
        getRole()
        getPermission()
    }, [groupId])

    return (
        <div className="m-2 vh-100">
            <Container fluid>
                <Row>
                    <Col>
                        <Tabs
                            activeKey={activeTab}
                            defaultActiveKey='listRole'
                            onSelect={(index) => setActiveTab(+index!)}
                        >
                            <Tab
                                eventKey='0'
                                title='Listagem Permissões'
                            >
                                <TableCustomComponent.Root>
                                    <Col>
                                        <TableCustomComponent.Search
                                            callback={searchRole}
                                        />
                                    </Col>
                                    <Col>
                                        <TableCustomComponent.Table
                                            data={role.data}
                                            cols={columns}
                                        />
                                    </Col>
                                    <Col>
                                        <TableCustomComponent.Pagination
                                            {...role.meta}
                                            callback={paginatorRole}
                                        />
                                    </Col>
                                </TableCustomComponent.Root>
                            </Tab>

                            {CheckPermissionWithArray(['role.store']) && (
                                <Tab
                                    eventKey='1'
                                    title='Criar Permissão'
                                >
                                    <FormProvider {...createReasonForm}>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label htmlFor='role'>{t('name')}</Form.Label>
                                                        <Form.Control type='text' id='role' {...register('name')} />
                                                        <FormCustom.ErrorMessage field='name' />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label htmlFor='key'>Chave</Form.Label>
                                                        <Form.Control type='text' id='key' {...register('key')} />
                                                        <FormCustom.ErrorMessage field='key' />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <h2 className='mt-3'>
                                                {t('permissions')}
                                            </h2>

                                            <Form.Group controlId='group' className='input-checkbok'>
                                                {permissions.map((item, i) => {
                                                    return (
                                                        <Form.Check
                                                            key={i}
                                                            type='checkbox'
                                                            label={item.name}
                                                            id={item.id.toString()}
                                                            onChange={(e) => appendValue(e.target)}
                                                        />
                                                    )
                                                })}
                                            </Form.Group>

                                            <Button
                                                type='submit'
                                                className="float-end mb-3 mr-1 m-1"
                                            >
                                                Salvar
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                type="button"
                                                className="float-end mb-3 mr-1 m-1"
                                                onClick={handleGoBack}>
                                                {t('goback')}
                                            </Button>
                                        </Form>
                                    </FormProvider>
                                </Tab>
                            )}

                        </Tabs>
                    </Col>
                </Row>
            </Container >
        </div>
    )
}

export default CreateRole