import { useEffect, useState } from "react";
import {  IDashboardOveralPerformanceResponse, IDashboardSideBardResponse } from "../interfaces/dashboard/dashboard.interface";
import { useParams } from "react-router-dom";
import {  getOverallPerformance, getSidebarPerformance, reportYearScore } from "../api/dashboard/dashboard";
import { getCurrentMonth, getCurrentYear } from "../utils/Helper";
import { useHomeScreen } from "../pages/admin/home-screen/hook/homeScreen";


export function useDashboard() {

  const { groupId } = useParams()
  const { imagesAdmin, images } = useHomeScreen()

  const [overallPerformance, setOverallPerformance] = useState<IDashboardOveralPerformanceResponse>(
    {} as IDashboardOveralPerformanceResponse
  )

  const [sidebarPerfomance, setSidebarPerfomance] = useState<IDashboardSideBardResponse>(
    {} as IDashboardSideBardResponse  
  )

  const [ranking, setRanking] = useState< [string, number][]>({} as  [string, number][])

  const fetchRanking = async () => {
    reportYearScore(+groupId!).then((response) => {
      setRanking(response.data)
    })
  }

  const fetchOverallPerformance = async () => {
    getOverallPerformance(+groupId!).then((response) => {
      setOverallPerformance(response.data)
    })
  }

  const fetchSidebarPerfomane = async () => {
    getSidebarPerformance({
      id_group: +groupId!,
      month: getCurrentMonth(),
      year: getCurrentYear()
    }).then((response) => {
      setSidebarPerfomance(response.data)
    })
  }
  
  useEffect(() => {
    fetchOverallPerformance()
    fetchSidebarPerfomane() 
    fetchRanking() 
  }, [groupId])

  return {
    overallPerformance,
    sidebarPerfomance,
    imagesAdmin,
    ranking,
    images
  }
}