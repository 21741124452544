import { IBussinesResponse, IBussinesShowResponse, IStoreBussines } from '../../interfaces/bussines/bussines.interface'
import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import api from '../Api'

export const showBussines = async (id_group: number, params: string) => {
    return await api.get<IResponseGeneric<IBussinesResponse>>(`bussines/group/${id_group}${params}`)
}

export const getBussinesByIdIndications =  async (id: number) => {
    return await api.get<IBussinesShowResponse>(`bussines/indication/${id}`)
}

export const storeBussines = async (data: IStoreBussines) => {
    let payload = {
        status: data.status,
        close_date: data.close_date,
        value: data.value,
        observations: data.obervations
    }

    
    return await api.post(`bussines/${data.id_bussines}`, { ...payload })
}