import { useEffect, useState } from "react";
import { IResponseGeneric } from "../../../interfaces/generic/generic.interface";
import { IBussinesResponse } from "../../../interfaces/bussines/bussines.interface";
import { showBussines } from "../../../api/bussines/bussines";
import { searchFactory } from "../../../factory/searchFactory";
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent";
import { ITableSearchFormData } from "../../../components/Table/TableSearchComponent";


export function useBussines(groupId: number) {
  const [bussines, setBussines] = useState<IResponseGeneric<IBussinesResponse>>(
    {} as IResponseGeneric<IBussinesResponse>  
  );
  const [searchParams, setSearchParams] = useState('')

  const fetchAllBussines = async () => {
    let params = searchFactory().createUrlParams({
      page: 1,
      perPage: 10,
    });
    const { data: bussines } = await showBussines(groupId, params);

    if (bussines) {
      setBussines(bussines);
    }
  };

  const fetchPaginationBussines = async (params: IPaginationsCallback) => {
    const newParams = {...params, contact: searchParams}
    let paramsUrl = searchFactory().createUrlParams(newParams);
    const { data: bussines } = await showBussines(groupId, paramsUrl);

    if (bussines) {
      setBussines(bussines);
    }
  }

  const fetchSearhBussines = async (params: ITableSearchFormData) => {
    setSearchParams(params.search)
    let paramsUrl = searchFactory().createUrlParams({
      contact: params.search,
      page: 1,
      perPage: 10
    });

    const { data: bussines } = await showBussines(groupId, paramsUrl);

    if (bussines) {
      setBussines(bussines);
    }
  }

  useEffect(() => {
    fetchAllBussines();
  }, [groupId]);

  return {
    bussines,
    fetchPaginationBussines,
    fetchSearhBussines    
  }
  
}