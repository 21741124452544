import '../../styles/home/home.css'
import '../../styles/login/login.css'
import React, { useEffect, useState } from "react"
import { Col, Row, Container, Card } from "react-bootstrap"
import { useAuth } from "../../contexts/Auth"
import ReactFlagsSelect from "react-flags-select"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form";
import { ModalComponent } from '../../components/ModalComponent'
import { LoginForm } from './components/loginForm'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

const schemaLoginForm = z.object({
    email: z.string(),
    password: z.string()
})

export type loginFormData = z.infer<typeof schemaLoginForm>

const Login: React.FC = () => {

    const loginForm = useForm<loginFormData>({
        resolver: zodResolver(schemaLoginForm)
    });    
    
    const [selected, setSelected] = useState("BR")

    const { ChangeLanguage, showModal, handleCloseModal } = useAuth()
    const { t } = useTranslation()   

    useEffect(() => {
        ChangeLanguage(selected)
    }, [selected, ChangeLanguage])

    return (

        <>
            <div style={{
                backgroundImage: "url(/assets/img/login/nexus-bg.jpg)",
                minHeight: "100vh",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom center",
            }}>

                <Container>
                    <ReactFlagsSelect
                        className='position-absolute px-5 py-3 end-0 text-white'
                        selected={selected}
                        onSelect={(code) => setSelected(code)}
                        fullWidth={false}
                        countries={["BR", "PT", "ES", "US"]}
                        customLabels={{ BR: "pt-BR", PT: "pt-PT", ES: "es-ES", US: "en-US" }}
                        placeholder={t('selectLanguage')}
                    />
                    <Row className="vh-100 d-flex justify-content-center align-items-center">
                        <Col md={8} lg={6} xs={12}>
                            <Card className="shadow">
                                <Card.Body>
                                    <div className="mb-2 mt-4">
                                        <h2 className="fw-bold mb-3 text-uppercase text-center">
                                            <img className="custom-width px-0" alt="logo" src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/login/nexus-logo.png`}></img>
                                        </h2>

                                        <FormProvider {...loginForm}>
                                            <LoginForm  handleLanguage={selected}/>
                                        </FormProvider>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ModalComponent.Root
                show={showModal}
                onHide={handleCloseModal}
            >
                <ModalComponent.Content>
                    <p>Seu acesso não está autorizado. Para maiores informações entre em contato com <a href="mailto:adm@nexusfn.com.br">adm@nexusfn.com.br</a></p>
                </ModalComponent.Content>
            </ModalComponent.Root>
        </>
    )
}

export default Login