import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'

interface IConfirmationModalProps {
  show: boolean
  message: string
  onConfirm: () => void
  onDecline: () => void
  declineLabel?: string
  confirmLabel?: string
  title?: string
}

function ConfirmationModal(props: IConfirmationModalProps) {
  const { t } = useTranslation()

  const handleConfirm = () => {
    props.onConfirm();
  };

  const handleDecline = () => {
    if (props.onDecline) {
      props.onDecline();
    }
  };

  return (
    <>
      <Modal show={props.show}>
        <Modal.Header closeButton onClick={handleDecline}>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDecline}>
            {props.declineLabel || t('cancel')}
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            {props.confirmLabel || t('confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
