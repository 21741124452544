import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FormCustom } from "../../components/Form";
import { z } from 'zod'
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { forgotResetPassword } from "../../api/forgotPassword/forgotPassword";
import { useNavigate } from 'react-router-dom'

const forgotPasswordResetSchema = z.object({
  password: z.string().nonempty('Senha Obrigatória')
})

type IForgotPasswordResetFormData = z.infer<typeof forgotPasswordResetSchema>

export const ForgotPasswordReset: React.FC = () => {
  const [showPass, setShowPass] = useState(true)
  const navigate = useNavigate()

  const {t} = useTranslation()
  const { token } = useParams()

  const resetForm = useForm<IForgotPasswordResetFormData>({
    resolver: zodResolver(forgotPasswordResetSchema)
  })

  const togglePassword = () => {
    setShowPass(!showPass)
  }

  const {
    handleSubmit
  } = resetForm

  const onSubmit = async ({ password }: IForgotPasswordResetFormData) => {
    const { status } = await forgotResetPassword({ password, token: token! })
    if (status === 200) {
      navigate('/')
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...resetForm}>
        <div style={{
          backgroundImage: "url(/assets/img/login/nexus-bg.jpg)",
          minHeight: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }} className="forgot_container">
          <div className="forgot_container_card">
            <div className="forgot_container_log">
              <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/login/nexus-logo.png`} />
            </div>
            <Form.Label style={{color: 'white'}}>Digite sua senha:</Form.Label>
            <InputGroup className="mb-3">
              <FormCustom.Control className="rounded-0" field="password" type={showPass ? "password" : "text"} placeholder={t('password')} />
              <InputGroup.Text onClick={togglePassword} >
                <div>
                  {showPass ? <EyeSlash size={20} className="text-primary" /> : <Eye size={20} className="text-primary" />}
                </div>
              </InputGroup.Text>
            </InputGroup>
            <div className="forgot_btn">
              <Button
                type="submit"
                variant="primary"
              >
                Enviar
              </Button>
            </div>
          </div>
        </div>
      </FormProvider>
    </Form>
  )
}