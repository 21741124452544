import { IChairStorage, IDeleteChairParams, IGetChairs, IUpadateChairParams } from '../../interfaces/chair/IGetChairs'
import api from '../Api'

export const getAllChairs = async ({ groupId }: IGetChairs) => {
    return await api.get<IChairStorage[]>(`group/${groupId}/chair/all`)
}

export const getChairs = async ({ groupId, meta }: IGetChairs) => {
    return await api.get(`group/${groupId}/chair${meta}`)
}

export const getChair = async (groupId: number, charId: number) => {
    return await api.get(`group/${groupId}/chair/${charId}`)
}

export const saveChair = async ({ groupId, data }: any) => {
    return await api.post(`group/${groupId}/chair`, data)
}

export const updateChair = async ({ groupId, chairId, data }: IUpadateChairParams) => {
    return await api.put(`group/${groupId}/chair/${chairId}`, data)
}

export const destroyChair = async ({ groupId, chairId }: IDeleteChairParams) => {
    return await api.delete(`group/${groupId}/chair/${chairId}`)
}

