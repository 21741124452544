import './formPhoto.css'
import { Form, FormControlProps } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormCustom } from ".";
import { useEffect } from 'react';

interface IFormPhotoProps extends FormControlProps{
  field: string
  url?: string 
}

export const FormPhoto: React.FC<IFormPhotoProps> = ({
  field,
  url,
  ...rest
}) => {
  const { t } = useTranslation()
  const { register, watch }  = useFormContext()

  const photoWatch: FileList = watch(field)
  
  const loadPhoto = () => {
    const preview = document.getElementById(
      "previewImage"
    ) as HTMLImageElement;
    preview.src = url!;
    preview.style.display = "block";     
  }
  
  if (url) {
    loadPhoto()    
  }

  const handleUpdatePhoto = async (files: FileList) => {
    if (!files) return;
    if (files[0]) {
      const src = URL.createObjectURL(files[0]);
      const preview = document.getElementById(
        "previewImage"
      ) as HTMLImageElement;
      preview.src = src;
      preview.style.display = "block";      
    }
  };

  useEffect(() => {
    if (photoWatch?.length) {
      handleUpdatePhoto(photoWatch)            
    }
    
  }, [photoWatch])

  return (
    <div>
      <Form.Label>{t('photo')}</Form.Label>
      <img className="imagePreview" id="previewImage"></img>
      <label className="form-label btn btn-primary w-100" htmlFor="1">
        {t("changePhoto")}
      </label>
      <Form.Control      
        {...rest}
        {...register(field)}
        style={{ display: "none" }}
        type="file"
        id="1"   
      ></Form.Control>
      <FormCustom.ErrorMessage field={field} />
    </div>
  )
}
