import React, { createContext, useContext } from "react";
import { useDashboard } from "../hooks/useDashboard";
import { IDashboardContext } from "../interfaces/dashboard/dashboard.interface";


const DashboardContext = createContext<IDashboardContext>({} as IDashboardContext);

interface IDeshboardProviderProps  {
  children: React.ReactNode;
}

export const DashboardProvider: React.FC<IDeshboardProviderProps> = ({ children }) => {
  const { overallPerformance, sidebarPerfomance, imagesAdmin, ranking, images} = useDashboard();


  return (
    <DashboardContext.Provider value={{
      overallPerformance,
      sidebarPerfomance,
      imagesAdmin,
      ranking,
      images
    }}>
      {children}
    </DashboardContext.Provider>
  )
};

export function useDashboardContext() {
  return useContext(DashboardContext);
}