import { ListGroup } from "react-bootstrap"
import { Briefcase, Building, CardList, DoorOpen, People, Person, ShieldExclamation, Star } from "react-bootstrap-icons"
import { Link, useLocation, useParams } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import useGuard from "../../contexts/Guard"

const Sidebar: React.FC = () => {

    const { t } = useTranslation()
    const { pathname } = useLocation()
    const { roleId } = useParams()
    const { CheckPermissionWithArray } = useGuard()

    const routeActive = (route: string) => {
        let routeCurrent = pathname.split("/").pop()
        if (Number(routeCurrent)) {
            routeCurrent = `${pathname.split("/").at(-2)}/${pathname.split("/").pop()}`
        }
        return routeCurrent === route
    }

    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style={{ width: "280px", height: "100%" }}>
            <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <span className="fs-4">
                    {t('admin')}
                </span>
            </a>
            <ListGroup as="ul" className="bg-secondary menu-custom">
                {
                    CheckPermissionWithArray(['user.index', 'user.delete', 'user.update', 'user.store']) ?
                        <ListGroup.Item as={Link} to={`./usuarios`} className={routeActive('usuarios') ? "li active" : "li"}>
                            <Person size={20} className="mb-1 me-2" />
                            {t('users')}
                        </ListGroup.Item>
                        : <></>
                }
                {
                    CheckPermissionWithArray([
                        'chair.index', 
                        'subChair.delete', 
                        'subChair.update', 
                        'subChair.store', 
                        'subChair.index',
                        'chair.store',
                        'chair.update',
                        'chair.delete'
                    ]) ?
                        <ListGroup.Item as={Link} to={`./cadeiras`} className={routeActive('cadeiras') ? "li active" : "li"}>
                            <Briefcase size={20} className="mb-1 me-2" />
                            {t('chairs')}
                        </ListGroup.Item>
                        : <></>
                }
                {
                    CheckPermissionWithArray(['group.index', 'group.update', 'group.delete', 'group.store']) ?
                        <ListGroup.Item as={Link} to={`./grupos`} className={routeActive('grupos') ? "li active" : "li"}>
                            <People size={20} className="mb-1 me-2" />
                            {t('groups')}
                        </ListGroup.Item>
                        : <></>
                }
                {
                    CheckPermissionWithArray(['company.index', 'company.store', 'company.delete', 'company.update']) ?
                        <ListGroup.Item as={Link} to={`./empresas`} className={routeActive('empresas') ? "li active" : "li"}>
                            <Building size={20} className="mb-1 me-2" />
                            {t('companies')}
                        </ListGroup.Item>
                        : <></>
                }
                {
                    CheckPermissionWithArray(['reason.index', 'reason.update', 'reason.delete', 'reason.store']) ?
                        <ListGroup.Item as={Link} to={`./motivos`} className={routeActive('motivos') ? "li active" : "li"}>
                            <ShieldExclamation size={20} className="mb-1 me-2" />
                            {t('reasons')}
                        </ListGroup.Item>
                        : <></>
                }
                {
                    CheckPermissionWithArray(['role.index', 'role.store', 'role.update', 'role.delete']) ?
                        <ListGroup.Item as={Link} to={`./permissoes`} className={routeActive('permissoes') || routeActive(`permissoes/${roleId}`) ? "li active" : "li"}>
                            <DoorOpen size={20} className="mb-1 me-2" />
                            {t('permissions')}
                        </ListGroup.Item>
                        : <></>
                }
                {
                    CheckPermissionWithArray(['point.index', 'point.update']) ?
                        <ListGroup.Item as={Link} to={`./pontuacoes`} className={routeActive('pontuacoes') ? "li active" : "li"}>
                            <Star size={20} className="mb-1 me-2" />
                            {t('points')}
                        </ListGroup.Item>
                        : <></>
                }
                {
                    CheckPermissionWithArray(['home-screen']) ?
                        <ListGroup.Item as={Link} to={`./home-screen`} className={routeActive('home-screen') ? "li active" : "li"}>
                            <CardList size={20} className="mb-1 me-2" />
                            {t('homeScreen')}
                        </ListGroup.Item>
                        : <></>
                }
            </ListGroup>
        </div>
    )
}

export default Sidebar