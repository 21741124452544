import axios from "axios"
import { catchToast, sendToast } from "../utils/Helper"

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

api.interceptors.response.use((response: any) => {
    sendToast({ message: response.data.message, type: 'success' })
    return response
}, (error: any) => {
    catchToast(error)
    checkToken(error)
})

const checkToken = (error: any) => {    
    if (error.response.statusText === "Unauthorized") {
        localStorage.removeItem('@App:user')
        localStorage.removeItem('@App:token')
        window.location.href = '/'
    }

    // console.log(error.response.statusText)
}

export default api