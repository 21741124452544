import { FormCustom } from "../../../components/Form"
import { Button, Col, Form, Row } from "react-bootstrap"
import { ISelectDefault } from "../../../interfaces/form/form.interface"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { phoneMask } from "../../../utils/Helper"
import { useEffect } from "react"

interface ICreateGuestProps {
    groupOptions: ISelectDefault[]
    dateOptions: ISelectDefault[]
    goBack: () => void
}

export const CreateGuests: React.FC<ICreateGuestProps> = ({
    groupOptions,
    dateOptions,
    goBack
}) => {   
    const { t } = useTranslation();

    const { watch, setValue } = useFormContext()
       
    return (
        <>
            <div>            
                <Form.Group className="mb-3 w-25">
                    <Form.Label htmlFor="name">{t('full_name')}</Form.Label>
                    <FormCustom.Control
                        type="text"
                        field="name"                
                        placeholder={t('full_name')}
                        id="name"
                    />                    
                </Form.Group>
                <Form.Group className="mb-3 w-25">
                    <Form.Label htmlFor="email">{t('email')}</Form.Label>
                    <FormCustom.Control
                        type="text"
                        field="email"                
                        placeholder={t('email')}
                        id="email"
                    />                    
                </Form.Group> 
                <Form.Group className="mb-3 w-25">
                    <Form.Label htmlFor="phone">{t('phone')}</Form.Label>
                    <FormCustom.Phone
                        type="text"
                        field="phone"                
                        placeholder={t('phone')}
                        id="phone"                       
                    />                    
                </Form.Group> 
                <Form.Group className="mb-3 w-25">
                    <Form.Label htmlFor="company">{t('company')}</Form.Label>
                    <FormCustom.Control
                        type="text"
                        field="company"
                        placeholder={t('company')}
                        id="company"
                    />                    
                </Form.Group>
                <Form.Group className="mb-3 w-25">
                    <Form.Label htmlFor="companyActivity">{t('activity_company_title')}</Form.Label>
                    <FormCustom.Control
                        type="text"
                        field="companyActivity"
                        placeholder={t('activity_company_title')}
                        id="companyActivity"
                    />                    
                </Form.Group>
                <Form.Group className="mb-3 w-25">
                    <Form.Label htmlFor="groupId">{t('group')}</Form.Label>
                    <FormCustom.Select field="groupId" id="groupId" options={groupOptions}/>                    
                </Form.Group>
                <Form.Group className="mb-3 w-25">
                    <Form.Label htmlFor="meetingDate">{t('guest_day_title')}</Form.Label>
                    <FormCustom.Select field="meetingDate" id="meetingDate" options={dateOptions}/>                              
                </Form.Group>            
            </div>
            <Row className="justify-content-md-end">
                <Col xl={3} sm={3} md={3} className="mt-3">
                    <Button
                    variant="primary"
                    type="submit"
                    className="float-end mb-3 mr-1 m-1"
                    >
                    {t("submit")}
                    </Button>
                    <Button
                    variant="secondary"
                    type="button"
                    className="float-end mb-3 mr-1 m-1"
                    onClick={goBack}               
                    >
                    {t("goback")}
                    </Button>
                </Col>
            </Row>                      
        </>
    )
}