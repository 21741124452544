import React, { useRef, useState } from "react";
import './corousel.css'
import { ModalComponent } from "../ModalComponent";

interface ICarouselProps {
  urls: string[]
}

export const Carousel: React.FC<ICarouselProps> = ({
  urls
}) => {
  const carousel = useRef<HTMLInputElement>(null)
  const [openModal, setOpenModal] = useState(false)
  const [urlModal, setUrlModal] = useState('')

  const handleOpenModal = (url: string) => {
    setUrlModal(url)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleLeftClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (carousel.current?.offsetWidth) {
      carousel.current.scrollLeft -= carousel.current.offsetWidth
    }
  }

  const handleRightClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (carousel.current?.offsetWidth) {
      carousel.current.scrollLeft += carousel.current.offsetWidth
    }
  }
  return (
    <>
      <div className="component__container">
        <div className="component__container__carousel" ref={carousel}>
          {
            urls.map((url, index) => (
              <div onClick={() => handleOpenModal(url)} key={index} className="component__container__carousel__item">
                <img src={url} alt="teste"></img>
              </div>
            ))
          }
        </div>
        {
          urls.length > 1 && (
            <div className="component__container__buttons">
              <button onClick={handleLeftClick}><img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/right_chevron_icon.png`} alt="scroll left"></img></button>
              <button onClick={handleRightClick}><img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/right_chevron_icon.png`} alt="scroll rigth"></img></button>
            </div>
          )
        }
      </div>
      {
        openModal && (
          <ModalComponent.Root
            className="modal-carouel-expand__container"
            show={openModal}
            onHide={handleCloseModal}
          >        
            <ModalComponent.Content
              className="modal-carouel-expand__container__content"
            >
              <img src={urlModal}></img>
            </ModalComponent.Content>          
          </ModalComponent.Root>
        )
      }
    </>
  )
}