import { useCallback, useEffect, useState } from "react";
import { getAllChairs } from "../../../../api/chair/Chair";
import { useParams } from "react-router-dom";
import { ISelectDefault } from "../../../../interfaces/form/form.interface";
import { IChairStorage } from "../../../../interfaces/chair/IGetChairs";

export function useChair() {
  const { groupId } = useParams();
  const [chair, setChair] = useState<IChairStorage[]>([])
  const [chairSelect, setChairSelect] = useState<ISelectDefault[]>([])

  const fetchChair = useCallback(() => {
    getAllChairs({ groupId: +groupId! }).then((response) => {
      setChair(response.data)
      const chairSelect = response.data.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })

      setChairSelect(chairSelect)
    })
  }, [])

  useEffect(() => {
    fetchChair()
  }, [groupId])

  return {
    chair,
    chairSelect
  }
}