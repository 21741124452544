import './sidebarDashboard.css'
import { CardInfo } from "../card-info/CardInfo"
import { useDashboardContext } from '../../../../contexts/Dashboard'
import { getCurrency, getCurrentMonthName } from '../../../../utils/Helper'
import { useTranslation } from 'react-i18next'


export const SidebarDashboard: React.FC = () => {
  const { t } = useTranslation()
  const { sidebarPerfomance, ranking } = useDashboardContext()

  const urlAvatarDefault = process.env.REACT_APP_PUBLIC_URL + '/assets/img/profile/avatar.png'

  const actualMonth = getCurrentMonthName('en').toLocaleLowerCase()
  const pointsMonth = ranking[actualMonth as keyof typeof ranking]

  const avatar = sidebarPerfomance?.user?.path_name ? sidebarPerfomance?.user?.path_name : urlAvatarDefault
  return (
    <div className='sidebar-dashboard-container'>
      <div className='sidebar-dashboard-container__header'>
        <div className='sidebar-dashboard-container__header__img'>
          <img src={avatar} alt="avatar" />
        </div>
        <div className='sidebar-dashboard-container__header__company'>
          <div className='sidebar-dashboard-container__header__company__info'>
            <span>Grupo: </span>{sidebarPerfomance?.user?.grupo_name}
          </div>
          <div className='sidebar-dashboard-container__header__company__info'>
            <span>Cadeira: </span>{sidebarPerfomance?.user?.chair_name}
          </div>
          <div className='sidebar-dashboard-container__header__company__info'>
            <span>{t('company')}: </span>{sidebarPerfomance?.user?.company_name}
          </div>
          <div className='sidebar-dashboard-container__header__company__info'>
            <span>Nome: </span>{sidebarPerfomance?.user?.user_name}
          </div>

        </div>
      </div>

      <div className='sidebar-dashboard-container__month'>
        <p>Desempenho: </p>
        <p>{getCurrentMonthName()}</p>
      </div>

      <div className="sidebar-dashboard-container__system-info">
        <CardInfo
          icon={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/calendar.svg`}
          title="Negócios Fechados"
        >
          <p className='card-info_container__body__info-1'>{sidebarPerfomance?.businees_closed_quantity}</p>
          <p className='card-info_container__body__info-2'>{getCurrency(sidebarPerfomance?.businees_closed_value)}</p>
        </CardInfo>
        <CardInfo
          icon={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/up-sign.svg`}
          title="Indicações de Negócio"
        >
          <p className='card-info_container__body__info-1'>{sidebarPerfomance?.indications_businnes_closed_quantity}</p>
          <p className='card-info_container__body__info-2'>{getCurrency(sidebarPerfomance?.indications_businnes_closed_valor)}</p>
        </CardInfo>
        <CardInfo
          icon={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/talk.svg`}
          title="RDN"
        >
          <p>{sidebarPerfomance?.rdn}</p>
        </CardInfo>
        <CardInfo
          icon={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/networking.svg`}
          title="Convidados"
        >
          <p>{sidebarPerfomance?.guests}</p>
        </CardInfo>
        <CardInfo
          icon={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/calendar.svg`}
          title="Presença em reuniões semanais"
        >
          <p>{sidebarPerfomance?.participation}</p>
        </CardInfo>
      </div>

      <div className='sidebar-dashboard-container__score'>
        <span>pontuação: </span>
        <span className='sidebar-dashboard-container__score--color'>{pointsMonth as number}</span>
      </div>
    </div>
  )
}