
import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import ICreateGroup, { IDeleteGroupParams, IGetGroups, IGroupAllPaginatorResponse, IGroupResponse, IShowGroupByIdParams, IUpdateGroupParams } from '../../interfaces/group/IGetGroups'
import api from '../Api'

export const getAllGroups = async ({ groupId }: IGetGroups) => {
    return await api.get<IGroupResponse[]>(`group/${groupId}/group/all`)
}

export const getAllgroupsPaginator = async ({ groupId, meta }: IGetGroups) => {
    return await api.get<IResponseGeneric<IGroupAllPaginatorResponse>>(`group/${groupId}/default/group${meta}`)
}

export const createGroup = async (paylaod: ICreateGroup) => {
    return await api.post(`group/${paylaod.groupId}/group`, paylaod)
}

export const showGrup = async ({ groupId, id }: IShowGroupByIdParams) => {
    return await api.get<IGroupResponse>(`group/${groupId}/group/${id}`)
}

export const updateGroup = async ({ groupId, id, data }: IUpdateGroupParams) => {
    return await api.put<IGroupResponse>(`group/${groupId}/group/${id}`, { ...data })
}

export const deleteGroup = async ({ groupId, id }: IDeleteGroupParams) => {
    return await api.delete(`group/${groupId}/group/${id}`)
}