
import './listWeekMeetingPresence.css'
import { useParams } from "react-router-dom"
import useGuard from "../../../contexts/Guard"
import { Button, Form } from "react-bootstrap"
import { z } from 'zod'
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from 'react'
import { getMeetingWeekPresence, storeMeetingWeekPresence, updateMeetingWeekPresence } from '../../../api/meetingWeek/meetingWeek'
import { IShowPresenceResponse } from '../../../interfaces/meetingWeek/meetingWeek.interface'
import { useTranslation } from 'react-i18next'
import { FormCustom } from '../../../components/Form'
import { getDateLocale } from '../../../utils/Helper'

const createPresenceSchema = z.object({
    id_meeting_week: z.coerce.number({ required_error: 'Reunião é obrigatório' }).positive(),
    user_presence: z.array(z.object({
        name: z.string().nonempty({ message: 'form_name_required' }),
        id_user: z.coerce.number({ required_error: 'Usuário é obrigatório' }).positive(),
        presence: z.coerce.boolean({ required_error: 'Presença é obrigatório' }),
        id: z.coerce.number().optional()
    })),
    guests_presence: z.array(z.object({
        name: z.string().nonempty({ message: 'form_name_required' }),
        id_guest: z.coerce.number({ required_error: 'Convidado é obrigatório' }).positive(),
        presence: z.coerce.boolean({ required_error: 'Presença é obrigatório' }),
        id: z.coerce.number().optional()
    }))
})

export type CreatePresenceFormData = z.infer<typeof createPresenceSchema>

export const ListWeekMeetingPresence: React.FC = () => {
    const { t } = useTranslation();
    const { id_week, groupId } = useParams()
    const { checkIfUserIsAdmin } = useGuard()
    const [meetingWeekPresence, setMeetingWeekPresence] = useState<IShowPresenceResponse>()

    const getPresenceList = () => {
        getMeetingWeekPresence({
            id_group: groupId!,
            meeting_week_id: id_week!
        }).then((response) => {
            setMeetingWeekPresence(response.data)       
            buildFormPresence(response.data)                        
        })
    }

    const disabledForm = !checkIfUserIsAdmin() && meetingWeekPresence?.disabled


    const buildFormPresence = (presence: IShowPresenceResponse) => {
        const { userPresences, guestPresences } = presence 

        for (const userPresence of userPresences) {   
            if (presence?.disabled && Object.hasOwn(userPresence, 'id' )) {                               
                userAppend({
                    id_user: userPresence.id_user,
                    presence: userPresence.presence,
                    name: userPresence.name,
                    id: userPresence.id
                })

                continue
            }      
            
            if (!presence?.disabled) {
                userAppend({
                    id_user: userPresence.id_user,
                    presence: userPresence.presence,
                    name: userPresence.name,
                    id: userPresence.id
                })
            }
        }
        

        guestPresences.forEach((guestPresence) => {
            guetsAppend({
                id_guest: guestPresence.id_guest,
                presence: guestPresence.presence,
                name: guestPresence.name,
                id: guestPresence.id
            })
        })

        setValue('id_meeting_week', +id_week!)
    }

    const createPresenceForm = useForm<CreatePresenceFormData>({
        resolver: zodResolver(createPresenceSchema),
    })

    const {
        register,
        handleSubmit,
        control,
        setValue,
    } = createPresenceForm

    const { fields: userFields, append: userAppend } = useFieldArray({
        control,
        name: 'user_presence',
    })

    const { fields: guestsFields, append: guetsAppend } = useFieldArray({
        control,
        name: 'guests_presence',
    })

    const onSubmit = (data: CreatePresenceFormData) => {        
        if (meetingWeekPresence?.disabled) {            
            const guests = data.guests_presence.map((item) => {
                return {
                    id: item.id,
                    presence: item.presence
                }
            })
            
            const users = data.user_presence.map((item) => {
                return {
                    id: item.id,
                    presence: item.presence
                }
            })

            updateMeetingWeekPresence({ 
                guests,
                users
            }).then((response: any) => {
                // console.log(response)
            })
            return
        }

        const user_presence = data.user_presence.map((user) => {
            return {
                id_user: user.id_user,
                presence: user.presence
            }
        })

        const guests_presence = data.guests_presence.map((guest) => {
            return {
                id_guest: guest.id_guest,
                presence: guest.presence
            }
        })

        storeMeetingWeekPresence({
            id_meeting_week: data.id_meeting_week,
            user_presence,
            guests_presence
        }).then((response) => {
            if (response) {
                setValue('user_presence', [])
                setValue('guests_presence', [])
                getPresenceList()
            }
        })
    }


    useEffect(() => {
        getPresenceList()
    }, [groupId])

    return (
        <>
            <FormProvider {...createPresenceForm}>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <div className="border border-secondary border-2 rounded pt-4 mt-2 week-presence">
                        <h3>Presença</h3>
                        <h4>{meetingWeekPresence?.group_name}</h4>

                        <Form.Group className="mb-3 w-25">
                            <Form.Label>Data</Form.Label>
                            <p>{getDateLocale(meetingWeekPresence?.meeting_day!, 'pt-BR')}</p>
                        </Form.Group>

                        <div className="presence-container">
                            <div className="presence-users">
                                <div className='presence-users-header'>
                                    <p>Membros</p>
                                    <p>Presença?</p>
                                </div>
                                <div className='presence-users-list'>
                                    {userFields.map((field, index) => (
                                        <Form.Group key={field.id} className='presence-users-list-form'>
                                            <Form.Label>{field.name}</Form.Label>
                                            <Form.Check disabled={disabledForm} {...register(`user_presence.${index}.presence`)} type="switch" />
                                            <FormCustom.ErrorMessage field={`user_presence.${index}.presence`} />
                                        </Form.Group>
                                    ))}
                                </div>
                            </div>
                            <div className="presence-guests">
                                <div className='presence-guests-header'>
                                    <p>Convidados</p>
                                    <p>Presença?</p>
                                </div>
                                <div className='presence-guests-list'>
                                    {guestsFields.map((field, index) => (
                                        <Form.Group key={field.id} className='presence-guests-list-form'>
                                            <Form.Label>{field.name}</Form.Label>
                                            <Form.Check disabled={disabledForm} {...register(`guests_presence.${index}.presence`)} type="switch" />
                                            <FormCustom.ErrorMessage field={`guests_presence.${index}.presence`} />
                                        </Form.Group>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='week-footer'>
                            {
                                meetingWeekPresence?.disabled ? (
                                    <>
                                        {
                                            !checkIfUserIsAdmin() && (
                                                <p>A lista de presença desta reunião já foi enviada e por isso não pode mais ser atualizada.</p>
                                            )
                                        }
                                        {checkIfUserIsAdmin() && (
                                              <Button
                                              variant="primary"
                                              type="submit"
                                              className="mb-3 mr-1 m-1"
                                          >
                                              {t("actionEdit")}
                                          </Button>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <p><span>Importante: </span>Ao enviar a lista de participação, esta reunião não poderá mais ser atualizada</p>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="mb-3 mr-1 m-1"
                                        >
                                            {t("submit")}
                                        </Button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </Form>
            </FormProvider>
        </>
    )
}