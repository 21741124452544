import "bootstrap/dist/css/bootstrap.min.css"
import './styles/default.css'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter } from "react-router-dom"
import Routes from "./routes/Index"
import { AuthProvider } from "./contexts/Auth"
import { GuardProvider } from "./contexts/Guard"

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <GuardProvider>
          <Routes />
        </GuardProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
