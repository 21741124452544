import { Outlet } from "react-router-dom"
import Sidebar from "../../components/admin/Sidebar"

const Admin: React.FC = () => {

    return (
        <div className="d-flex flex-row">
            <div>
                <Sidebar />
            </div>
            <div className="w-100">
                <Outlet />
            </div>
        </div>
    )
}


export default Admin