import './presenceUser.css';
export interface IPresenceUserProps { 
    name: string;
    id: number;
    id_group: number;
    name_group: string;
    index: number  
    remove: (index: number) => void; 
}

export const PresenceUser: React.FC<IPresenceUserProps> = ({ name, name_group, remove, id, index }: IPresenceUserProps) => {
  
  
  
  return (
      <div className="presence-user">
        <div className='container'>
           {index !== 0 ? (
             <div onClick={() => remove(index)} className='close'>x</div>
           ): ''}
            <span>{`${name} (${name_group})`}</span>
        </div>
      </div>
    );
}