import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../contexts/Auth";
import Admin from "../pages/admin/Admin";
import Role from "../pages/admin/role/Role";
import Point from "../pages/admin/point/Point";
import Reason from "../pages/admin/reason/Reason";
import ReportAdmin from "../pages/admin/report/ReportAdmin";
import Contact from "../pages/contact/Contact";
import Dashboard from "../pages/dashboard/Dashboard";
import NotFound from "../pages/error/NotFound";
import Guests from "../pages/guests/Guests";
import Home from "../pages/Home";
import Indications from "../pages/indications/Indications";
import Profile from "../pages/profile/Profile";
import Rdn from "../pages/rdn/Rdn";
import Report from "../pages/report/Report";
import WeeklyMeetings from "../pages/weeklyMeetings/WeeklyMeetings";
import EditRole from "../pages/admin/role/EditRole";
import CreateRole from "../pages/admin/role/CreateRole";
import { UserIndex } from "../pages/admin/user/UserIndex";
import CreateChair from "../pages/admin/chair/CreateChair";
import EditChair from "../pages/admin/chair/EditChair";
import CreateGroup from "../pages/admin/group/CreateGroup";
import EditGroup from "../pages/admin/group/EditGroup";
import CreateCompany from "../pages/admin/company/CreateCompany";
import EditCompany from "../pages/admin/company/EditCompany";
import User from "../pages/admin/user/User";
import Company from "../pages/admin/company/Company";
import Chair from "../pages/admin/chair/Chair";
import Group from "../pages/admin/group/Group";
import { CreateReason } from "../pages/admin/reason/CreateReason";
import { EditReason } from "../pages/admin/reason/EditReason";
import { ListWeekMeetingPresence } from "../pages/weeklyMeetings/components/ListWeekMeetingPresence";
import { WeekMeetingRoute } from "../pages/weeklyMeetings/WeekMeetingRoute";
import { TrainingMeetingRoute } from "../pages/training-meeting/TrainingMeetingRoute";
import { TrainingMeeting } from "../pages/training-meeting/TrainingMeeting";
import { ListTrainingMeetingPresence } from "../pages/training-meeting/components/ListTrainingMeetingPresence";
import { LeadershipMeeting } from "../pages/leadership-meeting/LeadershipMeeting";
import { IndicationRoute } from "../pages/indications/IndicationRoute";
import { ShowIndication } from "../pages/indications/components/ShowIndication";
import { BussinesRoute } from "../pages/Bussines/BussinesRoute";
import { Bussines } from "../pages/Bussines/Bussines";
import { ShowBussines } from "../pages/Bussines/components/ShowBussines";
import { HomeScreen } from "../pages/admin/home-screen/HomeScreen";
import { ExtractSpeficPeriod } from "../pages/extract-specific-period/ExtractSpeficPeriod";
import { BirthdayReport } from "../pages/report/birthday/BirthdayReport";
import { RankingReport } from "../pages/report/ranking/RankingReport";
import { MemberReport } from "../pages/report/member/MemberReport";
import { PresentationReport } from "../pages/report/presentation/PresentationReport";
import { UserForm } from "../pages/admin/user/Form/UserForm";
import { MonthlyPunctuationReport } from "../pages/report/monthly-punctuation/MonthlyPunctuation";
import { ForgotPassword } from "../pages/forgot/ForgotPassword";
import { ReinviteGuest } from "../pages/guests/components/ReinviteGuest";
import { GuestRoute } from "../pages/guests/GuestRoute";
import PrivateRoute from "./PrivateRoute";
import Forbidden from "../pages/error/Forbidden";

const AuthRoutes: React.FC = () => {
  const {
    infoUser: { group_selected },
  } = useAuth();

  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Navigate replace to={`/grupo/${group_selected.id}`} />} />
        <Route path="/grupo/:groupId" element={<Home />}>
          <Route path="" element={<Dashboard />} />
          <Route path="extract-spefic-period" element={<ExtractSpeficPeriod />} />
          <Route path="pontuacao-mensal" element={<MonthlyPunctuationReport/>} />
          <Route path="contato" element={<Contact />} />
          <Route path="reunioes" element={<WeekMeetingRoute />}>
            <Route path="" element={
              <PrivateRoute requiredPermissions={['weekly-meetings.index', 'weekly-meetings.store', 'weekly-meetings.presence']}>
                <WeeklyMeetings />
              </PrivateRoute>
            } />
            <Route path="presence/week-meeting/:id_week" element={
              <PrivateRoute requiredPermissions={['weekly-meetings.presence']}>
                <ListWeekMeetingPresence />
              </PrivateRoute>
            } />
          </Route>
          <Route path="treinamentos" element={<TrainingMeetingRoute />}>
            <Route path="" element={
              <PrivateRoute requiredPermissions={['training_meetings_menu', 'training_meetings_store', 'training_meetings_presence']}>
                <TrainingMeeting />
              </PrivateRoute>
            } />
            <Route
              path="presence/training-meeting/:id_training"
              element={<ListTrainingMeetingPresence />}
            />
          </Route>
          <Route path="rdn" element={
            <PrivateRoute requiredPermissions={['rdn.index', 'rdn.store', 'rdn.delete']}>
              <Rdn />
            </PrivateRoute>
          } />
          <Route path="leadership" element={
            <PrivateRoute requiredPermissions={['leadership_meetings_menu', 'leadership_meetings_store', 'leadership_meetings_delete']}>
              <LeadershipMeeting />
            </PrivateRoute>
          } />
          <Route path="indicacoes" element={<IndicationRoute />}>
            <Route path="" element={
              <PrivateRoute requiredPermissions={['indications.index', 'indications.store', 'indications.update']}>
                <Indications />
              </PrivateRoute>
            } />
            <Route path=":id_indication" element={
              <PrivateRoute requiredPermissions={['indications.update']}>
                <ShowIndication />
              </PrivateRoute>
            } />
          </Route>
          <Route path="bussines" element={<BussinesRoute />}>
            <Route path="" element={
              <PrivateRoute requiredPermissions={['business.index']}>
                <Bussines />
              </PrivateRoute>
            } />
            <Route path=":idIndication" element={
              <PrivateRoute requiredPermissions={['business.index']}>
                <ShowBussines />
              </PrivateRoute>
            } />
          </Route>
          <Route path="convidados" element={<GuestRoute />}>
            <Route path="" element={
              <PrivateRoute requiredPermissions={['guests.index', 'guests.store', 'guests.re-invite']}>
                <Guests />
              </PrivateRoute>
            } />
            <Route path="reconvidar/:guest/:name" element={
              <PrivateRoute requiredPermissions={['guests.re-invite']}>
                <ReinviteGuest />
              </PrivateRoute>
            } />
          </Route>
          <Route path="reinvite-guest/:guest" element={
            <PrivateRoute requiredPermissions={['guests.re-invite']}>
              <ReinviteGuest/>
            </PrivateRoute>
          } />
          <Route path="perfil/:userId" element={<Profile />} />
          <Route path="relatorios" element={<Report />}>
            <Route path="apresentacao" element={
              <PrivateRoute requiredPermissions={['report.apresentation']}>
                <PresentationReport />
              </PrivateRoute>
            } />
            <Route path="membros" element={
              <PrivateRoute requiredPermissions={['report.members']}>
                <MemberReport />
              </PrivateRoute>
            } />
            <Route path="ranking" element={
              <PrivateRoute requiredPermissions={['report.ranking']}>
                <RankingReport />
              </PrivateRoute>
            } />
            <Route path="aniversariantes" element={
              <PrivateRoute requiredPermissions={['report.birthday']}>
                <BirthdayReport />
              </PrivateRoute>
            } />
          </Route>
          <Route path="admin" element={<Admin />}>
            <Route path="usuarios" element={<User />}>
              <Route path="" element={
                <PrivateRoute requiredPermissions={['user.update', 'user.index', 'user.delete', 'user.store']}>
                  <UserIndex />
                </PrivateRoute>
              } />
              <Route path=":userId" element={
                <PrivateRoute requiredPermissions={['user.update']}>
                  <UserForm />
                </PrivateRoute>
              } />
            </Route>
            <Route path="cadeiras" element={<Chair />}>
              <Route path="" element={
                <PrivateRoute requiredPermissions={[
                  'chair.index', 
                  'subChair.delete', 
                  'subChair.update', 
                  'subChair.store', 
                  'subChair.index',
                  'chair.store',
                  'chair.update',
                  'chair.delete'
              ]}>
                  <CreateChair />
                </PrivateRoute>
              } />
              <Route path=":chairId" element={ <EditChair />} />
            </Route>
            <Route path="motivos" element={<Reason />}>
              <Route path="" element={
                <PrivateRoute requiredPermissions={['reason.index', 'reason.update', 'reason.delete', 'reason.store']}>
                  <CreateReason />
                </PrivateRoute>
              } />
              <Route path=":reasonId" element={
                <PrivateRoute requiredPermissions={['reason.update']}>
                  <EditReason />
                </PrivateRoute>
              } />
            </Route>
            <Route path="grupos" element={<Group />}>
              <Route path="" element={
                <PrivateRoute requiredPermissions={['group.index', 'group.update', 'group.delete', 'group.store']}>
                  <CreateGroup />
                </PrivateRoute>
              } />
              <Route path=":id" element={
                <PrivateRoute requiredPermissions={['group.update']}>
                  <EditGroup />
                </PrivateRoute>
              } />
            </Route>
            <Route path="empresas" element={<Company />}>
              <Route path="" element={
                <PrivateRoute requiredPermissions={['company.index', 'company.store', 'company.delete', 'company.update']}>
                  <CreateCompany />
                </PrivateRoute>
              } />
              <Route path=":companyId" element={
                <PrivateRoute requiredPermissions={['company.update']}>
                  <EditCompany />
                </PrivateRoute>
              } />
            </Route>
            <Route path="pontuacoes" element={
              <PrivateRoute requiredPermissions={['point.index', 'point.update']}>
                <Point />
              </PrivateRoute>
            } />
            <Route path="permissoes" element={<Role />}>
              <Route path="" element={
                <PrivateRoute requiredPermissions={['role.index', 'role.store', 'role.store', 'role.delete']}>
                  <CreateRole />
                </PrivateRoute>
              } />
              <Route path=":roleId" element={ <EditRole />} />
            </Route>
            <Route path="home-screen" element={
              <PrivateRoute requiredPermissions={['home-screen']}>
                <HomeScreen />
              </PrivateRoute>
            } />
          </Route>
        </Route>
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/forgot"  element={<ForgotPassword />} />
        <Route path="/forbidden"  element={<Forbidden />} />
      </Routes>
    </div>
  );
};

export default AuthRoutes;
