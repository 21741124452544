import { Button, Col, Form, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SelectUserStatus } from "./SelectUserStatus";
import useAuth from "../../../../contexts/Auth";
import { useGroup } from "../../../../hooks/useGroup";
import useGuard from "../../../../contexts/Guard";


type FilterMemberProps = {
  exportRef: React.RefObject<HTMLDivElement>
  isLoading: boolean
  onChangePerPage: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onChangeGroup: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onChangeType: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onChangeInputSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  executeSearch: () => void
  generateCsv: () =>void
}

export const FilterMember = ({  
  isLoading,
  onChangePerPage,
  onChangeGroup,
  onChangeType,
  onChangeInputSearch,
  executeSearch,
  generateCsv
}: FilterMemberProps) => {
  const { groups } = useGroup()
  const { infoUser, groups: groupsHasAccess } = useAuth()
  const { checkIfUserIsAdmin, CheckPermissionWithArray } = useGuard()
  const { t } = useTranslation()
  const limitList = [10, 25, 50, 100]
  const groupOptions = checkIfUserIsAdmin() ? groups : groupsHasAccess

  

  return (
    <>
      <Col lg={4}>
        <div className="d-flex flex-column justify-content-start w-75">
          {CheckPermissionWithArray(['generate_report']) && (
            <div className="w-100">           
            <Button
              variant="primary"
              type="submit"
              className="fw-semibold text-uppercase"
              onClick={generateCsv}
            >
              {t('printExport')}
            </Button>
          </div>
          )}
        
          <FormGroup className="d-flex align-items-end mt-3">
            <Form.Select
              onChange={onChangePerPage}
              className="rounded-4 mw-10"
              style={{ width: '80px' }}
              disabled={isLoading}
            >
              {
                limitList.map(item => (
                  <option
                    key={item}
                    value={item}
                  >
                    {item}
                  </option>
                ))
              }
            </Form.Select>

            <Form.Label className="fs-6 fw-semibold ms-2 mb-1 d-block">{t('labelLimitTable')}</Form.Label>
          </FormGroup>
        </div>
      </Col>

      <Col lg={8}>
        <div className="d-flex align-items-end justify-content-between h-100">
          <FormGroup className="w-30 mx-2">
            <Form.Label>
              {t('filterByGroup')}
            </Form.Label>

            <Form.Select
              onChange={onChangeGroup}
              disabled={isLoading}
            >
              {
                groupOptions.map(item => (
                  <option
                    key={item.id}
                    value={item.id}
                    selected={item.id === infoUser.group_selected.id}
                  >
                    {item.name}
                  </option>
                ))
              }
            </Form.Select>
          </FormGroup>

          <SelectUserStatus
            isDisable={isLoading}
            onChange={onChangeType}
          />

          <div className="d-flex align-items-center w-40 mx-2">
            <Form.Control
              type="text"
              id="lookFor"
              placeholder={t('filterByName')}
              onChange={onChangeInputSearch}
              disabled={isLoading}
              autoComplete="off"
            />

            <Button
              variant="secondary"
              type="button"
              className="ms-3 px-4"
              onClick={() => executeSearch()}
              disabled={isLoading}
            >
              {t('lookFor')}
            </Button>
          </div>
        </div>
      </Col>
    </>
  );
}