import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { TitlePage } from "../components/TitlePgae"
import { useTranslation } from "react-i18next"
import { PunctuationItem } from "./PunctuationItem/PunctuationItem"
import useAuth from "../../../contexts/Auth"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { getMonthlyPunctuationReport, getMonthlyPunctuationReportCsv } from "../../../api/report/report"
import { downloadAndConvertBufferToBlob, getCurrentYear, getYearOfRegisterUserLogged } from "../../../utils/Helper"
import { MonthlyPunctuation } from "../../../interfaces/report/IMonthlyPunctuation"
import { FormGroup } from "@mui/material"
import { LoadingReport } from "../components/LoadingReport"

export const MonthlyPunctuationReport = () => {
  const { t } = useTranslation()
  const { infoUser } = useAuth()

  const exportRef = useRef<HTMLDivElement>(null);
  const yearFilterRef = useRef<number>(getCurrentYear());
  const yearOptions: number[] = getYearOfRegisterUserLogged()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listMonthlyPunctuation, setListMonthlyPunctuation] = useState<MonthlyPunctuation[]>([])

  const getReport = async () => {
    try {
      setIsLoading(true)

      const { data } = await getMonthlyPunctuationReport({
        year: yearFilterRef.current,
        groupId: infoUser.group_selected.id
      })

      setListMonthlyPunctuation(data.data)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeYearFilter = (e: ChangeEvent<HTMLSelectElement>) => {
    yearFilterRef.current = Number(e.target.value)
    getReport()
  }

  const generateCsv = async () => {
    const { data } = await getMonthlyPunctuationReportCsv({
      year: yearFilterRef.current,
      groupId: infoUser.group_selected.id
    })

    downloadAndConvertBufferToBlob(data, t('report_monthly_punctuation'))
  }

  useEffect(() => {
    getReport()
  }, [])

  return (
    <Container className="vh-100 mt-5">
      <Row>
        <TitlePage title="report_monthly_punctuation" />
        <p className="w-50 fw-semibold">{t('report_monthly_punctuation_description')}</p>
      </Row>

      <Row ref={exportRef}>
        <FormGroup className="d-flex  flex-row align-items-baseline mt-2">
          <Form.Select
            onChange={onChangeYearFilter}
            className="rounded-4"
            style={{ width: '80px' }}
            disabled={isLoading}
          >
            {
              yearOptions.map(year => (
                <option
                  key={year}
                  value={year}
                  selected={year === yearFilterRef.current}
                >
                  {year}
                </option>
              ))
            }
          </Form.Select>

          <Form.Label className="fs-6 ms-2">{t('selectYear')}</Form.Label>
        </FormGroup>
      </Row>

      <Row className="mt-5">
        {isLoading && <LoadingReport />}

        {
          !isLoading && !listMonthlyPunctuation.length && (
            <p className="mt-3">{t('noDataMonthlyPunctuation')}</p>
          )
        }

        {
          (!isLoading && !!listMonthlyPunctuation.length) && (
            listMonthlyPunctuation.map(item => (
              <PunctuationItem
                key={item.period}
                period={item.period}
                absence={item.absence}
                rdn={item.rdn}
                guests={item.guests}
                guestMembers={item.guestMembers}
                training={item.training}
                leadershipMeeting={item.leadershipMeeting}
                indication={item.indication}
                closedIndication={item.closedIndication}
              />
            ))
          )
        }
      </Row>

      <Row className="mt-5">
        <Col lg={2} className="ms-auto">
          <Button
            variant="primary"
            type="submit"
            className="fw-semibold text-uppercase"
            onClick={generateCsv}
          >
            {t('printExport')}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}