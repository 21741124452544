import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormCustom } from "../../../components/Form";
import { getWeeks } from "../../../api/week/Week";
import { WeekResponseGet } from "../../../interfaces/week/Week.interface";
import { IGroupAllPaginatorResponse } from "../../../interfaces/group/IGetGroups";
import {
  createGroup,
  deleteGroup,
  getAllgroupsPaginator,
} from "../../../api/group/Group";
import { IResponseGeneric } from "../../../interfaces/generic/generic.interface";
import ConfirmationModal from "../../../components/custom/ConfirmationModal";
import { TableCustomComponent } from "../../../components/Table";
import { ITableComponentCols } from "../../../components/Table/TableComponent";
import { searchFactory } from "../../../factory/searchFactory";
import { ITableSearchFormData } from "../../../components/Table/TableSearchComponent";
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent";
import useGuard from "../../../contexts/Guard";

const groupCreateSchema = z.object({
  name: z
    .string({ required_error: "form_name_required" })
    .nonempty("form_name_required"),
  local: z
    .string({ required_error: "form_locations_required" })
    .nonempty("form_locations_required"),
  day_week: z.coerce
    .number({ required_error: "form_day_week_required" })
    .refine((day) => day > 0, "form_choose_day_required"),
});

export type CraeteGroupFormData = z.infer<typeof groupCreateSchema>;

const CreateGroup: React.FC = () => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { CheckPermissionWithArray } = useGuard()

  const [weeks, setWeeks] = useState<WeekResponseGet[]>([]);
  const [groups, setGroups] = useState<IResponseGeneric<IGroupAllPaginatorResponse>>(
    {} as IResponseGeneric<IGroupAllPaginatorResponse>
  );
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowGroup, setRowGroup] = useState<IGroupAllPaginatorResponse>(
    {} as IGroupAllPaginatorResponse
  );
  const [searchParams, setSearchParams] = useState('')

  const createGroupForm = useForm<CraeteGroupFormData>({
    resolver: zodResolver(groupCreateSchema),
  });

  const { handleSubmit, register, reset, setValue } = createGroupForm;

  const recordMap = (data: IGroupAllPaginatorResponse[]): IGroupAllPaginatorResponse[] => {
    return data.map((item) => {
      return {
        name: item.name,
        week_day: t(item.week_day),
        id: item.id
      }
    })
  }

  const getData = async () => {
    const params = searchFactory().createUrlParams({
      page: 1,
    })

    const { data } = await getAllgroupsPaginator({ groupId: +groupId!, meta: params })
    setGroups({ data: recordMap(data.data), meta: data.meta })
  };

  const searchGroup = async ({ search }: ITableSearchFormData) => {
    const params = searchFactory().createUrlParams({
      page: 1,
      name: search
    })
    setSearchParams(search)
    const { data } = await getAllgroupsPaginator({ groupId: +groupId!, meta: params })
    setGroups({ data: recordMap(data.data), meta: data.meta })
  }
  const paginatorGroup = async ({ page }: IPaginationsCallback) => {
    const params = searchFactory().createUrlParams({
      page,
      name: searchParams
    })
    const { data } = await getAllgroupsPaginator({ groupId: +groupId!, meta: params })
    setGroups({ data: recordMap(data.data), meta: data.meta })
  }

  const getWeeksApi = useCallback(async (groupId: number) => {
    getWeeks({ groupId: groupId }).then((response) => {
      const weekMap = response.data.map((item) => {
        return {
          id: item.id,
          name: t(item.name)
        }
      })
      setWeeks(weekMap);
      setValue("day_week", 1);
    });
  }, []);

  const columns: ITableComponentCols[] = [

    {
      title: t('group_name'), render: (row: IGroupAllPaginatorResponse) => (
        <span>{row.name}</span>
      )
    },
    {
      title: t('meeting_day'), render: (row: IGroupAllPaginatorResponse) => (
        <span>{row.week_day}</span>
      )
    },
    {
      title: 'Ações',
      permissions: ['group.update', 'group.delete'],
      render: (row: IGroupAllPaginatorResponse) => (
        <>
          <div>
            {CheckPermissionWithArray(['group.update']) && (
              <Button
                variant="primary"
                type="button"
                className="w-25 me-4"
                onClick={() => {
                  navigate(`${row.id}`);
                }}
              >
                {t('actionEdit')}
              </Button>
            )}
            {CheckPermissionWithArray(['group.delete']) && (
              <Button
                variant="danger"
                type="button"
                onClick={() => {
                  setRowGroup(row);
                  setShowDeleteModal(true);
                }}
              >
                {t('actionDelete')}
              </Button>
            )}
          </div>
        </>
      )
    }
  ];

  const handleGoBack = () => {
    setActiveTab(0);
  }

  const onDeleteGroup = () => {
    deleteGroup({
      groupId: +groupId!,
      id: rowGroup.id,
    }).then(() => {
      getData();
      handleCloseModalDelete();
    });
  };

  const handleCloseModalDelete = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getWeeksApi(+groupId!);
  }, [groupId]);

  const onSubmit = async (data: CraeteGroupFormData) => {
    createGroup({
      groupId: +groupId!,
      id_week: data.day_week,
      local: data.local,
      name: data.name,
    }).then(() => {
      getData();
      setActiveTab(0);
      reset();
    });
  };

  return (
    <div className="m-2 vh-100">
      <Container fluid>
        <Row>
          <Col>
            <Tabs
              activeKey={activeTab}
              onSelect={(index) => setActiveTab(+index!)}
              className="mb-3"
              defaultActiveKey="listGroup"
            >
              <Tab eventKey={0} title={t("groupListing")}>
                <TableCustomComponent.Root>
                  <Col>
                    <TableCustomComponent.Search callback={searchGroup} />
                  </Col>
                  <Col>
                    <TableCustomComponent.Table
                      striped
                      cols={columns}
                      data={groups.data}
                    />
                  </Col>
                  <Col>
                    <TableCustomComponent.Pagination {...groups.meta} callback={paginatorGroup} />
                  </Col>

                </TableCustomComponent.Root>
              </Tab>

              {CheckPermissionWithArray(['group.store']) && (
                <Tab eventKey={1} title={t("createGroup")}>
                  <FormProvider {...createGroupForm}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Container fluid>
                        <Row style={{ height: "70vh" }}>
                          <Col>
                            <Form.Group>
                              <Form.Label htmlFor="name">{t('name')}</Form.Label>
                              <Form.Control
                                type="text"
                                id="name"
                                {...register("name")}
                              />
                            </Form.Group>
                            <FormCustom.ErrorMessage field="name" />
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Label htmlFor="local">Local</Form.Label>
                              <Form.Control
                                type="text"
                                id="local"
                                {...register("local")}
                              />
                            </Form.Group>
                            <FormCustom.ErrorMessage field="local" />
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Label htmlFor="dayWeek">
                                {t('dayOfWeek')}
                              </Form.Label>
                              <Form.Select {...register("day_week")} id="dayWeek">
                                {weeks.map((item, i) => {
                                  return (
                                    <option key={i} value={item.id}>
                                      {t(item.name)}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                            <FormCustom.ErrorMessage field="day_week" />
                          </Col>
                        </Row>

                        <Row className="justify-content-md-end">
                          <Col xl={3} sm={3} md={3} className="mt-3">
                            <Button
                              variant="primary"
                              type="submit"
                              className="float-end mb-3 mr-1 m-1"
                            >
                              {t("submit")}
                            </Button>
                            <Button
                              variant="secondary"
                              type="button"
                              className="float-end mb-3 mr-1 m-1"
                              onClick={handleGoBack}
                            >
                              {t("goback")}
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </FormProvider>
                </Tab>
              )}
            </Tabs>
          </Col>
        </Row>
      </Container>

      <ConfirmationModal
        show={showDeleteModal}
        message={t("messageConfirmationModal")}
        onConfirm={onDeleteGroup}
        onDecline={handleCloseModalDelete}
      />
    </div>
  );
};

export default CreateGroup;
