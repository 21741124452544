import { IDasboardImagesStoreParams, IDashboardImagesResponse, IDashboardOveralPerformanceResponse, IDashboardSideBardResponse, IDashboardSidebarPerformanceParams } from '../../interfaces/dashboard/dashboard.interface'
import api from '../Api'

export const getOverallPerformance = async (id_group: number) => {
  return await api.get<IDashboardOveralPerformanceResponse>(`dashboard/group/${id_group}/overall-performance`)
}

export const getSidebarPerformance = async (params: IDashboardSidebarPerformanceParams) => {
  return await api.get<IDashboardSideBardResponse>(`dashboard/group/${params.id_group}/sidebar-performance`, {
    params: {
      year: params.year,
      month: params.month
    }
  })
}

export const getImagesAdmin = async (id_group: number) => {
  return await api.get<IDashboardImagesResponse[]>(`group/${id_group}/dashboard-image`)
}

export const getImages = async (id_group: number) => {
  return await api.get<IDashboardImagesResponse[]>(`group/${id_group}/dashboard`)
}

export const storeImages = async ({ file, id_group, name, size }: IDasboardImagesStoreParams) => {
  return await api.post<IDashboardImagesResponse>(`group/${id_group}/dashboard-image`, { file, name, size })
}

export const destroyImages = async (id_group: number, id_dashboard_image: number) => {
  return await api.delete(`group/${id_group}/dashboard-image`, {
    params: {
      id_dashboard_image
    }
  })
}

export const reportYearScore = async (id_group: number) => {
  return await api.get< [string, number][]>(`dashboard/group/${id_group}/monthly-score`)
}