
import { IListParamsSelected, IListUserByGroupResponse } from '../../interfaces/list/list.interface'
import './listUser.css'
export interface ListUserProps {
   data: IListUserByGroupResponse[]
   add: (data: IListParamsSelected) => void
   fields: IListParamsSelected[]
}

export const ListUser: React.FC<ListUserProps> = ({
    data, 
    add,
    fields
}) => {

    const handleSelected = (data: IListParamsSelected) => {        
        add(data)
    }

    const active = (user_id: number, group_id: number): string => {
        const find = fields.find((item) => item.user_id === user_id && item.group_id === group_id)
        if(find) {
            return 'text-focus'
        }
        return ''
    }
   
    return (
        <div className="list-user">
            <div className="member">
               <span className='text-focus'>Membros</span>
               {data.map((member, index) => (
                     <p className={active(member.user_id, member.group_id)} key={index} onClick={() => {handleSelected({ 
                        group_id: member.group_id, 
                        group_name: member.group_name,
                        user_id: member.user_id,
                        user_name: member.user_name, 

                    })}}>{member.user_name}</p>
               ))}              
            </div>
            <div className="company">
                <span className='text-focus'>Empresa</span>
                {data.map((company, index) => (
                    <p className={active(company.user_id, company.group_id)} key={index} onClick={() => handleSelected({
                        group_id: company.group_id,
                        group_name: company.group_name,
                        user_id: company.user_id,
                        user_name: company.user_name,                        
                    })}>{company.company_name}</p>                    
                ))}               
            </div>
        </div>
    )
}