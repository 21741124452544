import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { z } from 'zod'
import { FormCustom } from "../../../components/Form"
import { useEffect, useState } from "react"
import { ISelectDefault } from "../../../interfaces/form/form.interface"
import { useTranslation } from "react-i18next"
import { useGroup } from "../../../hooks/useGroup"
import { getOptionsDate, guestReinvite } from "../../../api/guests/guests"
import { getDateLocale } from "../../../utils/Helper"
import './reinviteGuest.css'

const reinviteSchema = z.object({
  meetingDate: z.coerce.date(),
  groupId: z.coerce.number().positive(),
})

type IReinviteFormData = z.infer<typeof reinviteSchema>

export const ReinviteGuest: React.FC = () => {

  const { guest, name, groupId } = useParams()
  const { selectGroup } = useGroup()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [dateOptions, setDateOptions] = useState<ISelectDefault[]>([])  

  const reinviteForm = useForm<IReinviteFormData>({
    resolver: zodResolver(reinviteSchema)
  })

  const {
    handleSubmit,
    watch,    
  } = reinviteForm

  const group = watch('groupId')
  const goback = () => {    
    navigate('../')
  }

  const onSubmit = async (data: IReinviteFormData) => {
    const { status } = await guestReinvite({
      id_guest: +guest!,
      id_group_created: +groupId!,
      id_group: data.groupId,
      meeting_day: data.meetingDate
    })

    if (status === 200) {
      goback()
    }
  }

  useEffect(() => {
    if (group) {
      getOptionsDate(group).then((response) => {
        const { data } = response
        const dateOptions = data.map((item) => ({
          value: item.date,
          label: getDateLocale(item.date, 'pt-BR')
        }))

        setDateOptions(dateOptions)
      })
    }
  }, [group])

  return (
    <FormProvider {...reinviteForm}>
      <Container fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="reinvite_info">
            <p>Você estar reconvidado o convidado: {name}</p>
          </Row>
          <Row className="reinvite_container">
            <Form.Group className="mb-3 w-25">
              <Form.Label htmlFor="groupId">{t('group')}</Form.Label>
              <FormCustom.Select field="groupId" id="groupId" options={selectGroup} />
            </Form.Group>
            <Form.Group className="mb-3 w-25">
              <Form.Label htmlFor="meetingDate">{t('guest_day_title')}</Form.Label>
              <FormCustom.Select field="meetingDate" id="meetingDate" options={dateOptions} />
            </Form.Group>
          </Row>
          <Row className="justify-content-md-end">
            <Col xl={3} sm={3} md={3} className="mt-3">
              <Button
                variant="primary"
                type="submit"
                className="float-end mb-3 mr-1 m-1"
              >
                {t("submit")}
              </Button>
              <Button
                variant="secondary"
                type="button"
                className="float-end mb-3 mr-1 m-1" 
                onClick={goback}
              >
                {t("goback")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </FormProvider>
  )
}