import { Outlet } from "react-router-dom";

const Report: React.FC = () => {
  return (
    <div className="vh-100 m-5">
      <Outlet />
    </div>
  );
};

export default Report;
