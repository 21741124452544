
import './SystemInfo.css';
import { Alert } from './components/alert/Alert';
import { Ranking } from './components/ranking/Ranking';
import { SystemSupport } from './components/system-support/SystemSupport';
export const SystemInfo: React.FC = () => {
  return (
    <div className='system-info-container'>
      <Ranking />
      <SystemSupport />
      <Alert />
    </div>
  )
}