import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IResponseUser } from "../../../interfaces/user/IGetUsers";
import { ITableComponentCols } from "../../../components/Table/TableComponent";
import { TableCustomComponent } from "../../../components/Table";
import { useUser } from "./hook/useUser";
import { UserForm } from "./Form/UserForm";
import useGuard from "../../../contexts/Guard";

export const UserIndex: React.FC = () => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const { CheckPermissionWithArray } = useGuard()

  const navigate = useNavigate();

  const {
    users,
    fetchDeleteUser,
    fetchUsersPaginator,
    searchUsers,
    refleshUser
  } = useUser(+groupId!);

  const [activeTab, setActiveTab] = useState<number>(0);

  const columns: ITableComponentCols[] = [
    {
      title: t('name'),
      render: (row: IResponseUser) => (
        <span>{row.name}</span>
      ),
    },
    {
      title: "email",
      render: (row: IResponseUser) => (
        <span>{row.email}</span>
      ),
    },
    {
      title: "Grupo",
      render: (row: IResponseUser) => (
        <span>{row.group}</span>
      ),
    },
    {
      title: 'Ações',
     permissions: ['user.update', 'user.delete'],
      render: (row: IResponseUser) => (
        <>
          <div>
            {CheckPermissionWithArray(['user.update']) && (
              <Button
                variant="primary"
                type="button"
                className="me-4"
                onClick={() => {
                  navigate(`${row.id}`);
                }}
              >
                {t('actionEdit')}
              </Button>
            )}
            {CheckPermissionWithArray(['user.delete']) && (
              <Button
                variant="danger"
                type="button"
                onClick={() => {
                  fetchDeleteUser(row.id);
                }}
              >
                {t('actionDelete')}
              </Button>
            )}

          </div>
        </>
      ),
    },
  ];

  const handleGoBack = () => {
    setActiveTab(0);
  }

  const listeningCreateOrUpdate = (e: boolean) => {
    refleshUser()
  }
  
  return (
    <div className="m-2 vh-100">
      <Container fluid>
        <Row >
          <Col>
            <Tabs
              activeKey={activeTab}
              defaultActiveKey="listUser"
              onSelect={(index) => setActiveTab(+index!)}
              transition={false}
              className="mb-3"
            >
              <Tab eventKey={0} title={t("listOfUsers")}>
                <TableCustomComponent.Root>
                  <Col>
                    <TableCustomComponent.Search
                      callback={searchUsers}
                    />
                  </Col>
                  <Col>
                    <TableCustomComponent.Table
                      striped
                      cols={columns}
                      data={users.data}
                    />
                  </Col>
                  <Col>
                    <TableCustomComponent.Pagination
                      {...users.meta}
                      callback={fetchUsersPaginator}
                    />
                  </Col>
                </TableCustomComponent.Root>

              </Tab>
              {CheckPermissionWithArray(['user.store']) && (
                <Tab eventKey={1} title={t("createUser")}>
                  <UserForm
                    handleGoback={handleGoBack}
                    emitCreatedOrUpdateUser={listeningCreateOrUpdate}
                  />
                </Tab>
              )}

            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};