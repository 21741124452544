import { useTranslation } from 'react-i18next';
import { TableCustomComponent } from '../../../../components/Table';
import { ITableComponentCols } from '../../../../components/Table/TableComponent';
import { getCurrency } from '../../../../utils/Helper';
import { MonthlyPunctuation } from '../../../../interfaces/report/IMonthlyPunctuation';
import './punctuationItem.css';

export const PunctuationItem = (props: MonthlyPunctuation) => {
  const { t } = useTranslation()
  
  const cols: ITableComponentCols[] = [
    {
      title: t('ABSENCE').toUpperCase(),
      render: (row: MonthlyPunctuation) => (<span>{row.absence}</span>)
    },
    {
      title: t('RDN').toUpperCase(),
      render: (row: MonthlyPunctuation) => (<span>{row.rdn}</span>)
    },
    {
      title: t('guests').toUpperCase(),
      render: (row: MonthlyPunctuation) => (<span>{row.guests}</span>)
    },
    {
      title: t('guests_members').toUpperCase(),
      render: (row: MonthlyPunctuation) => (<span>{row.guestMembers}</span>)
    },
    {
      title: t('trainingTitle').toUpperCase(),
      render: (row: MonthlyPunctuation) => (<span>{row.training}</span>)
    },
    {
      title: t('LEADERSHIP_MEETING').toUpperCase(),
      render: (row: MonthlyPunctuation) => (<span>{row.leadershipMeeting}</span>)
    },
    {
      title: t('indicationsTitle').toUpperCase(),
      render: (row: MonthlyPunctuation) => (<span>{row.indication}</span>)
    },
    {
      title: t('closed_indications').toUpperCase(),
      render: (row: MonthlyPunctuation) => (
        <span>{ getCurrency(row.closedIndication ? +row.closedIndication : 0) }</span>
      )
    }
  ]
  
  return (
    <div className="punctuation mb-3">
      <span className="fw-bold mb-2 d-block ps-4">
        { props.period }
      </span>

      <TableCustomComponent.Root>
        <TableCustomComponent.Table
          hover={true}
          striped={true}
          cols={cols}
          data={[props]}
        />
      </TableCustomComponent.Root>
    </div>
  );
}