import { ModalFooter } from "react-bootstrap";
import { ModalContent } from "./ModalContent";
import { ModalRoot } from "./ModalRoot";
import { ModalActions } from "./ModalActions";
import { ModalHeaderComponent } from "./ModalHeader";

export const ModalComponent = {
    Root: ModalRoot,
    Content: ModalContent,
    Header: ModalHeaderComponent,
    Footer: ModalFooter,
    Actions: ModalActions
}