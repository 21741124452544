import { Col, Button, Row, Container, Card } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const Forbidden: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleBack = () => {
        navigate('/')
    }

    return (
        <div style={{
            backgroundImage: "url(/assets/img/login/nexus-bg.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom center",
            height: "100vh"
        }}>
            <Container>
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        <Card className="shadow">
                            <Card.Body>
                                <div className="mb-3 mt-md-4 my-auto text-center">
                                    <h2 className="fw-bold mb-2 text-uppercase text-center">
                                        <img className="w-50 px-5" alt="logo" src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/login/nexus-logo.png`}></img>
                                    </h2>
                                    <p className="text-center text-white">
                                        {t('accessDenied')}
                                    </p>
                                    <Button variant="primary" onClick={handleBack}>
                                        {t('homePage')}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Forbidden