import { IListUserByGroupResponse, listAllGroupResponse } from '../../interfaces/list/list.interface'
import { IReasonResponse } from '../../interfaces/reason/reason.interface'
import api from '../Api'

export const listAllGroup = async () => {
    return await api.get<listAllGroupResponse[]>('list/all-group')    
}

export const listUserByGroup = async (group_id: number) => {
    return await api.get<IListUserByGroupResponse[]>(`list/group/${group_id}`)
}

export const listReason = async () => {
    return await api.get<IReasonResponse[]>('list/reason')
}
