import { useTranslation } from "react-i18next"
import { ISelectDefault } from "../../interfaces/form/form.interface"
import { StatusUser } from "../../interfaces/enum/statusUser.enum"
import { FormCustom } from "."
import { FormSelectProps } from "react-bootstrap"

interface IFormStatusProps extends FormSelectProps {
  field: string
}

export const FormStatus: React.FC<IFormStatusProps> = ({
  field,
  ...rest
}) => {
  const { t } = useTranslation()

  const status: ISelectDefault[] = [
    {
      label: t(StatusUser.MEMBER),
      value: StatusUser.MEMBER
    },
    {
      label: t(StatusUser.INACTIVE_MEMBER),
      value: StatusUser.INACTIVE_MEMBER
    },
    {
      label: t(StatusUser.FORMER_MEMBER),
      value: StatusUser.FORMER_MEMBER
    },
    {
      label: t(StatusUser.EXEMPT_MEMBER),
      value: StatusUser.EXEMPT_MEMBER
    },
  ]

  return (
    <FormCustom.Select options={status} field={field} {...rest} />
  )
}