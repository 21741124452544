import { TableCustomComponent } from '../../../../components/Table'
import { ITableComponentCols, TableComponent } from '../../../../components/Table/TableComponent'
import './cardExtract.css'

export interface ICardExtractProps {
  title: string
  subtitle: string
  descriptions: string | null
  cols: ITableComponentCols[]
  data: any
}

export const CardExtract: React.FC<ICardExtractProps> = ({
  cols,
  data,
  subtitle,
  title,
  descriptions
}) => {
  return (
    <div className='card-extract__container'>
      <h2>{title}</h2>
      <p>{subtitle}</p>
      {
        descriptions && (
          <p>{descriptions}</p>
        )
      }

      <div className='card-extract__table'>
        <TableCustomComponent.Root>
          <TableCustomComponent.Table cols={cols} data={data} />
        </TableCustomComponent.Root>
      </div>
    </div>
  )
}