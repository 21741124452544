import { Button, Form, Modal } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormCustom } from "../../../../components/Form"
import { ISubChairResponse } from "../../../../interfaces/chair/IGetChairs"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const editSubchairSchema = z.object({
  name: z.string().nonempty('form_name_required')
})

export type IEditSubchairFormData = z.infer<typeof editSubchairSchema>

interface IMOdalEditSubChairProps {
  show: boolean
  infoSubChair: ISubChairResponse
  onSave: (e: IEditSubchairFormData) => void
  onCancel: () => void
}

export const ModalEditSubChair: React.FC<IMOdalEditSubChairProps> = ({ show, infoSubChair, onCancel, onSave }: IMOdalEditSubChairProps) => {
  const { t } = useTranslation()

  const editForm = useForm<IEditSubchairFormData>({
    resolver: zodResolver(editSubchairSchema)
  })

  const {
    register,
    handleSubmit,
    setValue
  } = editForm

  const onsubmit = (data: IEditSubchairFormData) => {
    onSave(data)
  }

  useEffect(() => {
    setValue('name', infoSubChair.name)
  }, [infoSubChair.name])

  return (
    <FormProvider {...editForm}>
      <Form onSubmit={handleSubmit(onsubmit)}>
        <Modal show={show}>
          <Modal.Header closeButton onClick={onCancel}>
            <Modal.Title>
            {t('subchair')} {infoSubChair.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Label htmlFor="name">{t('subchair')}</Form.Label>
            <Form.Control type="text" id="name" {...register('name')} />
            <FormCustom.ErrorMessage field="name" />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" onClick={handleSubmit(onsubmit)}>{t('submit')}</Button>
            <Button onClick={onCancel}>{t('cancel')}</Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </FormProvider>
  )
}