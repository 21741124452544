import './createWeekMeeting.css'
import { Button, Form } from "react-bootstrap"
import useGuard from "../../../contexts/Guard"
import { FormCustom } from "../../../components/Form"

interface ICreateWeekMeetingProps {
    goBack: () => void
}

export const CreateWeekMeeting: React.FC<ICreateWeekMeetingProps> = ({
    goBack
}) => {
    const { infoUser } = useGuard()

    return (
        <div>
            <h3 className='title-group-week'>{infoUser.group_selected.name}</h3>
            <Form.Group className="mb-3 w-25">
                <Form.Label htmlFor="meeting_day">Data</Form.Label>
                <FormCustom.Control 
                    field="meeting_day"
                    id="meeting_day"
                    type="date"
                />
                <FormCustom.ErrorMessage field="meeting_day" />
            </Form.Group>
            <Button 
                type="submit"
                variant="primary"
                className="mb-3 mr-1 m-1 button-week-submit"                
            >Criar</Button>

            <p className='alert-week'><span>Importante:</span> Ao criar a reunião, você poderá acessa-lá em reuniões semanais ou clicando <span onClick={goBack} className='goback'>aqui</span></p>
        </div>
    )
}