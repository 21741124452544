import React, { useEffect } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { maskCpf_Cnpj } from "../../utils/Helper";

interface IFormDocuments extends FormControlProps {
  field: string
}

export const FormDocuments: React.FC<IFormDocuments> = ({
  field,
  ...rest
}) => {

  const {
    register,
    setValue,
    watch
  } = useFormContext()

  const documentsWatch = watch(field)

  useEffect(() => {
    if (documentsWatch) {
      setValue(field, maskCpf_Cnpj(documentsWatch))
    }
  }, [documentsWatch])

  return (
    <Form.Control
    className="rounded-4"
    {...register(field)}
    {...rest}  
    />
  )
}