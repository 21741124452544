import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

interface ITableSearchComponentProps {
  callback: (params: ITableSearchFormData) => void
}

const searchFormSchema = z.object({
  search: z.string()
})

export type ITableSearchFormData = z.infer<typeof searchFormSchema>

export const TableSearchComponent: React.FC<ITableSearchComponentProps> = ({
  callback
}) => {
  const { t } = useTranslation()

  const { register, handleSubmit } = useForm<ITableSearchFormData>({
    resolver: zodResolver(searchFormSchema)
  })
    
  const onSubmit = (data: ITableSearchFormData) => {
    callback({ search: data.search });
    
  };

  return (
    <Container fluid>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-md-end">
          <Col xl="4">
            <Form.Control type="text" id="search" {...register("search")} placeholder={t('search')} />
          </Col>
          <Col xl="1" className="">
            <Button variant="secondary" type="submit" className="float-start">
              {t('search')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}