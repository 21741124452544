import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { FormCustom } from "../../../components/Form"
import { useTranslation } from "react-i18next";
import { ISelectDefault } from "../../../interfaces/form/form.interface";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { phoneMask } from "../../../utils/Helper";

export interface ICreateIndicationProps {
    group: ISelectDefault [],
    users: ISelectDefault[],
    handleGoBack: () => void
}

export const CreateIndication: React.FC<ICreateIndicationProps> = ({
    group,
    users,
    handleGoBack
}) => {
    const { t } = useTranslation();

    const { watch, setValue } = useFormContext()

    const phone = watch('phone')

    useEffect(() => {
        setValue('phone', phoneMask(phone))
    }, [phone])    


    return (
        <Container fluid> 
            <Form.Group>
                <Form.Label>Empresa</Form.Label>
                <FormCustom.Control className="w-25" field="company"/>
                <FormCustom.ErrorMessage field="company"/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Contato</Form.Label>
                <FormCustom.Control className="w-25" field="contact"/>
                <FormCustom.ErrorMessage field="contact"/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Telefone</Form.Label>
                <FormCustom.Control className="w-25" field="phone"/>
                <FormCustom.ErrorMessage field="phone"/>
            </Form.Group>
            <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <FormCustom.Control className="w-25" field="email"/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Data da indicação</Form.Label>
                <FormCustom.Control className="w-25" type="date" field="indications_date"/>
                <FormCustom.ErrorMessage field="indications_date"/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Observação</Form.Label>
                <FormCustom.Control className="w-25" as="textarea" field="obervations" style={{ height: '100px' }} />
                <FormCustom.ErrorMessage field="obervations"/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Indicado para: </Form.Label>
            </Form.Group>
            <Form.Group>                
                <Form.Label>Group </Form.Label>
                <FormCustom.Select className="w-25" field="id_group_indications" options={group}/>
                <FormCustom.ErrorMessage field="id_group_indications"/>
            </Form.Group>  
            <Form.Group>
                <Form.Label>Membro indicado </Form.Label>
                <FormCustom.Select className="w-25" field="id_user_indications" options={users}/>
                <FormCustom.ErrorMessage field="id_user_indications"/>
            </Form.Group>
            <Row className="justify-content-md-end">
                <Col xl={3} sm={3} md={3} className="mt-3">
                    <Button
                        variant="primary"
                        type="submit"
                        className="float-end mb-3 mr-1 m-1"
                    >
                       {t("submit")}
                    </Button>
                    <Button
                        variant="secondary"
                        type="button"
                        className="float-end mb-3 mr-1 m-1"
                        onClick={handleGoBack}
                    >
                        {t("goback")}
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}