import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormCustom } from "../../../components/Form";
import { useTranslation } from "react-i18next";
import { ISelectDefault } from "../../../interfaces/form/form.interface";
import { useFormContext } from "react-hook-form";
import { getSubChairList } from "../../../api/sub-chair/SubChair";
import { useParams } from "react-router-dom";

interface IProfileFormGroupProps {
  selectGroup: ISelectDefault[]
  companySelect: ISelectDefault[]
  chairSelect: ISelectDefault[]  
  roleSelect: ISelectDefault[]
  subChairSelect?: number | null
  index: number
  disabled: boolean
}

export const ProfileFormGroup: React.FC<IProfileFormGroupProps> = ({
  chairSelect,
  companySelect,
  roleSelect,
  selectGroup,  
  index,
  disabled,
  subChairSelect
}) => {
  const { t } = useTranslation()
  const { watch, setValue } = useFormContext()
  const { groupId } = useParams()

  const [selectSubChair, setSelectSubChair ] = useState<ISelectDefault[]>([])

  const watchChair: number = watch(`userGroup.${index}.id_chair`)

  const fetchSubChair = async () => {
    if (!watchChair) return 
    const { data } = await getSubChairList(watchChair)
    const subchar = data.map((item) => {
      return {
        label: item.name,
        value: item.id
      }
    })
    
    setSelectSubChair(subchar)
  }

  useEffect(() => {
    fetchSubChair()
  }, [watchChair])

  useEffect(() => {
    setValue(`userGroup.${index}.id_sub_chair`, subChairSelect)
  }, [selectSubChair])

  return (
    <>
      <Form.Group className='form-group__group'>
        <Form.Label htmlFor='group'>{t('group')}</Form.Label>
        <FormCustom.Select disabled={disabled} options={selectGroup} field={`userGroup.${index}.id_group`} />
      </Form.Group>
      <Form.Group className='form-group__company'>
        <Form.Label htmlFor='company'>{t('company')}</Form.Label>
        <FormCustom.Select disabled={disabled} options={companySelect} field={`userGroup.${index}.id_company`} />
      </Form.Group>
      <Form.Group className='form-group__chair'>
        <Form.Label htmlFor='chair'>{t('chair')}</Form.Label>
        <FormCustom.Select disabled={disabled} options={chairSelect} field={`userGroup.${index}.id_chair`} />
      </Form.Group>
      <Form.Group className='form-group__sub-chair'>
        <Form.Label htmlFor='subchair'>{t('subChairName')}</Form.Label>
        <FormCustom.Select disabled={disabled} options={selectSubChair} field={`userGroup.${index}.id_sub_chair`} />
      </Form.Group>
      <Form.Group className='form-group__sub-chair'>
        <Form.Label htmlFor='subchair'>{t('type')}</Form.Label>
        <FormCustom.Select disabled={disabled} options={roleSelect} field={`userGroup.${index}.id_role`} />
      </Form.Group>
    </>
  )
}