import { Col, Container, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useGuard from "../../contexts/Guard";

export interface ITableComponentProps {
    bordered?: boolean;
    hover?: boolean;
    striped?: boolean;
    cols: ITableComponentCols[]
    data: any[]
}

export interface ITableComponentCols {
    title: string
    permissions?: string[]
    render: (data: any) => JSX.Element
}

export const TableComponent: React.FC<ITableComponentProps> = ({
    bordered = false,
    hover = false,
    striped = false,
    cols,
    data = [],
}: ITableComponentProps) => {
    const { t } = useTranslation()
    const { CheckPermissionWithArray } = useGuard()

    const removeColumn = (colsTable: ITableComponentCols[]): ITableComponentCols[] => {
        const cols: ITableComponentCols[] = []
        for (const col of colsTable) {
            if (!col.permissions?.length) {
                cols.push(col)
                continue
            }

            if (CheckPermissionWithArray(col.permissions)) {
                cols.push(col)
            }
        }
        return cols
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Table
                        bordered={bordered}
                        hover={hover}
                        striped={striped}
                    >
                        <thead>
                            <tr>
                                {removeColumn(cols).map((col, index) => (
                                    <th key={index}>{col.title}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.length === 0 && (<tr><td colSpan={cols.length + 1}><p className="text-center mt-3">{t('emptyDataTable')}</p></td></tr>)}

                            {data.map((item, index) => (
                                <tr key={index}>
                                    {removeColumn(cols).map((col, index) => (
                                        <td key={index}>{col.render(item)}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}