import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Login from "../pages/login/Login"
import { ForgotPassword } from '../pages/forgot/ForgotPassword'
import { ForgotPasswordReset } from '../pages/forgot/ForgotPasswordReset'

const SignRoutes: React.FC = () => {

    return (
        <div>
            <ToastContainer />
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/forgot"  element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ForgotPasswordReset />}/>
            </Routes>
        </div>

    )
}

export default SignRoutes