import {  Container } from "react-bootstrap"
import { ProfileForm } from "./Form/Form"

const Profile: React.FC = () => {
                        
    return (
        <Container>
           <ProfileForm />           
        </Container >
    )
}

export default Profile