
import { IListParamsSelected } from '../../interfaces/list/list.interface';
import { PresenceUser } from './components/presenceUser/PresenceUser';
import './presence.css'

export interface IPresenceProps { 
  data: IListParamsSelected[]
  remove: (index: number) => void;
 }

export const Presence: React.FC<IPresenceProps> = ({ data, remove }: IPresenceProps) => {
    return (
      <div className="presence">
        <div className='container-presence'>
            {data.map((item, index) => (
                <PresenceUser 
                index={index}
                id_group={item.group_id}
                name={item.user_name}
                name_group={item.group_name}
                remove={remove}
                id={item.user_id}
                key={index}               
            />
            ))}                     
        </div>

      </div>
    );
  };