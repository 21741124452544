import { IPunctuationResponseAll, IScoreUpdatePayload } from '../../interfaces/score/score.interface'
import api from '../Api'

export const getScore = async (id_group: number) => {
  return await api.get<IPunctuationResponseAll[]>(`/group/${id_group}/punctuation`)
}

export const updateScore = async ({id_group, payload }: IScoreUpdatePayload) => {
  const { config } = payload
  return await api.put<IPunctuationResponseAll[]>(`/group/${id_group}/punctuation`, {config})
}