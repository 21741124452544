import './dashboard.css'
import React from 'react'
import { useParams } from 'react-router-dom'
import { SidebarDashboard } from './components/sidebar/SidebarDashboard'
import { MainDashboard } from './components/main-dashboard/MainDashboard'
import { SystemInfo } from './components/system-info/SystemInfo'
import { DashboardProvider } from '../../contexts/Dashboard'


const Dashboard: React.FC = () => {    
    return (
        <DashboardProvider>
            <div className='dashboard-container'>
                <div className='sidebar-dashboard'>
                    <SidebarDashboard />
                </div>
                <div className='main-dashboard'>
                    <MainDashboard />
                </div>
                <div className='system-dashboard'>
                    <SystemInfo />
                </div>
            </div>
        </DashboardProvider>
    )
}

export default Dashboard