import {  ILeadershipQuery, IReportExtractPeriodGuestReponse, IReportExtractPeriodResponse, IResponseExtractPeriodBussines, IResponseExtractPeriodIndications, ITrainingMeetingQuery } from "../../../interfaces/extractSpeficPeriod/extractSpeficPeriod.interface"
import { ITableComponentCols } from "../../../components/Table/TableComponent"
import { IResponseRdnShow } from "../../../interfaces/rdn/rdn.interface"
import { ICardExtractProps } from "../components/card-extract/CardExtract"
import { getCurrency, getDateLocale, getDateLocaleUTC } from "../../../utils/Helper"
import { useTranslation } from "react-i18next"

export const useExtracSpeficPeriod = () => {
  const { t } = useTranslation()

  const colsGuest: ITableComponentCols[] = [
    {
      title: t('name'),
      render: (item: IReportExtractPeriodGuestReponse) => (
        <span>{item.name}</span>
      )
    },
    {
      title: 'Data do Convite',
      render: (item: IReportExtractPeriodGuestReponse) => (
        <span>{getDateLocale(item.meeting_day, 'pt-BR')}</span>
      )
    },
    {
      title: 'status',
      render: (item: IReportExtractPeriodGuestReponse) => (
        <span>{t(item.status)}</span>
      )
    }
  ] 

  const colsIndications: ITableComponentCols[] = [
    {
      title: 'Indicado Para',
      render: (item: IResponseExtractPeriodIndications) => (
        <span>{item.user_name}</span>
      )
    },
    {
      title: 'Indicação',
      render: (item: IResponseExtractPeriodIndications) => (
        <span>{item.group_name}</span>
      )
    },
    {
      title: 'Data da Indicação',
      render: (item: IResponseExtractPeriodIndications) => (        
        <span>{getDateLocaleUTC(item.indication_date, 'pt-BR')}</span>        
      )
    },
    {
      title: 'Data Fechamento',
      render: (item: IResponseExtractPeriodIndications) => (
        <span>{ getDateLocale(item.date_closed, 'pt-BR')}</span>
      )
    },
    {
      title: 'Valor do Negócio',
      render: (item: IResponseExtractPeriodIndications) => (
        <span>{getCurrency(+item.close_value)}</span>
      )
    },
  ]

  
  const colsBussines: ITableComponentCols[] = [
    {
      title: 'Indicado Por',
      render: (item: IResponseExtractPeriodBussines) => (
        <span>{item.user_name}</span>
      )
    },
    {
      title: 'Indicação',
      render: (item: IResponseExtractPeriodBussines) => (
        <span>{item.group_name}</span>
      )
    },
    {
      title: 'Data da Indicação',
      render: (item: IResponseExtractPeriodBussines) => (
        <span>{getDateLocale(item.indication_date, 'pt-BR')}</span>
      )
    },
    {
      title: 'Data Fechamento',
      render: (item: IResponseExtractPeriodBussines) => (
        <span>{getDateLocale(item.date_closed, 'pt-BR')}</span>
      )
    },
    {
      title: 'Valor do Negócio',
      render: (item: IResponseExtractPeriodBussines) => (
        <span>{getCurrency(+item.close_value)}</span>
      )
    },
  ]

  const colsRdn: ITableComponentCols[] = [
    {
      title: 'Data',
      render: (item: IResponseRdnShow) => (
        <span>{item.meeting_date}</span>
      )
    },
    {
      title: 'Participantes',
      render: (item: IResponseRdnShow) => (
        <span>{item.guests.map(item => item.user.name).join(', ')}</span>
      )
    },
  ]

  const colsTraining: ITableComponentCols[] = [
    {
      title: 'Data',
      render: (item: ITrainingMeetingQuery) => (
        <span>{getDateLocale(item.meeting_date, 'pt-BR')}</span>
      )
    },
  ]

  const colsLeadership: ITableComponentCols[] = [
    {
      title: 'Data',
      render: (item: ILeadershipQuery) => (
        <span>{getDateLocale(item.meeting_date, 'pt-BR')}</span>
      )
    },
  ]

  const getColsBykey = (key: string): ITableComponentCols[] => {
    switch(key) {
      case 'guest':
        return colsGuest
      case 'indications':
        return colsIndications
      case 'bussines':
        return colsBussines
      case 'rdn':
        return colsRdn
      case 'training':
        return colsTraining
      case 'leadership':
        return colsLeadership
      default:
        return colsGuest
    }
  }

  const getValeuByKey = (key: string, data: IReportExtractPeriodResponse) => {
    switch(key) {
      case 'guest':
        return data[key]
      case 'indications':
        return data[key].value ?? null
      case 'bussines':
        return data[key].value ?? null
      case 'rdn':
        return data[key]
      case 'training':
        return data[key]
      case 'leadership':
        return data[key]
      default:
        return null
    }
  }

  const getTitleBykey = (key: string) => {
    switch(key) {
      case 'guestTitle':
        return t(key)
      case 'indicationsTitle':
        return t(key)
      case 'bussinesTitle':
        return t(key)
      case 'rdnTitle':
        return t(key)
      case 'trainingTitle':
        return t(key)
      case 'leadershipTitle':
        return t(key)
      default:
        return t(key)
    }
  }

  const getSubtitleBykey = (key: string, data: any) => {
    switch(key) {
      case 'guestSubtitle':
        return t(key, { guestValue: data.length})
      case 'indicationsSubtitle':
        return t(key, { indicationValue: data.length})
      case 'bussinesSubtitle':
        return t(key, { value: data.length})
      case 'rdnSubtitle':
        return t(key, { value: data.length})
      case 'trainingSubtitle':
        return t(key, { value: data.length})
      case 'leadershipSubtitle':
        return t(key, { value: data.length})
      default:
        return colsGuest
    }
  }

  const getDescriptionBykey = (key: string, data: any) => {
    switch(key) {
      case 'guestDescription':
        return null
      case 'indicationsDescription':
        return t(key, { value: data})
      case 'bussinesDescription':
        return t(key, { value: data})
      case 'rdnDescription':
        return null
      case 'trainingDescription':
        return null
      case 'leadershipDescription':
        return null
      default:
        return null
    }
  }



  const getDataByResponse = (data: IReportExtractPeriodResponse): ICardExtractProps[] => {
    const response = Object.keys(data).map((key) => {
      const value = getValeuByKey(key, data)

      let descriptionValue = 0
      if (key === 'bussines') {
        descriptionValue = data[key].my_bussines
      }

      if (key === 'indications') {
        descriptionValue = data[key].total_group
      }

      return {
        title: getTitleBykey(`${key}Title`),
        subtitle: getSubtitleBykey(`${key}Subtitle`, value) as string,
        descriptions: getDescriptionBykey(`${key}Description`, descriptionValue),
        cols: getColsBykey(key),
        data: value
      }
    })

    return response
  }

  return {
    getDataByResponse
  }

}