import './showBussines.css'

import { Button, Col, Form, FormGroup, Row } from "react-bootstrap"
import { ISelectDefault } from "../../../interfaces/form/form.interface"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { z } from "zod"
import { FormProvider, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormCustom } from "../../../components/Form"
import { StatusBussines } from "../../../interfaces/enum/status.enum"
import { useTranslation } from "react-i18next"
import { getBussinesByIdIndications, storeBussines } from "../../../api/bussines/bussines"
import { IBussinesShowResponse } from "../../../interfaces/bussines/bussines.interface"
import { getDateLocale, phoneMask, tranformeValideStringDate } from "../../../utils/Helper"
import { toast } from 'react-toastify'

const setBussinesValueSchema = z.object({
    id_group: z.coerce.number({ required_error: 'grupo é obrigatório' }).positive(),
    status: z.string({ required_error: 'status é obrigatório' }),
    close_date: z.string({ required_error: 'data é obrigatório' }),    
    price_value: z.string({ required_error: 'valor é obrigatório' }).transform((value) => {
        return value.replace(/[^\d,]/g, '').replace(',', '.')
    }).optional(),
    obervations: z.string().optional(),
})

export type SetBussinesValueFormData = z.infer<typeof setBussinesValueSchema>

export const ShowBussines: React.FC = () => {
   
    const { t } = useTranslation()
    const { idIndication } = useParams()
    const navigate = useNavigate();
    

    const { groupId } = useParams()
    const [status, setStatus] = useState<ISelectDefault[]>([])
    const [bussines, setBussines] = useState<IBussinesShowResponse>({} as IBussinesShowResponse)

    const getInfoBussines = () => { 
        getBussinesByIdIndications(+idIndication!).then((response) => {            
            setValue('status', response.data.status)                 
            setValue('obervations', response.data.businees_observation ?? '')            
            setBussines(response.data)
        })
    }

    const buildStatus = () => {
        for (const [key, value] of Object.entries(StatusBussines)) {
            setStatus((prev) => [...prev, { value: key, label: t(value) }])
        }
    }

    const isDisabled = () => {
        return bussines.status !== StatusBussines.INDICATION
    }
    
    const setBussinesForm = useForm<SetBussinesValueFormData>({
        resolver: zodResolver(setBussinesValueSchema)
    })

    const {
        setValue,        
        handleSubmit,
        setFocus,
        watch,        
    } = setBussinesForm
              
    const statusWatch = watch('status')

    const onSubmit = (data: SetBussinesValueFormData) => {        
        if (data.status === StatusBussines.INDICATION) {
            toast.error('para continuar salve o status como diferente de indicação')
            return
        }        

        if (data.status === StatusBussines.CLOSED && ( !data.close_date || !Object.hasOwn(data, 'price_value') )) {
            toast.error('para continuar informe a data e o valor')
            return
        }                       
        
        if (data.status !== StatusBussines.CLOSED ) {
            data.price_value = '0'            
        }

        storeBussines({
            close_date: tranformeValideStringDate(new Date(data.close_date)),
            status: data.status,
            value: data.price_value ? parseFloat(data.price_value) : 0,
            obervations: data.obervations,
            id_bussines: bussines.id_bussines,
        }).then((response) => {
            if (response.data) {
                getInfoBussines()
            }
        })        
    }

    const handleGoBack = () => {
        navigate('../')
    }

    useEffect(() => {
        if (statusWatch !== StatusBussines.CLOSED) {
            
        }
    }, [statusWatch])

    useEffect(() => {
        if (!groupId) return
        buildStatus()
        setValue('id_group', +groupId!)
        setFocus('close_date')
        getInfoBussines()   
    }, [groupId])    

    return (
        <div className="m-2 border border-secondary border-2 rounded p-4">
           
           <Form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...setBussinesForm}>
                <div>
                <FormCustom.ErrorMessage field='price_value' />
            </div>
                    <div className='form-group-container'>
                        <Form.Label>Empresa: </Form.Label>
                        <p>{bussines.company}</p>
                    </div>
                    <div className='form-group-container'>
                        <Form.Label>Contato: </Form.Label>
                        <p>{bussines.contact}</p>
                    </div>
                    <div className='form-group-container'>
                        <Form.Label>Telefone: </Form.Label>
                        <p>{phoneMask(bussines.phone)}</p>
                    </div>
                    <div className='form-group-container'>
                        <Form.Label>E-mail: </Form.Label>
                        <p>{bussines.email}</p>
                    </div>
                    <div className='form-group-container'>
                        <Form.Label>Data de indicação: </Form.Label>
                        <p>{getDateLocale(bussines.indication_date, 'pt-BR')}</p>
                    </div>
                    <div className='form-group-container'>
                        <Form.Label>Observação: </Form.Label>
                        <p>{bussines.observations}</p>
                    </div>
                    <div className='form-group-container'>
                        <Form.Label>Indicado por :</Form.Label>
                        <p>{`${bussines.user_indication} (${bussines.group_indication})`}</p>
                    </div>                    
                   <div className='show-form'>
                     <div>
                        <Form.Label>Status</Form.Label>
                        <FormCustom.Select disabled={isDisabled()}  field="status" options={status}/>
                     </div>
                     <div>
                        <Form.Label>Data do Fechamento</Form.Label>
                        <FormCustom.Control  defaultValue={bussines.date_closed} disabled={isDisabled()}  type="date" field="close_date"/>                        
                    </div>                
                     {statusWatch === StatusBussines.CLOSED && (
                       <>                           
                            <div >
                                <Form.Label>Valor fechado</Form.Label>                        
                                <FormCustom.Currency  field='price_value' disabled={isDisabled()} initialValue={bussines.close_value ?? ''}  />
                            </div>   
                       </>
                     )}                  
                   </div>
                    <FormGroup>
                        <Form.Label>Observação</Form.Label>
                        <FormCustom.Control  disabled={isDisabled()} className="w-25" as="textarea" field="obervations" style={{ height: '100px' }} />
                    </FormGroup>                    
                </FormProvider>
                <Row className="justify-content-md-end">
                    <Col xl={3} sm={3} md={3} className="mt-3">
                        <Button
                            variant="primary"
                            type="submit"
                            className="float-end mb-3 mr-1 m-1"
                            disabled={isDisabled()}
                        >
                        {t("submit")}
                        </Button>
                        <Button
                            variant="secondary"
                            type="button"
                            className="float-end mb-3 mr-1 m-1"
                            onClick={handleGoBack}
                        >
                            {t("goback")}
                        </Button>
                    </Col>
            </Row>
           </Form>
       </div>
    )
}