import { useRef, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Row } from "react-bootstrap"
import { ISelectDefault } from "../../../interfaces/form/form.interface";
import { IResponseGeneric } from "../../../interfaces/generic/generic.interface";
import { IBirthdayReport } from "../../../interfaces/report/IBirthday";
import { TableCustomComponent } from "../../../components/Table";
import { ITableComponentCols } from "../../../components/Table/TableComponent";
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent"
import { generateCsvBirthday, getBirthdayReport } from "../../../api/report/report";
import { downloadAndConvertBufferToBlob, getAllMonthsForSelect, getDateLocale } from "../../../utils/Helper";
import useGuard from "../../../contexts/Guard";
import { useTranslation } from "react-i18next";
import './birthday.css'
import { TitlePage } from "../components/TitlePgae";
import { LoadingReport } from "../components/LoadingReport";


export const BirthdayReport = () => {
  const mothSelected = useRef(0);
  const { infoUser, CheckPermissionWithArray } = useGuard()
  const { t } = useTranslation()
  const exportRef = useRef<HTMLDivElement>(null);
  const [listUsers, setListUsers] = useState<IResponseGeneric<IBirthdayReport>>({} as IResponseGeneric<IBirthdayReport>)
  const [isSearched, setIsSearched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const months: ISelectDefault[] = getAllMonthsForSelect()
  const cols: ITableComponentCols[] = [
    {
      title: t('name').toUpperCase(),
      render: (row: IBirthdayReport) => (<span>{row.name}</span>)
    },
    {
      title: t('date').toUpperCase(),
      render: (row: IBirthdayReport) => (
        <span>
          {getDateLocale(row.birthday, 'pt-BR')}
        </span>
      )
    }
  ]

  const getBirthday = async (params?: IPaginationsCallback) => {
    try {
      const { data } = await getBirthdayReport({
        month: mothSelected.current,
        paginationParams: params,
        id_group: infoUser.group_selected.id
      })

      setListUsers({
        data: data.data.data,
        meta: data.data.meta
      })
    } finally {
      setIsSearched(true)
      setIsLoading(false)
    }
  }

  const onSearch = async () => {
    if (mothSelected.current === 0) return

    setIsLoading(true)
    getBirthday()
  }

  const onSearchPagination = (paramsPagination: IPaginationsCallback) => {
    getBirthday(paramsPagination)
  }

  const generateCsv = async () => {
    const {data} = await generateCsvBirthday({
      id_group: infoUser.group_selected.id,
      month: mothSelected.current
    })
    downloadAndConvertBufferToBlob(data, t('birthday'))
  }

  return (
    <Container fluid>
      <Row>
        <TitlePage title="birthday" />
      </Row>

      <Row>
        <Col>
          <FormGroup className="mb-3">
            <Form.Label className="fs-6 fw-bold">{t('month')}</Form.Label>

            <Form.Select
              onChange={(e) => mothSelected.current = +e.target.value}
              className="rounded-4 w-25"
              disabled={isLoading}
            >
              <option value={0}>{t('select')}</option>
              {
                months.map((item, index) => (
                  <option
                    key={item.value}
                    value={item.value}
                  >
                    {t(item.label)}
                  </option>
                ))
              }
            </Form.Select>
          </FormGroup>

          <Button
            type="button"
            className="bg-primary text-uppercase button-search"
            onClick={() => onSearch()}
            disabled={isLoading}
          >
            {isLoading ? t('searchingDots') : t('lookFor')}
          </Button>
        </Col>
      </Row>

      <Row ref={exportRef} className="mt-3">
        <Col>
          {isLoading && <LoadingReport />}

          {!isSearched && !isLoading && (
            <span className="fs-6">{t('selectMonth')}</span>
          )}

          {
            isSearched && !listUsers?.data.length && (
              <p>{t('noDataBirthdayReport')}</p>
            )
          }

          {
            (isSearched && Boolean(listUsers?.data.length)) && (
              <TableCustomComponent.Root className="table-birthday mt-5">
                <Col>
                  <TableCustomComponent.Table
                    hover={true}
                    striped={true}
                    cols={cols}
                    data={listUsers.data}
                  />
                </Col>

                <Col>
                  <TableCustomComponent.Pagination
                    {...listUsers.meta}
                    callback={onSearchPagination}
                  />
                  {CheckPermissionWithArray(['generate_report']) && (
                    <div className="w-10 d-flex justify-content-end me-5">                  
                    <Button
                      variant="primary"
                      type="submit"
                      className="fw-semibold text-uppercase"
                      onClick={generateCsv}
                    >
                      {t('printExport')}
                    </Button>
                  </div>
                  )}
                </Col>
              </TableCustomComponent.Root>
            )
          }
        </Col>
      </Row>
    </Container>
  );
};
