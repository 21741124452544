
import { Col, Form, FormGroup, Row } from "react-bootstrap"
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next"
import { ISelectDefault } from "../../interfaces/form/form.interface";
import { getSubChairList } from "../../api/sub-chair/SubChair";
import { useEffect, useState } from "react";
import * as Icon from 'react-bootstrap-icons';
import { FormCustom } from "../Form";
import { useParams } from "react-router-dom";
import { listUserByGroup } from "../../api/list/list";
import { IListUserByGroupResponse } from "../../interfaces/list/list.interface";
import { IGetUserInfo } from "../../interfaces/user/IUser";

interface IFieldInputUserProps {
  groups: ISelectDefault[],
  companys: ISelectDefault[],
  chairs: ISelectDefault[],
  roles: ISelectDefault[],
  subChairSelected?: number,
  indexForm: number
  callbackAdd: (e: object) => void
  callbackRemove: (e: number) => void
  indexsDisabled: number[]
  isEdit?: boolean
  userFetch?: IGetUserInfo
}

export const FieldInputUser: React.FC<IFieldInputUserProps> = (props: IFieldInputUserProps) => {
  const { t } = useTranslation()
  const { groupId } = useParams();
  const { setValue, watch } = useFormContext();
  const [subchairs, setsubChairs] = useState<ISelectDefault[]>([])
  const [selectUser, setSelectUser] = useState<ISelectDefault[]>([])

  const chair = watch(`userGroup.${props.indexForm}.id_chair`)
  const groupFather = watch(`userGroup.${props.indexForm}.id_group_guest`)
  const fetchUsers = async (id_group: number) => {
    const users = await listUserByGroup(id_group)

    if (users) {

      const selectUser = users.data.map((user: IListUserByGroupResponse) => {
        return {
          label: user.user_name,
          value: user.user_id
        }
      })

      setSelectUser(selectUser)
    }
  }

  const getSubChair = (groupId: number, idChair: number) => {
    getSubChairList(idChair).then(async (response) => {

      const selectSubChair = response.data.map((item: any) => {
        return {
          label: item.name,
          value: item.id
        }
      })

      setsubChairs(selectSubChair)
    }).catch(error => console.log(error))
  }

  const handleAddGroup = () => {
    props.callbackAdd({
      id_group: undefined,
      id_company: undefined,
      id_chair: undefined,
      id_sub_chair: undefined,
      type: undefined
    })
  }

  const removeGroup = (id: number) => {
    props.callbackRemove(id)
  }

  const isDisabled = (): boolean => {
    return props.indexsDisabled[props.indexForm] ? true : false
  }

  useEffect(() => {
    setValue(`userGroup.${props.indexForm}.id_sub_chair`, props.subChairSelected)
  }, [subchairs])

  useEffect(() => {
    if (!chair) return
    getSubChair(+groupId!, +chair)
  }, [chair])

  useEffect(() => {
    if (!groupFather) return
    fetchUsers(groupFather)
  }, [groupFather])

  return (
    <>
      <Row className='align-items-end mb-5'>
        <Col>
          <Form.Label htmlFor="group">{t('group')}</Form.Label>
          <FormCustom.Select
            id="group"
            options={props.groups}
            field={`userGroup.${props.indexForm}.id_group`}
            showError={true}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="company">{t('company')}</Form.Label>
          <FormCustom.Select
            id="company"
            field={`userGroup.${props.indexForm}.id_company`}
            options={props.companys}
            showError={true}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="chair">{t('chair')}</Form.Label>
          <FormCustom.Select
            id="chair"
            field={`userGroup.${props.indexForm}.id_chair`}
            options={props.chairs}
            showError={true}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="underChair">{t('underchair')}</Form.Label>
          <FormCustom.Select
            id="underChair"
            field={`userGroup.${props.indexForm}.id_sub_chair`}
            options={subchairs}
            showError={true}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="type">{t('type')}</Form.Label>
          <FormCustom.Select
            id="type"
            field={`userGroup.${props.indexForm}.type`}
            options={props.roles}
            showError={true}
          />
        </Col>
       {
        props.indexForm === 0 && (
          <Col>
          <Form.Label htmlFor="godFatherUser">{t('godfather')}</Form.Label>
          <Form.Control
            className="rounded-4"
            id="godFatherUser"
            value={props.userFetch?.godparents?.name ?? ''}
            disabled
          />
        </Col>
        )
       }
        {
          props.isEdit && props.indexForm != 0 && (
            <>
              <Col>
                <FormGroup>
                  <Form.Label>{t('godfather_group')}</Form.Label>
                  <FormCustom.Select
                    field={`userGroup.${props.indexForm}.id_group_guest`}
                    options={props.groups}
                    disabled={isDisabled()}
                    className='input-padrinho-group'
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Form.Label>{t('godfather')}</Form.Label>
                  <FormCustom.Select
                    options={selectUser}
                    field={`userGroup.${props.indexForm}.id_user_guest`}
                    disabled={isDisabled()}
                  />
                </FormGroup>
              </Col>
            </>
          )
        }
        {props.indexForm === 0 ?
          <div className='col'>
            <button type="button" onClick={handleAddGroup} className="btn btn-success">+</button>
          </div>
          :
          ''
        }

        {
          props.indexForm > 0 ?
            <div className='col'>
              <button onClick={() => removeGroup(props.indexForm)} type="button" className="btn btn-danger">
                <Icon.Trash3 color='white' />
              </button>
            </div>
            :
            ''
        }
      </Row>
    </>
  )
}