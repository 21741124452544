import { Button, Col } from "react-bootstrap"
import { ITableComponentCols, TableComponent } from "../../../components/Table/TableComponent"
import { getDateLocale, phoneMask } from "../../../utils/Helper"
import { Link } from "react-router-dom"
import useGuard from "../../../contexts/Guard"
import { IBussinesResponse } from "../../../interfaces/bussines/bussines.interface"
import { useTranslation } from "react-i18next"
import { TableCustomComponent } from "../../../components/Table"
import { IMeta } from "../../../interfaces/generic/generic.interface"
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent"
import { ITableSearchFormData } from "../../../components/Table/TableSearchComponent"

interface ListBussinesProps {
    data: IBussinesResponse[]
    meta: IMeta
    callbackPaginator: (params: IPaginationsCallback) => void
    callbackSearch: (params: ITableSearchFormData) => void
}

export const ListBussines: React.FC<ListBussinesProps> = ({
    data,
    meta,
    callbackPaginator,
    callbackSearch
}) => {
    const { infoUser } = useGuard()
    const { t } = useTranslation()

    const columns: ITableComponentCols[] = [
        {
            title: 'Contato',
            render: (row: IBussinesResponse) => (
                <span>{row.contact}</span>            
            )
        },
        {
            title: 'Telefone',
            render: (row: IBussinesResponse) => (
                <span>{ phoneMask(row.phone)}</span>            
            )
        },
        {
            title: 'Indicado por',
            render: (row: IBussinesResponse) => (
                <span>{row.name}</span>            
            )
        },
        {
            title: 'Data da indicação',
            render: (row: IBussinesResponse) => (
                <span>{ getDateLocale(row.indication_date, 'pt-BR')}</span>            
            )
        },
        {
            title: 'Data do Fechamento',
            render: (row: IBussinesResponse) => (
                <span>{ row.date_closed ? getDateLocale(row.date_closed, 'pt-BR') : '__/__/____'}</span>            
            )
        },
        {
            title: 'Status',
            render: (row: IBussinesResponse) => (
                <span>{t(row.status)}</span>            
            )
        },
        {
            title: 'Ações',
            render: (row: IBussinesResponse) => (
                <Link to={`/grupo/${infoUser.group_selected.id}/bussines/${row.id}`}>
                    <Button                    
                        type="button"
                        className="w-50"                               
                    >
                        ver
                    </Button>
                </Link>
            )
        },
    ]

    return (
         <>

            <TableCustomComponent.Root>
                <Col>
                    <TableCustomComponent.Search callback={callbackSearch} />
                </Col>
                <Col>
                    <TableCustomComponent.Table 
                        cols={columns}
                        data={data}
                        striped
                    />
                </Col>
                <Col>
                    <TableCustomComponent.Pagination callback={callbackPaginator} {...meta} />
                </Col>
            </TableCustomComponent.Root>           
        </>
    )
}