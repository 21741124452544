import './formCurrency.css'
import React from "react"
import { useFormContext } from "react-hook-form"
import MaskedInput from "react-text-mask"
import { createNumberMask } from "text-mask-addons"

interface IFormCurrency {
    options?: IDefaultValue 
    field: string,
    defaultValue: string
    disabled?: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}


interface IDefaultValue {    
    prefix: string
    suffix: string
    includeThousandsSeparator: boolean
    thousandsSeparatorSymbol: string
    allowDecimal: boolean
    decimalSymbol: string
    decimalLimit: number
    integerLimit: number | null
    allowNegative: boolean
    allowLeadingZeroes: boolean    
}

const defaultMaskOptions: IDefaultValue = {   
    prefix: 'R$ ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 14, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,      
}

export const FormCurrencyDefault: React.FC<IFormCurrency> = ({
    options, field, defaultValue, disabled = false, onChange
}) => {
    const value = defaultValue?.replace('.', ',')
    
    const setOptions = options || defaultMaskOptions
    const mask = createNumberMask(setOptions)

    return (
        <div>                
            <MaskedInput onChange={(e) => onChange(e)} disabled={disabled}  defaultValue={value} className="input-currency" mask={mask}  />
        </div>
    )
}