import React from 'react';
import { Navigate } from 'react-router-dom';
import useGuard from '../contexts/Guard';

interface PrivateRouteProps {
  requiredPermissions: string[];
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ requiredPermissions, children }) => {
  const { CheckPermissionWithArray } = useGuard();

  const hasRequiredPermissions = CheckPermissionWithArray(requiredPermissions);

  return hasRequiredPermissions ? <>{children}</> : <Navigate to="/forbidden" />;
};

export default PrivateRoute;