import { IGetBirthdayReport } from './../../interfaces/report/IBirthday';
import { IResponseGeneric, IResponsePaginator } from "../../interfaces/generic/generic.interface"
import { IBirthdayReport } from "../../interfaces/report/IBirthday"
import { IGetMembersReport, IMembersReport } from '../../interfaces/report/IMembers';
import { IGetMonthlyPunctuationReport, MonthlyPunctuation } from '../../interfaces/report/IMonthlyPunctuation';
import { IGetRankMonthlyReport, IRankingReportResponse } from '../../interfaces/report/IRanking';
import api from "../Api"
import { IApresentationReportPramsApi, IReportApresentationResponseAll } from '../../interfaces/report/IApresentation';

export const getBirthdayReport = async ({ id_group, month, paginationParams }: IGetBirthdayReport) => {
  return await api.get<IResponsePaginator<IBirthdayReport>>(`report/${id_group}/birthday/${month}`, {
    params: paginationParams
  })
}

export const getMembersReport = async ({ name, paginationParams, type, groupId }: IGetMembersReport) => {
  return await api.get<IResponsePaginator<IMembersReport>>(`report/members/${groupId}/${type}`, {
    params: { 
      ...paginationParams, 
      name
    }
  })
}

export const getMonthlyPunctuationReport = async ({ year, groupId }: IGetMonthlyPunctuationReport) => {
  return await api.get<IResponseGeneric<MonthlyPunctuation>>(`report/monthly-punctuation/${groupId}/${year}`)
}

export const getRankMonthlyReport = async ({ groupId, type }: IGetRankMonthlyReport) => {
  return await api.get<IRankingReportResponse>(`report/monthly-rank/${groupId}/${type}`)
}

export const getApresentationReport = async(params: IApresentationReportPramsApi) => {
  const { id_group, paramsQuery } = params
  return await api.get<IReportApresentationResponseAll>(`report/apresentation/group/${id_group}${paramsQuery}`)
}

export const generateCsvBirthday = async({ id_group, month }: IGetBirthdayReport) => {
  return await api.get(`report/${id_group}/birthday/${month}/csv`)
}

export const getMembersReportCsv = async ({ name, type, groupId }: IGetMembersReport) => {
  return await api.get(`report/members/${groupId}/${type}/csv`, {
    params: {       
      name
    }
  })
}

export const getMonthlyPunctuationReportCsv = async ({ year, groupId }: IGetMonthlyPunctuationReport) => {
  return await api.get(`report/monthly-punctuation/${groupId}/${year}/csv`)
}