import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ITableComponentCols } from "../../../components/Table/TableComponent";
import { IMembersReport } from "../../../interfaces/report/IMembers";
import { downloadAndConvertBufferToBlob, getDateLocale, userAdminOrDirector } from "../../../utils/Helper";
import { IResponseGeneric } from "../../../interfaces/generic/generic.interface";
import { TableCustomComponent } from "../../../components/Table";
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent";
import { getMembersReport, getMembersReportCsv } from "../../../api/report/report";
import { TitlePage } from "../components/TitlePgae";
import { FilterMember } from "./Filter/FilterMember";
import useAuth from "../../../contexts/Auth";
import { StatusUserWithGuest } from "../../../interfaces/enum/status.enum";
import { LoadingReport } from "../components/LoadingReport";
import './member.css';

export const MemberReport = () => {
  const limitSelected = useRef(10)
  const exportRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation()
  const { infoUser } = useAuth()

  const [filterGroup, setFilterGroup] = useState<number>(infoUser.group_selected.id)
  const [selectType, setSelectType] = useState<string>(userAdminOrDirector() ? 'all' : StatusUserWithGuest.MEMBER)
  const [inputSearch, setInputSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [listMembers, setListMembers] = useState<IResponseGeneric<IMembersReport>>({
    data: [] as IMembersReport[],
  } as IResponseGeneric<IMembersReport>)

  const cols: ITableComponentCols[] = [
    {
      title: t('name').toUpperCase(),
      render: (row: IMembersReport) => (<span>{row.name}</span>)
    },
    {
      title: t('company').toUpperCase(),
      render: (row: IMembersReport) => (<span>{row.companyName}</span>)
    },
    {
      title: t('phone').toUpperCase(),
      render: (row: IMembersReport) => (<span>{row.phone}</span>)
    },
    {
      title: t('email').toUpperCase(),
      render: (row: IMembersReport) => (<span>{row.email}</span>)
    },
    {
      title: t('chair').toUpperCase(),
      render: (row: IMembersReport) => (<span>{row.chairName}</span>)
    },
    {
      title: t('deliveryAddress').toUpperCase(),
      render: (row: IMembersReport) => (<span>{row.deliveryAddress}</span>)
    },
    {
      title: t('accessionDate').toUpperCase(),
      render: (row: IMembersReport) => (
        <span>{getDateLocale(row.accessionDate, 'pt-BR')}</span>
      )
    },
    {
      title: t('type').toUpperCase(),
      render: (row: IMembersReport) => (
        <span className="text-capitalize">
          { t(row.status.toLowerCase()) }
        </span>
      )
    },
    {
      title: t('group').toUpperCase(),
      render: (row: IMembersReport) => (<span>{row.groupName}</span>)
    }
  ]

  const onChangePerPage = (e: ChangeEvent<HTMLSelectElement>) => {
    limitSelected.current = +e.target.value
    getMembers()
  }

  const onChangeType = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectType(e.target.value)
  }

  const onChangeGroup = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilterGroup(+e.target.value)
  }

  const getMembers = async (params?: IPaginationsCallback) => {
    try {
      const searchParams = params ? { ...params, perPage: limitSelected.current } : { page: 1, perPage: limitSelected.current }
      setIsLoading(true)

      const { data } = await getMembersReport({ 
        name: inputSearch, 
        type: selectType.toLowerCase(),
        groupId: filterGroup,
        paginationParams: searchParams,
      })
      
      setListMembers({
        data: data.data.data,
        meta: data.data.meta
      })
    } finally {
      setIsLoading(false)
    }
  }

  const generateCsv = async () => {
    const {data} = await getMembersReportCsv({
      name: inputSearch,
      type: selectType.toLowerCase(),
      groupId: filterGroup,      
    })

    downloadAndConvertBufferToBlob(data, t('members'))
  }

  useEffect(() => {
    getMembers()
  }, [])

  return (
    <Container fluid>
      <Row>
        <TitlePage title="members" />
      </Row>

      <Row>
        <FilterMember 
          exportRef={exportRef}
          isLoading={isLoading}
          onChangePerPage={onChangePerPage}
          onChangeGroup={onChangeGroup}
          onChangeType={onChangeType}
          onChangeInputSearch={(e) => setInputSearch(e.target.value)}
          executeSearch={getMembers}
          generateCsv={generateCsv}
        />
      </Row>

      <Row ref={exportRef}>
        <Col>
          {
            isLoading && <LoadingReport />
          }

          {
            !isLoading && !listMembers?.data.length && (
              <p className="mt-5">{t('noDataMembersReport')}</p>
            )
          }

          {
            !isLoading && Boolean(listMembers?.data.length) && (
              <TableCustomComponent.Root className="table-members mt-5">
                <Col>
                  <TableCustomComponent.Table
                    hover={true}
                    striped={true}
                    cols={cols}
                    data={listMembers.data}
                  />
                </Col>
                
                <Col>
                  <TableCustomComponent.Pagination
                    {...listMembers.meta}
                    callback={getMembers} 
                  />
                </Col>
              </TableCustomComponent.Root>    
            )
          }
        </Col>
      </Row>
    </Container>
  );
};
