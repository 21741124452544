import { PaginationComponent } from "./PaginationComponent";
import { TableComponent } from "./TableComponent";
import { TableRootComponent } from "./TableRootComponent";
import { TableSearchComponent } from "./TableSearchComponent";

export const TableCustomComponent = {
  Root: TableRootComponent,
  Table: TableComponent,
  Pagination: PaginationComponent,
  Search: TableSearchComponent
}