import { ICreateUserParams, IGetUserInfo, IUpdateUserApiParams } from '../../interfaces/user/IUser'
import { IDeleteUserParams, IGetUsers, IResponseUser } from '../../interfaces/user/IGetUsers'

import api from '../Api'
import { IResponseGeneric } from '../../interfaces/generic/generic.interface'


export const getUser = async (groupId: number, userId: number) => {
    return await api.get<IGetUserInfo>(`group/${groupId}/user/${userId}`)
}

export const getUsers = async ({ groupId, meta }: IGetUsers) => {
    let url = `group/${groupId}/user`
    if (meta) {
        url += meta
    }
    return await api.get<IResponseGeneric<IResponseUser>>(url)
}

export const updateUser = async ({ groupId, data, userId}: IUpdateUserApiParams) => {
    return await api.put(`group/${groupId}/user/${userId}`, data)
}

export const createUser = async (data: ICreateUserParams, groupId: number) => {
    return await api.post(`group/${groupId}/user`, data)
}

export const deleteUser = async ({ groupId, userId }: IDeleteUserParams) => {
    return await api.delete(`group/${groupId}/user/${userId}`)
}

