import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form";
import { z } from 'zod'
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateTrainingMeeting } from "./components/CreateTrainingMeeting";
import { useParams } from "react-router-dom";
import { getTrainingMeetingList, storeTrainingMeeting } from "../../api/trainingMeeting/training-meeting";
import { ListTrainingMeeting } from "./components/LitsTrainingMeeting";
import { ITrainingMeetingListResponse } from "../../interfaces/trainingMeeting/trainingMeeting.interface";
import { blockContributionDate } from "../../utils/Helper";
import { searchFactory } from "../../factory/searchFactory";
import { IResponseGeneric } from "../../interfaces/generic/generic.interface";
import { IPaginationsCallback } from "../../components/Table/PaginationComponent";

const createTrainingMeetingSchema = z.object({
    meeting_date: z.coerce.date().refine((data) => blockContributionDate(data), {message: 'Não é possível criar reuniões com mais de 1 mês de atraso' }),
    id_group: z.coerce.number({ required_error: 'grupo é obrigatório' }).positive(),    
})

export type CreateTrainingMeetingFormData = z.infer<typeof createTrainingMeetingSchema>

export const TrainingMeeting: React.FC = () => {
    const { groupId } = useParams()

    const [activeTab, setActiveTab] = useState<number>(0);
    const [data, setData] = useState<IResponseGeneric<ITrainingMeetingListResponse>>(
        {} as IResponseGeneric<ITrainingMeetingListResponse>
    )
    const handleGoBack = () => {
        setActiveTab(0)
    }

    const createTrainingMeetingForm = useForm<CreateTrainingMeetingFormData>({
        resolver: zodResolver(createTrainingMeetingSchema),
        defaultValues: {
            id_group: +groupId!
        }
    })

    const {
        handleSubmit,
        reset
    } = createTrainingMeetingForm

    const getTrainingMeeting = () => {
        getTrainingMeetingList({
            id_group: +groupId!,
            params: searchFactory().createUrlParams({
                page: 1,
                perPage: 10
            })
        }).then((response) => {
            const { data } = response
            setData(data)
        })
    }

    const getTrainingMeetingPaginations = (params: IPaginationsCallback) => {
        const paramsUrl = searchFactory().createUrlParams({
            page: params.page,
            perPage: params.perPage        
        })
        
        getTrainingMeetingList({
            id_group: +groupId!,
            params: paramsUrl
        }).then((response) => {
            const { data } = response
            setData(data)
        })
    }

    const onSubmit = (data: CreateTrainingMeetingFormData) => {
        storeTrainingMeeting({
            id_group: data.id_group,
            meeting_date: data.meeting_date
        }).then((response) => {
            if (response) {
                reset()
                getTrainingMeeting()                
            }
        })
    }

    useEffect(() => {
        getTrainingMeeting()
    }, [groupId])

    return (
        <div className="m-2">
            <Container fluid>
                <Row className="border border-secondary border-2 rounded pt-4">
                    <Col>
                        <Tabs
                            activeKey={activeTab}
                            defaultActiveKey={0}
                            onSelect={(index) => setActiveTab(+index!)}
                            className="mb-3"
                        >
                            <Tab eventKey={0} title="Listagem de Reuniões Treinamento">
                                <ListTrainingMeeting 
                                    data={data.data}
                                    meta={data.meta}
                                    callbackPagination={getTrainingMeetingPaginations}
                                />
                            </Tab>
                            <Tab eventKey={1} title="Criar Reunião Treinamento">
                             <FormProvider {...createTrainingMeetingForm}>
                               <Form onSubmit={handleSubmit(onSubmit)}>
                                <CreateTrainingMeeting goBack={handleGoBack}/>
                               </Form>
                             </FormProvider>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}