import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { FormCustom } from "../../../components/Form";
import { WeekResponseGet } from "../../../interfaces/week/Week.interface";
import { getWeeks } from "../../../api/week/Week";
import { showGrup, updateGroup } from "../../../api/group/Group";
import { IGroupResponse } from "../../../interfaces/group/IGetGroups";

const editGroupFormSchema = z.object({
  name: z
    .string({ required_error: "form_name_required" })
    .nonempty("form_name_required"),
  local: z
    .string({ required_error: "form_locations_required" })
    .nonempty("form_locations_required"),
  day_week: z.coerce
    .number({ required_error: "form_day_week_required" })
    .refine((day) => day > 0, "form_choose_day_required"),
});

type IEditGroupFormData = z.infer<typeof editGroupFormSchema>;

const EditGroup: React.FC = () => {
  const { t } = useTranslation();
  const { groupId, id } = useParams();
  const navigate = useNavigate();

  const [weeks, setWeeks] = useState<WeekResponseGet[]>([]);
  const [group, setGroup] = useState<IGroupResponse>({} as IGroupResponse);

  const editGroupForm = useForm<IEditGroupFormData>({
    resolver: zodResolver(editGroupFormSchema),
  });

  const { register, handleSubmit, setValue } = editGroupForm;

  const onSubmit = async (data: IEditGroupFormData) => {
    await updateGroup({
      groupId: +groupId!,
      id: +id!,
      data: {
        id_week: data.day_week,
        local: data.local,
        name: data.name,
      },
    });
  };

  const getGroupById = () => {
    showGrup({
      groupId: +groupId!,
      id: +id!,
    }).then((response) => {
      setValue("day_week", response.data.day_week_id);
      setValue("local", response.data.address);
      setValue("name", response.data.name);
      setGroup(response.data);
    });
  };

  const getWeeksApi = useCallback(async (groupId: number) => {
    getWeeks({ groupId: groupId })
      .then((response) => {
        const weekMap = response.data.map((item) => {
          return {
            id: item.id,
            name: t(item.name)
          }
        })
        setWeeks(weekMap);
        setValue("day_week", 1);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleGoBack = () => {
    navigate(`../`);
  };

  useEffect(() => {
    getWeeksApi(+groupId!);
  }, [groupId]);

  useEffect(() => {
    getGroupById();
  }, [groupId]);

  return (
    <Container fluid className="p-4">
      <FormProvider {...editGroupForm}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Container fluid>
            <Row style={{ height: "70vh" }}>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor="name">{t('name')}</Form.Label>
                  <Form.Control type="text" id="name" {...register("name")} />
                </Form.Group>
                <FormCustom.ErrorMessage field="name" />
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label htmlFor="local">{t('location')}</Form.Label>
                  <Form.Control type="text" id="local" {...register("local")} />
                </Form.Group>
                <FormCustom.ErrorMessage field="local" />
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label htmlFor="dayWeek">{t('dayOfWeek')}</Form.Label>
                  <Form.Select {...register("day_week")} id="dayWeek">
                    {weeks.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <FormCustom.ErrorMessage field="day_week" />
              </Col>
            </Row>       
            <Row className="justify-content-md-end">
              <Col xl={3} sm={3} md={3} className="mt-3">               
                <Button
                  variant="primary"
                  type="submit"
                  className="float-end mb-3 mr-1 m-1"
                >
                  {t('actionEdit')}
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  className="float-end mb-3 mr-1 m-1"
                  onClick={handleGoBack}
                >
                  {t("goback")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </FormProvider>
    </Container>
  );
};

export default EditGroup;
