import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import PTBR from "./locales/pt-br.json"
import ENUS from "./locales/en-us.json"
import PTPT from "./locales/pt-pt.json"
import ESES from "./locales/es-es.json"

const resources = {
    "pt-BR": PTBR,
    "en-US": ENUS,
    "pt-PT": PTPT,
    "es-ES": ESES
}
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: navigator.language,
        interpolation: {
            escapeValue: false
        }
    })

export default i18n