import { useEffect, useState } from "react"
import { Pagination } from "react-bootstrap"
import { IMeta } from "../../interfaces/generic/generic.interface"

interface IPaginationsProps extends IMeta {
  callback: (params: IPaginationsCallback) => void
}

interface IPaginationsItem {
  page: number
}

export interface IPaginationsCallback {
  page: number
  perPage: number
}

export const PaginationComponent: React.FC<IPaginationsProps> = (params : IPaginationsProps) => {
  const [pagination, setPagination] = useState<IPaginationsItem[]>([])

  const buildPaginator = () => {
    let element: IPaginationsItem[] = [];
    const totalPages = params.last_page;
    const currentPage = params.current_page;

    // Defina o número máximo de páginas visíveis à frente e atrás
    const pagesToShow = 5;
    const pagesBeforeCurrent = Math.min(currentPage - 1, pagesToShow);
    const pagesAfterCurrent = Math.min(totalPages - currentPage, pagesToShow);

    // Calcule o primeiro e último número da página visível
    const firstVisiblePage = Math.max(1, currentPage - pagesBeforeCurrent);
    const lastVisiblePage = Math.min(totalPages, currentPage + pagesAfterCurrent);

    for (let index = firstVisiblePage; index <= lastVisiblePage; index++) {
      element.push({
        page: index,
      });
    }

    setPagination(element);
  };


  useEffect(() => {
    buildPaginator()
  }, [params])

  return (
    <>     
      <Pagination className="table-paginator-custom">
      <Pagination.First onClick={() => params.callback({ page: 1, perPage: 10 })}/>
        {pagination.map((item) => (
          <Pagination.Item 
            key={item.page} 
            active={item.page === params.current_page}
            onClick={() => params.callback({page: item.page, perPage: params.per_page})}
          >
            {item.page}
          </Pagination.Item>
        ))}
        <Pagination.Last onClick={() => params.callback({ page: params.last_page, perPage: 10 })} />
      </Pagination>       
    </>
  )
}