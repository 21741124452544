import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { FormCustom } from "../../../components/Form";
import { z } from "zod";
import {
  blobToBase64,
  clearBase64,
  getOnlyNumberCep,
  getOnlyNumberOfCnpj,
  maskCnpj,
  resolveOptionsSelect,
  zipCodeMask,
} from "../../../utils/Helper";
import { zodResolver } from "@hookform/resolvers/zod";
import { ISelectDefault } from "../../../interfaces/form/form.interface";
import { getAddresByCep, getUfs } from "../../../api/cep/Cep";
import { getAllChairs } from "../../../api/chair/Chair";
import { useNavigate, useParams } from "react-router-dom";
import { editCompany, showCompany } from "../../../api/company/Company";
import { IGetCompanyByIdResponse } from "../../../interfaces/company/IGetCompanys";
import { IPhotoSend } from "../../../interfaces/generic/generic.interface";

const editCompanyFormSchema = z.object({
  name: z.string().nonempty("form_name_required"),
  cnpj: z
    .string()
    .nonempty("form_cnpj_required")
    .transform((value) => getOnlyNumberOfCnpj(value)),
  corporateName: z.string().nonempty("form_company_name_social_required"),
  cep: z
    .string()
    .nonempty("form_zip_code_required")
    .transform((value) => getOnlyNumberCep(value)),
  address: z.string().nonempty("form_address_required"),
  complement: z.string().optional(),
  neighborhood: z.string().nonempty("form_neighborhood_required"),
  uf: z.string().nonempty("form_uf_required"),
  city: z.string().nonempty("form_city_required"),
  chairId: z.coerce.number({ required_error: "form_chair_required" }),
  site: z.string().nullable(),
  instagram: z.string().nullable(),
  youtube: z.string().nullable(),
  facebook: z.string().nullable(),
  linkedin: z.string().nullable(),
});

export type IEditCompanyFormData = z.infer<typeof editCompanyFormSchema>;

const EditCompany: React.FC = () => {
  const { t } = useTranslation();
  const { groupId, companyId } = useParams();
  const navigate = useNavigate();

  const [company, setCompany] = useState<IGetCompanyByIdResponse>(
    {} as IGetCompanyByIdResponse
  );
  const [ufs, setUfs] = useState<string[]>([]);
  const [chair, setChair] = useState<ISelectDefault[]>([]);
  const [photo, setPhoto] = useState<File | null>(null);
  const [count, setCount] = useState(0);

  const handleGetCompany = () => {
    showCompany({ groupId: +groupId!, idCompany: +companyId! }).then(
      (response) => {
        setCompany(response.data);
        handleSetValue(response.data);
      }
    );
  };

  const handleGetUfs = () => {
    getUfs().then((response) => {
      setUfs(response.data);
    });
  };

  const handleGetChair = () => {
    getAllChairs({ groupId: +groupId! }).then((response) => {
      setChair(resolveOptionsSelect(response.data));
    });
  };

  const editCompanyForm = useForm<IEditCompanyFormData>({
    resolver: zodResolver(editCompanyFormSchema),
    defaultValues: {
      site: null,
      instagram: null,
      youtube: null,
      facebook: null,
      linkedin: null,
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = editCompanyForm;

  const onSubmit = async (data: IEditCompanyFormData) => {
    const base64photo: IPhotoSend = {} as IPhotoSend;
    if (photo) {
      const base64 = await blobToBase64(photo);
      base64photo.file = clearBase64(base64 as string);
      base64photo.name = photo.name;
      base64photo.size = photo.size;
    }
    editCompany({
      groupId: +groupId!,
      companyId: +companyId!,
      photo: { ...base64photo },
      ...data,
    }).then((response) => {
      console.log(response.data);
    });
  };

  const handleSetValue = (data: IGetCompanyByIdResponse) => {
    setValue("name", data.name);
    setValue("cnpj", maskCnpj(data.cnpj));
    setValue("corporateName", data.corporate_name);
    setValue("cep", data.cep);
    setValue("address", data.address);
    setValue("complement", data.complement);
    setValue("neighborhood", data.neighborhood);
    setValue("uf", data.uf);
    setValue("city", data.city);
    setValue("chairId", data.chair_id);
    setValue("site", data.site);
    setValue("instagram", data.instagram);
    setValue("youtube", data.youtube);
    setValue("facebook", data.facebook);
    setValue("linkedin", data.linkedin);
    setPhotoUrl(data.urlPhoto);
  };

  const setPhotoUrl = (url: string | null) => {
    if (url) {
      const preview = document.getElementById(
        "previewImage"
      ) as HTMLImageElement;
      preview.src = url;
      preview.style.display = "block";
    }
  };

  const handleGoBack = () => {
    navigate(`../`)
  }


  const handleChangePhoto = async (files: FileList) => {
    if (!files) return;
    if (files[0]) {
      const src = URL.createObjectURL(files[0]);
      const preview = document.getElementById(
        "previewImage"
      ) as HTMLImageElement;
      preview.src = src;
      preview.style.display = "block";
      setPhoto(files[0]);
    }
  };

  const cnpj = watch("cnpj");
  const watchCep = watch("cep");

  useEffect(() => {
    handleGetChair();
    handleGetUfs();
  }, [groupId]);

  useEffect(() => {
    handleGetCompany();
  }, [groupId, companyId]);

  useEffect(() => {
    setValue("cep", zipCodeMask(watchCep));
    setCount(count + 1);
    if (watchCep?.length > 8 && count > 3) {
      const cep = getOnlyNumberCep(watchCep);
      getAddresByCep(cep).then((response) => {
        const { data } = response;
        if (data.uf) {
          setValue("address", data.logradouro);
          setValue("neighborhood", data.bairro);
          setValue("city", data.localidade);
          setValue("uf", data.uf);
        }
      });
    }
  }, [watchCep]);

  return (
    <div className=" border border-secondary border-2 rounded pt-4 m-2">
      <Container fluid>
        <FormProvider {...editCompanyForm}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Container fluid>
              <Row>
                <Col xl={2}>
                  <img className="imagePreview" id="previewImage"></img>
                  <label
                    className="form-label btn btn-primary w-100"
                    htmlFor="1"
                  >
                    {t("changePhoto")}
                  </label>
                  <Form.Control
                    style={{ display: "none" }}
                    type="file"
                    id="1"
                    onChange={(e) => {
                      const files = (e.target as HTMLInputElement).files;
                      if (files) handleChangePhoto(files);
                    }}
                  ></Form.Control>
                </Col>
                <Col>
                  <Row>
                    <Col xl={8}>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="company">{t('company')}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t('company')}
                          id="company"
                          {...register("name")}
                          isInvalid={errors.name ? true : false}
                        />
                        <FormCustom.ErrorMessage field="name" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="cnpj">cnpj</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="cnpj"
                          {...register("cnpj")}
                          id="cnpj"
                          isInvalid={errors.cnpj ? true : false}
                        />
                        <FormCustom.ErrorMessage field="cnpj" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label htmlFor="corporateName">
                        {t('corporate_reason')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="corporateName"
                        {...register("corporateName")}
                        isInvalid={errors.corporateName ? true : false}
                      />
                    </Col>
                    <Col>
                      <Form.Label htmlFor="chair">{t('chair')}</Form.Label>
                      <Form.Select
                        id="chair"
                        {...register("chairId")}
                        isInvalid={errors.chairId ? true : false}
                      >
                        {chair.map((item, i) => {
                          return (
                            <option key={i} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <FormCustom.ErrorMessage field="chairId" />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xl={2}>
                  <Form.Label htmlFor="cep">{t('cep')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="cep"
                    {...register("cep")}
                    isInvalid={errors.cep ? true : false}
                  />
                </Col>
                <Col>
                  <Form.Label htmlFor="address">{t('address')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="address"
                    {...register("address")}
                    isInvalid={errors.address ? true : false}
                  />
                  <FormCustom.ErrorMessage field="address" />
                </Col>
                <Col xl={2}>
                  <Form.Label htmlFor="complement">{t('complement')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="complement"
                    {...register("complement")}
                    isInvalid={errors.complement ? true : false}
                  />
                  <FormCustom.ErrorMessage field="complement" />
                </Col>
              </Row>
              <Row>
                <Col xl={2}>
                  <Form.Label htmlFor="neighborhood">{t('Neighborhood')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="neighborhood"
                    {...register("neighborhood")}
                    isInvalid={errors.neighborhood ? true : false}
                  />
                  <FormCustom.ErrorMessage field="neighborhood" />
                </Col>
                <Col xl={1}>
                  <Form.Label htmlFor="uf">{t('UF')}</Form.Label>
                  <Form.Select
                    id="uf"
                    {...register("uf")}
                    isInvalid={errors.uf ? true : false}
                  >
                    {ufs.map((item, i) => {
                      return (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <FormCustom.ErrorMessage field="uf" />
                </Col>
                <Col xl={2}>
                  <Form.Label htmlFor="city">{t('city')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="city"
                    {...register("city")}
                    isInvalid={errors.city ? true : false}
                  />
                  <FormCustom.ErrorMessage field="city" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label htmlFor="site">Site</Form.Label>
                  <Form.Control type="text" id="site" {...register("site")} />
                  <FormCustom.ErrorMessage field="site" />
                </Col>
                <Col>
                  <Form.Label htmlFor="instagram">{t('Instagram')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="instagram"
                    {...register("instagram")}
                  />
                  <FormCustom.ErrorMessage field="instagram" />
                </Col>
                <Col>
                  <Form.Label htmlFor="facebook">{t('Facebook')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="facebook"
                    {...register("facebook")}
                  />
                  <FormCustom.ErrorMessage field="facebook" />
                </Col>
                <Col>
                  <Form.Label htmlFor="linkedin">{t('Linkedin')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="linkedin"
                    {...register("linkedin")}
                  />
                  <FormCustom.ErrorMessage field="linkedin" />
                </Col>
                <Col>
                  <Form.Label htmlFor="youtube">{t('YouTube')}</Form.Label>
                  <Form.Control
                    type="text"
                    id="youtube"
                    {...register("youtube")}
                  />
                  <FormCustom.ErrorMessage field="youtube" />
                </Col>
              </Row>
              <Row className="justify-content-md-end">
                <Col xl={3} sm={3} md={3} className="mt-3">                 
                    <Button
                    variant="primary"
                    type="submit"
                    className="float-end mb-3 mr-1 m-1"
                  >
                    {t("submit")}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="float-end mb-3 mr-1 m-1"
                    onClick={handleGoBack}
                  >
                    {t("goback")}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </FormProvider>
      </Container>
    </div>
  );
};

export default EditCompany;
