import { ListGroup } from "react-bootstrap"
import { IRank } from "../../../../interfaces/report/IRanking"
import { getHexdecimalColor } from "../../../../utils/Helper"

interface ItemRankProps {
  description: string 
  rankItems: IRank[]
}

export const ItemRank = ({ description, rankItems }: ItemRankProps) => {
  return (
    <div className="w-90 mb-5">
      <span className="mb-2 d-block fw-semibold fs-6">
        {description}
      </span>

      {
        !rankItems.length && (
          <span className="mb-2 d-block fw-semibold fs-6">No data</span>
        )
      }

      {
        !!rankItems.length && (
          <ListGroup>
            {
              rankItems.map((item, index: number) => (
                <ListGroup.Item 
                  className="d-flex w-100 justify-content-between"
                  key={item.name}
                  style={{ backgroundColor: getHexdecimalColor(item.point) }}
                >
                  <div className="w-75">
                    <span className="me-2">{ index + 1 }</span>
                    <span>{ item.name }</span>
                  </div>

                  <span>{ item.point }</span>
                </ListGroup.Item>
              ))
            }
          </ListGroup>
        )
      }
    </div>
  )
}