import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap"
import { CreateGuests } from "./components/CreateGuests"
import { z } from 'zod'
import { FormProvider, useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import { ISelectDefault } from "../../interfaces/form/form.interface"
import { getDateLocale, getOnlyNumberPhone } from "../../utils/Helper"
import { listAllGroup } from "../../api/list/list"
import { getGuestsList, getOptionsDate, storeGuests } from "../../api/guests/guests"
import { ListGuests } from "./components/ListGuests"
import { IGuestShowResponse } from "../../interfaces/guests/guests.interface"
import { searchFactory } from "../../factory/searchFactory"
import { IResponseGeneric } from "../../interfaces/generic/generic.interface"
import { IPaginationsCallback } from "../../components/Table/PaginationComponent"
import { ITableSearchFormData } from "../../components/Table/TableSearchComponent"
import useGuard from "../../contexts/Guard"

const createGuestSchema = z.object({
    name: z.string().nonempty({ message: 'Nome é obrigatório' }),
    email: z.string().email({ message: 'Email Deve ser valido' }).nonempty({ message: 'Email é obrigatório' }),
    phone: z.string().nonempty({ message: 'Telefone é obrigatório' }).transform((value) => getOnlyNumberPhone(value)),
    company: z.string().nonempty({ message: 'Empresa é obrigatório' }),
    companyActivity: z.string().nonempty({ message: 'Atividade da empresa é obrigatório' }),
    groupId: z.coerce.number().positive(),
    meetingDate: z.coerce.date(),
})

export type CreateGuestsFormData = z.infer<typeof createGuestSchema>

const Guests: React.FC = () => {

    const { groupId } = useParams()
    const { CheckPermissionWithArray } = useGuard()
    const [activeTab, setActiveTab] = useState<number>(0);
    const [groupOptions, setGroupOptions] = useState<ISelectDefault[]>([])
    const [dateOptions, setDateOptions] = useState<ISelectDefault[]>([])
    const [guests, setGuests] = useState<IResponseGeneric<IGuestShowResponse>>(
        {} as IResponseGeneric<IGuestShowResponse>
    )
    const [serachParams, setSerachParams] = useState('')

    const createGuestForm = useForm<CreateGuestsFormData>({
        resolver: zodResolver(createGuestSchema),
    })

    const {
        handleSubmit,
        setValue,
        reset,
        watch,
    } = createGuestForm


    const loadingData = () => {
        listGuests()
        listAllGroup().then((response) => {
            const { data } = response
            const groupOptions = data.map((item) => ({
                value: item.id,
                label: item.name
            }))
            setGroupOptions(groupOptions)
            setTimeout(() => {
                setValue('groupId', +groupId!)
            }, 100)
        })
    }

    const listGuests = () => {
        getGuestsList({
            group_id: +groupId!,
            params: searchFactory().createUrlParams({
                page: 1,
                perPage: 10
            })
        }).then((response) => {
            const { data } = response
            setGuests(data)
        })
    }

    const listGuestPaginations = (params: IPaginationsCallback) => {
        const paramsUrl = searchFactory().createUrlParams({
            page: params.page,
            perPage: params.perPage,
            contact: serachParams
        })

        getGuestsList({
            group_id: +groupId!,
            params: paramsUrl
        }).then((response) => {
            const { data } = response
            setGuests(data)
        })
    }

    const listGuestSearch = (params: ITableSearchFormData) => {
        setSerachParams(params.search)
        const paramsUrl = searchFactory().createUrlParams({
            page: 1,
            perPage: 10,
            contact: params.search
        })

        getGuestsList({
            group_id: +groupId!,
            params: paramsUrl
        }).then((response) => {
            const { data } = response
            setGuests(data)
        })
    }

    const group = watch('groupId')

    const handleGoBack = () => {
        setActiveTab(0);
    }


    const onSubmit = (data: CreateGuestsFormData) => {
        storeGuests({
            company: data.company,
            companyActivity: data.companyActivity,
            email: data.email,
            id_group: data.groupId,
            meeting_day: data.meetingDate,
            name: data.name,
            phone: data.phone,
            id_group_created: +groupId!
        }).then((response) => {
            if (response) {
                listGuests()
                reset()
            }
        })
    }

    useEffect(() => {
        loadingData()
    }, [groupId])

    useEffect(() => {
        setValue('meetingDate', new Date())
    }, [dateOptions])

    useEffect(() => {
        if (group) {
            getOptionsDate(group).then((response) => {
                const { data } = response
                const dateOptions = data.map((item) => ({
                    value: item.date,
                    label: getDateLocale(item.date, 'pt-BR')
                }))

                setDateOptions(dateOptions)
            })
        }
    }, [group])

    return (
        <div className="m-2">
            <Container fluid>
                <Row className="border border-secondary border-2 rounded pt-4">
                    <Col>
                        <Tabs
                            activeKey={activeTab}
                            defaultActiveKey={0}
                            onSelect={(index) => setActiveTab(+index!)}
                            transition={false}
                            className="mb-3"
                        >

                            <Tab eventKey={0} title='Listagem de Convidados'>
                                <ListGuests
                                    data={guests.data}
                                    meta={guests.meta}
                                    callbackPagination={listGuestPaginations}
                                    callbackSearch={listGuestSearch}
                                />
                            </Tab>
                            {CheckPermissionWithArray(['guests.store']) && (
                                <Tab eventKey={1} title='Cadastrar Convidados'>
                                    <FormProvider {...createGuestForm}>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <CreateGuests
                                                groupOptions={groupOptions}
                                                dateOptions={dateOptions}
                                                goBack={handleGoBack}
                                            />
                                        </Form>
                                    </FormProvider>
                                </Tab>
                            )}

                        </Tabs>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Guests