import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  deleteCompany,
  getComanysWithPaginator,
  storeCompany,
} from "../../../api/company/Company";
import {
  IPhotoSend,
  IResponseGeneric,
} from "../../../interfaces/generic/generic.interface";
import { ICompanyResponse } from "../../../interfaces/company/IGetCompanys";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { FormCustom } from "../../../components/Form";
import {
  blobToBase64,
  clearBase64,
  getOnlyNumberCep,
  getOnlyNumberOfCnpj,
  maskCnpj,
  resolveOptionsSelect,
  zipCodeMask,
} from "../../../utils/Helper";
import { getAddresByCep, getUfs } from "../../../api/cep/Cep";
import { getAllChairs } from "../../../api/chair/Chair";
import { ISelectDefault } from "../../../interfaces/form/form.interface";
import { TableCustomComponent } from "../../../components/Table";
import { ITableComponentCols } from "../../../components/Table/TableComponent";
import { ITableSearchFormData } from "../../../components/Table/TableSearchComponent";
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent";
import { searchFactory } from "../../../factory/searchFactory";
import useGuard from "../../../contexts/Guard";

const createCompanyFormSchema = z.object({
  name: z.string().nonempty("form_name_required"),
  cnpj: z
    .string()
    .nonempty("form_cnpj_required")
    .transform((value) => getOnlyNumberOfCnpj(value)),
  corporateName: z.string().nonempty("form_company_name_social_required"),
  cep: z
    .string()
    .nonempty("form_zip_code_required")
    .transform((value) => getOnlyNumberCep(value)),
  address: z.string().nonempty("form_address_required"),
  complement: z.string().optional(),
  neighborhood: z.string().nonempty("form_neighborhood_required"),
  uf: z.string().nonempty("form_uf_required"),
  city: z.string().nonempty("form_city_required"),
  chairId: z.coerce.number({ required_error: "form_chair_required" }),
  site: z.string().optional(),
  instagram: z.string().optional(),
  youtube: z.string().optional(),
  facebook: z.string().optional(),
  linkedin: z.string().optional(),
});

export type ICreateCompanyFormData = z.infer<typeof createCompanyFormSchema>;

const CreateCompany: React.FC = () => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { CheckPermissionWithArray } = useGuard()


  const [activeTab, setActiveTab] = useState<any>(0);
  const [company, setCompany] = useState<IResponseGeneric<ICompanyResponse>>(
    {} as IResponseGeneric<ICompanyResponse>
  );
  const [photo, setPhoto] = useState<File | null>(null);
  const [ufs, setUfs] = useState<string[]>([]);
  const [chair, setChair] = useState<ISelectDefault[]>([]);

  const createCompanyForm = useForm<ICreateCompanyFormData>({
    resolver: zodResolver(createCompanyFormSchema),
  });

  const [searchParams, setSearchParams] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = createCompanyForm;

  const cnpj = watch("cnpj");

  const recordMap = (data: ICompanyResponse[]): ICompanyResponse[] => {
    return data.map((item) => {
      return {
        ...item,
        cnpj: maskCnpj(item.cnpj),
      }
    })
  }

  const getCompany = async () => {
    const params = searchFactory().createUrlParams({
      page: 1,
    })
    const { data } = await getComanysWithPaginator({
      groupId: +groupId!,
      meta: params
    })
    setCompany({ data: recordMap(data.data), meta: data.meta })
  };

  const companySearch = async ({ search }: ITableSearchFormData) => {
    setSearchParams(search)
    const params = searchFactory().createUrlParams({
      page: 1,
      name: search
    })
    const { data } = await getComanysWithPaginator({
      groupId: Number(groupId),
      meta: params
    })
    setCompany({ data: recordMap(data.data), meta: data.meta })
  }
  const companyPaginator = async ({ page }: IPaginationsCallback) => {
    const params = searchFactory().createUrlParams({
      page,
      name: searchParams
    })
    const { data } = await getComanysWithPaginator({
      groupId: Number(groupId),
      meta: params
    })
    setCompany({ data: recordMap(data.data), meta: data.meta })
  }

  const handleChangePhoto = async (files: FileList) => {
    if (!files) return;
    if (files[0]) {
      const src = URL.createObjectURL(files[0]);
      const preview = document.getElementById(
        "previewImage"
      ) as HTMLImageElement;
      preview.src = src;
      preview.style.display = "block";
      setPhoto(files[0]);
    }
  };

  const handleResetPhoto = async () => {
    const preview = document.getElementById("previewImage") as HTMLImageElement;
    preview.src = "";
    preview.style.display = "block";
    setPhoto(null);
  };

  const watchCep = watch("cep");

  const columns: ITableComponentCols[] = [
    {
      title: t('company'), render: (row: ICompanyResponse) => (
        <span>{row.name}</span>
      )
    },
    {
      title: t('CNPJ'), render: (row: ICompanyResponse) => (
        <span>{row.cnpj}</span>
      )
    },
    {
      title: "actions",
      permissions: ['company.delete', 'company.update'],
      render: (row: ICompanyResponse) => (
        <>
          <div>
            {CheckPermissionWithArray(['company.update']) && (
              <Button
                variant="primary"
                type="button"
                className="me-4"
                onClick={() => {
                  navigate(`${row.id}`);
                }}
              >
                {t('actionEdit')}
              </Button>
            )}
            {CheckPermissionWithArray(['company.delete']) && (
              <Button
                variant="danger"
                type="button"
                onClick={() => {
                  handleDelete(row);
                }}
              >
                {t('actionDelete')}
              </Button>
            )}
          </div>
        </>
      )
    },
  ];

  const handleGetUfs = () => {
    getUfs().then((response) => {
      setUfs(response.data);
    });
  };


  const handleGoBack = () => {
    setActiveTab(0);
  }


  const onSubmit = async (data: ICreateCompanyFormData) => {
    const base64photo: IPhotoSend = {} as IPhotoSend;
    if (photo) {
      const base64 = await blobToBase64(photo);
      base64photo.file = clearBase64(base64 as string);
      base64photo.name = photo.name;
      base64photo.size = photo.size;
    }

    storeCompany({
      ...data,
      photo: base64photo.file ? base64photo : null,
      groupId: +groupId!,
    }).then(() => {
      reset();
      handleResetPhoto();
      getCompany();
    });
  };

  const handleGetChair = () => {
    getAllChairs({ groupId: +groupId! }).then((response) => {
      setChair(resolveOptionsSelect(response.data));
    });
  };

  const handleDelete = (data: ICompanyResponse) => {
    deleteCompany({
      groupId: +groupId!,
      idCompany: data.id,
    }).then(() => {
      getCompany();
    });
  };

  useEffect(() => {
    getCompany();
    handleGetUfs();
    handleGetChair();
  }, [groupId]);

  useEffect(() => {
    setValue("cnpj", maskCnpj(cnpj));
  }, [cnpj]);

  useEffect(() => {
    setValue("cep", zipCodeMask(watchCep));
    if (watchCep?.length > 8) {
      const cep = getOnlyNumberCep(watchCep);
      getAddresByCep(cep).then((response) => {
        const { data } = response;
        if (data.uf) {
          setValue("address", data.logradouro);
          setValue("neighborhood", data.bairro);
          setValue("city", data.localidade);
          setValue("uf", data.uf);
        }
      });
    }
  }, [watchCep]);

  return (
    <div className="m-2">
      <Container fluid>
        <Row className="border border-secondary border-2 rounded pt-4 vh-100">
          <Col>
            <Tabs
              onSelect={(index) => setActiveTab(index)}
              activeKey={activeTab}
              defaultActiveKey="0"
              transition={false}
              className="mb-3"
            >
              <Tab eventKey="0" title={t('business_listing')}>
                <TableCustomComponent.Root>
                  <Col>
                    <TableCustomComponent.Search callback={companySearch} />
                  </Col>
                  <Col>
                    <TableCustomComponent.Table
                      striped
                      cols={columns}
                      data={company.data}
                    />
                  </Col>
                  <Col>
                    <TableCustomComponent.Pagination
                      {...company.meta}
                      callback={companyPaginator}
                    />
                  </Col>
                </TableCustomComponent.Root>
              </Tab>
              
              {CheckPermissionWithArray(['company.store']) && (
                <Tab eventKey="1" title={t('create_company')}>
                  <FormProvider {...createCompanyForm}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Container fluid>
                        <Row>
                          <Col xl={2}>
                            <img className="imagePreview" id="previewImage"></img>
                            <label
                              className="form-label btn btn-primary w-100"
                              htmlFor="1"
                            >
                              {t("changePhoto")}
                            </label>
                            <Form.Control
                              style={{ display: "none" }}
                              type="file"
                              id="1"
                              onChange={(e) => {
                                const files = (e.target as HTMLInputElement)
                                  .files;
                                if (files) handleChangePhoto(files);
                              }}
                            ></Form.Control>
                          </Col>
                          <Col>
                            <Row>
                              <Col xl={8}>
                                <Form.Group className="mb-3">
                                  <Form.Label htmlFor="company">
                                    {t('company')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Empresa"
                                    id="company"
                                    {...register("name")}
                                    isInvalid={errors.name ? true : false}
                                  />
                                  <FormCustom.ErrorMessage field="name" />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className="mb-3">
                                  <Form.Label htmlFor="cnpj">cnpj</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="cnpj"
                                    {...register("cnpj")}
                                    id="cnpj"
                                    isInvalid={errors.cnpj ? true : false}
                                  />
                                  <FormCustom.ErrorMessage field="cnpj" />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Label htmlFor="corporateName">
                                  {t('corporate_reason')}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  id="corporateName"
                                  {...register("corporateName")}
                                  isInvalid={errors.corporateName ? true : false}
                                />
                              </Col>
                              <Col>
                                <Form.Label htmlFor="chair">{t('chair')}</Form.Label>
                                <Form.Select
                                  id="chair"
                                  {...register("chairId")}
                                  isInvalid={errors.chairId ? true : false}
                                >
                                  {chair.map((item, i) => {
                                    return (
                                      <option key={i} value={item.value}>
                                        {item.label}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                                <FormCustom.ErrorMessage field="chairId" />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={2}>
                            <Form.Label htmlFor="cep">{t('cep')}</Form.Label>
                            <Form.Control
                              type="text"
                              id="cep"
                              {...register("cep")}
                              isInvalid={errors.cep ? true : false}
                            />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="address">{t('address')}</Form.Label>
                            <Form.Control
                              type="text"
                              id="address"
                              {...register("address")}
                              isInvalid={errors.address ? true : false}
                            />
                            <FormCustom.ErrorMessage field="address" />
                          </Col>
                          <Col xl={2}>
                            <Form.Label htmlFor="complement">
                              {t('complement')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="complement"
                              {...register("complement")}
                              isInvalid={errors.complement ? true : false}
                            />
                            <FormCustom.ErrorMessage field="complement" />
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={2}>
                            <Form.Label htmlFor="neighborhood">{t('Neighborhood')}</Form.Label>
                            <Form.Control
                              type="text"
                              id="neighborhood"
                              {...register("neighborhood")}
                              isInvalid={errors.neighborhood ? true : false}
                            />
                            <FormCustom.ErrorMessage field="neighborhood" />
                          </Col>
                          <Col xl={1}>
                            <Form.Label htmlFor="uf">{t('UF')}</Form.Label>
                            <Form.Select
                              id="uf"
                              {...register("uf")}
                              isInvalid={errors.uf ? true : false}
                            >
                              {ufs.map((item, i) => {
                                return (
                                  <option key={i} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <FormCustom.ErrorMessage field="uf" />
                          </Col>
                          <Col xl={2}>
                            <Form.Label htmlFor="city">{t('city')}</Form.Label>
                            <Form.Control
                              type="text"
                              id="city"
                              {...register("city")}
                              isInvalid={errors.city ? true : false}
                            />
                            <FormCustom.ErrorMessage field="city" />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Label htmlFor="site">{t('site')}</Form.Label>
                            <Form.Control
                              type="text"
                              id="site"
                              {...register("site")}
                            />
                            <FormCustom.ErrorMessage field="site" />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="instagram">{t('Instagram')}</Form.Label>
                            <Form.Control
                              type="text"
                              id="instagram"
                              {...register("instagram")}
                            />
                            <FormCustom.ErrorMessage field="instagram" />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="facebook">{t('Facebook')}</Form.Label>
                            <Form.Control
                              type="text"
                              id="facebook"
                              {...register("facebook")}
                            />
                            <FormCustom.ErrorMessage field="facebook" />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="linkedin">{t('Linkedin')}</Form.Label>
                            <Form.Control
                              type="text"
                              id="linkedin"
                              {...register("linkedin")}
                            />
                            <FormCustom.ErrorMessage field="linkedin" />
                          </Col>
                          <Col>
                            <Form.Label htmlFor="youtube">{t('YouTube')}</Form.Label>
                            <Form.Control
                              type="text"
                              id="youtube"
                              {...register("youtube")}
                            />
                            <FormCustom.ErrorMessage field="youtube" />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-end">
                          <Col xl={3} sm={3} md={3} className="mt-3">
                            <Button
                              variant="primary"
                              type="submit"
                              className="float-end mb-3 mr-1 m-1"
                            >
                              {t("submit")}
                            </Button>
                            <Button
                              variant="secondary"
                              type="button"
                              className="float-end mb-3 mr-1 m-1"
                              onClick={handleGoBack}
                            >
                              {t("goback")}
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </FormProvider>
                </Tab>
              )}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateCompany;
