import { useCallback, useEffect, useState } from "react";
import { getAllRoles } from "../../../../api/role/Role";
import { useParams } from "react-router-dom";
import { IRoleResponse } from "../../../../interfaces/role/Role.interface";
import { ISelectDefault } from "../../../../interfaces/form/form.interface";

export function useRole() {
  const { groupId } = useParams();
  const [role, setRole] = useState<IRoleResponse[]>([])
  const [roleSelect, setRoleSelect] = useState<ISelectDefault[]>([])

  const fetchRole = useCallback(() => {
    getAllRoles({ groupId: +groupId! }).then((response) => {
      setRole(response.data)
      const roleSelecetMap = response.data.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })

      setRoleSelect(roleSelecetMap)
    })
  }, [])

  useEffect(() => {
    fetchRole()
  }, [groupId])

  return {
    role,
    roleSelect
  }
}