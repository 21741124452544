import { useCallback, useEffect, useState } from "react";
import { ISelectDefault } from "../../../interfaces/form/form.interface";
import { getGuestNotBecomeMember } from "../../../api/guests/guests";
import { useParams } from "react-router-dom";
import { IGuestMembers } from "../../../interfaces/guests/guests.interface";
import { useTranslation } from "react-i18next";

export function useGuest () {
  const { t } = useTranslation()
  const { groupId } = useParams()
  const [membersOptions, setMembersOptions] = useState<ISelectDefault[]>([])
  const [ members, setMembers ] = useState<IGuestMembers[]>([])

  const fecthMembers =  useCallback(() => {

    getGuestNotBecomeMember(+groupId!).then((response) => {
      setMembers(response.data)
      const membersSelect: ISelectDefault[] = [
        {
          label: t('select_guest'),
          value: ''
        }
      ]

      response.data.forEach((item) => {
        membersSelect.push({
          label: `${item.guest_name} - ${item.group_name}`,
          value: item.guest_id
        })
      })

      setMembersOptions(membersSelect)
    })
  }, [])

  useEffect(() => {
    fecthMembers()
  }, [])

  return {
    membersOptions,
    members,
  }
}