import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { destroyChair, getChairs, saveChair } from "../../../api/chair/Chair";
import ConfirmationModal from "../../../components/custom/ConfirmationModal";
import { z } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormCustom } from "../../../components/Form";
import { IChairResponse } from "../../../interfaces/chair/IGetChairs";
import { ITableComponentCols } from "../../../components/Table/TableComponent";
import { TableCustomComponent } from "../../../components/Table";
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent";
import { ITableSearchFormData } from "../../../components/Table/TableSearchComponent";
import { searchFactory } from "../../../factory/searchFactory";
import useGuard from "../../../contexts/Guard";

const createChairSchema = z.object({
  name: z.string(),
});

type CreateChairFormData = z.infer<typeof createChairSchema>;

const CreateChair: React.FC = () => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const [data, setData] = useState<any>({});
  const [activeTab, setActiveTab] = useState<any>(0);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [row, setRow] = useState<IChairResponse>({} as IChairResponse);

  const createChairForm = useForm<CreateChairFormData>({
    resolver: zodResolver(createChairSchema),
  });
  const [searchParams, setSearchParams] = useState('')
  const { CheckPermissionWithArray } = useGuard()

  const { register, handleSubmit } = createChairForm;

  const getData = () => {
    const params = searchFactory().createUrlParams({
      page: 1
    })
    getChairs({ groupId: Number(groupId), meta: params }).then((response) => {
      setData(response.data);
    });
  }

  const searchChair = async ({ search }: ITableSearchFormData) => {
    const params = searchFactory().createUrlParams({
      page: 1,
      name: search
    })
    setSearchParams(search)
    const response = await getChairs({ groupId: Number(groupId), meta: params })
    setData(response.data)
  }
  const paginatorChair = async ({ page }: IPaginationsCallback) => {
    const params = searchFactory().createUrlParams({
      page: page,
      name: searchParams
    })
    const response = await getChairs({ groupId: Number(groupId), meta: params })
    setData(response.data)
  }

  const onSubmit = (e: CreateChairFormData) => {
    saveChair({ groupId: Number(groupId), data: e }).then(() => {
      getData();
      setActiveTab(0);
    });
  };

  const handleGoBack = () => {
    setActiveTab(0);
  };

  function handleDecline() {
    setShowModal(false);
  }

  const handleChairDelete = async () => {
    destroyChair({
      groupId: +groupId!,
      chairId: row.id,
    }).then(() => {
      getData();
      setShowModal(false);
    });
  };

  const openModalConfirmation = (row: IChairResponse) => {
    setShowModal(true);
    setRow(row);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns: ITableComponentCols[] = [
    {
      title: t("chair"), render: (row: IChairResponse) => (
        <span>{row.name}</span>
      )
    },
    {
      title: t('subchairs'), render: (row: IChairResponse) => (
        <span>{row.sub_total}</span>
      )
    },
    {
      title: 'ações', render: (row: IChairResponse) => (
        <>
          <div>
            <Button
              variant="primary"
              type="button"
              className="me-4"
              onClick={() => {
                navigate(`${row.id}`);
              }}
            >
              {t('actionEdit')}
            </Button>

            {CheckPermissionWithArray(['chair.delete']) && (
              <Button
                variant="danger"
                type="button"
                onClick={() => {
                  openModalConfirmation(row);
                }}
              >
                {t('actionDelete')}
              </Button>
            )}

          </div>
        </>
      )
    }
  ];

  return (
    <div className="m-2">
      <Container fluid>
        <Row className="border border-secondary border-2 rounded vh-100">
          <Col>
            <Tabs
              activeKey={activeTab}
              onSelect={(index) => setActiveTab(index)}
              defaultActiveKey="listChair"
              transition={false}
              className="mb-3"
            >
              <Tab eventKey="0" title={t("tabListChair")}>
                <TableCustomComponent.Root>
                  <Col>
                    <TableCustomComponent.Search callback={searchChair} />
                  </Col>
                  <Col>
                    <TableCustomComponent.Table
                      data={data.data}
                      cols={columns}
                    />
                  </Col>
                  <Col>
                    <TableCustomComponent.Pagination
                      {...data.meta}
                      callback={paginatorChair}
                    />
                  </Col>
                </TableCustomComponent.Root>
              </Tab>
              {CheckPermissionWithArray(['chair.store']) && (
                <Tab eventKey="1" title={t("tabCreateChair")}>
                  <FormProvider {...createChairForm}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Container fluid>
                        <Row>
                          <Col xl={4} sm={4} md={4}>
                            <Form.Label htmlFor="name">{t("name")}</Form.Label>
                            <Form.Control
                              id="name"
                              type="text"
                              {...register("name")}
                            />
                            <FormCustom.ErrorMessage field="name" />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-end">
                          <Col xl={3} sm={3} md={3} className="mt-3">
                            <Button
                              variant="primary"
                              type="submit"
                              className="float-end mb-3 mr-1 m-1"
                            >
                              {t("submit")}
                            </Button>
                            <Button
                              variant="secondary"
                              type="button"
                              className="float-end mb-3 mr-1 m-1"
                              onClick={handleGoBack}
                            >
                              {t("goback")}
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </FormProvider>
                </Tab>
              )}
            </Tabs>
          </Col>
        </Row>
      </Container>
      <div>
        {showModal && (
          <ConfirmationModal
            show={showModal}
            message={t("messageConfirmationModal")}
            onConfirm={handleChairDelete}
            onDecline={handleDecline}
          />
        )}
      </div>
    </div>
  );
};

export default CreateChair;
