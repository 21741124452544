import { ICompanyDelete, ICompanyEdit, ICompanyResponse, ICompanyShow, ICompanyStorage, ICompanyStore, IGetCompanyByIdResponse, IGetCompanys } from '../../interfaces/company/IGetCompanys'
import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import api from '../Api'

export const getAllCompanys = async ({ groupId }: IGetCompanys) => {
    return await api.get<ICompanyStorage[]>(`group/${groupId}/company/all`)
}

export const getComanysWithPaginator = async ({ groupId, meta }: IGetCompanys) => {
    const url = `group/${groupId}/company/${meta}`
    return await api.get<IResponseGeneric<ICompanyResponse>>(url)
}

export const storeCompany = async ({ groupId , photo, ...data }: ICompanyStore) => {
    if (photo?.file) { 
        return await api.post(`group/${groupId}/company`, { ...data, photo })
    }
    return await api.post(`group/${groupId}/company`, data)
}

export const showCompany = async ({ groupId, idCompany }: ICompanyShow) => {
    return await api.get<IGetCompanyByIdResponse>(`group/${groupId}/company/${idCompany}`)
}

export const editCompany = async ({ groupId, companyId, photo, ...data }: ICompanyEdit) => {
    if (photo?.file) {
        return await api.put(`group/${groupId}/company/${companyId}`, { ...data, photo })
    }

    return await api.put(`group/${groupId}/company/${companyId}`, data)
}

export const deleteCompany = async ({ groupId, idCompany }: ICompanyDelete) => {
    return await api.delete(`group/${groupId}/company/${idCompany}`)
}