import React from "react";
import { FormCustom } from ".";
import { FormSelectProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IFormLangProps extends FormSelectProps {
  field: string
} 

export const FormLang: React.FC<IFormLangProps> = ({
  field,
  ...rest
}) =>  {
  const { t } = useTranslation()

  const langs = [{
    value: "BR",
    label: t("languageBr")
  },
  {
    value: "PT",
    label: t("languagePt")
  },
  {
    value: "ES",
    label: t("languageEs")
  },
  {
    value: "US",
    label: t("languageUs")
  }]

  return (
    <FormCustom.Select options={langs} field={field} {...rest} />
  )
}