import { useEffect, useState } from "react";
import { IResponseGeneric } from "../../../../interfaces/generic/generic.interface";
import { IResponseUser } from "../../../../interfaces/user/IGetUsers";
import { getUsers, deleteUser, createUser } from "../../../../api/user/User";
import { IPaginationsCallback } from "../../../../components/Table/PaginationComponent";
import { searchFactory } from "../../../../factory/searchFactory";
import { ITableSearchFormData } from "../../../../components/Table/TableSearchComponent";
import { ICreateUserParams, ICreateUserResponse } from "../../../../interfaces/user/IUser";

export function useUser(groupId: number) {
  const [users, setUsers] = useState<IResponseGeneric<IResponseUser>>(
    {} as IResponseGeneric<IResponseUser>
  )

  const [searchParams, setSearchParams] = useState('')

  const fetchAllUsers = async () => {
    const params = searchFactory().createUrlParams({
      page: 1,
      perPage: 10
    })

    const { data: user } = await getUsers({
      groupId,
      meta: params    
    })

    if (user) {
      setUsers(formatedData(user))
    }
  }

  const formatedData = (response: IResponseGeneric<IResponseUser>) => {
    const { data, meta } = response

    return {
      data,
      meta
    }    
  }

  const fetchUsersPaginator = async (params: IPaginationsCallback) => {
    const newParams = {...params, name: searchParams}
    const { data: user } = await getUsers({
      groupId,
      meta: searchFactory().createUrlParams(newParams),
    })

    if (user) {
      setUsers(formatedData(user))
    }
  }

  const fetchDeleteUser = async (id: number) => {
    const resposne = await deleteUser({
      groupId: groupId,
      userId: id
    })

    if (resposne) {
      fetchAllUsers()
    }
  }

  const searchUsers = async (params: ITableSearchFormData) => {
    setSearchParams(params.search)
    let urlParams = searchFactory().createUrlParams({
      page: 1,
      perPage: 10,
      name: params.search
    })

    const { data: user } = await getUsers({
      groupId,
      meta: urlParams,
    })

    if (user) {
      setUsers(formatedData(user))
    }
  }

  const refleshUser = () => {
    fetchAllUsers()
  }
  
  const createUserApi = (paylod: ICreateUserParams): ICreateUserResponse => {
    createUser(paylod, groupId).then((response) => {
      if (response) {
        fetchAllUsers()
      }
    })
    
    return {
      status: true
    }
  }

  useEffect(() => {
    fetchAllUsers()
  }, [groupId])

  return {
    users,      
    fetchUsersPaginator,
    fetchDeleteUser,
    searchUsers,
    createUserApi,
    refleshUser
  }
}