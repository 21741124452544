import React, { createContext, useContext } from 'react'
import useAuth from "../contexts/Auth"
import { useNavigate } from 'react-router-dom'
import { IGuardContext } from '../interfaces/login/IGuardContext'
import { IGetPermissionProps } from '../interfaces/permission/IPermission'
import { changeContextGroup } from '../api/changeContext/changeContext'

const GuardContext = createContext<IGuardContext>({} as IGuardContext)
const ADMIN = "ADMIN"

export const GuardProvider: React.FC<any> = ({ children }: any) => {
    const { infoUser, groups, setInfoUser } = useAuth()
    const navigate = useNavigate()


    const Permission = (): IGetPermissionProps => {
        const permissions = infoUser.role_selected.permission.map((permission) => { 
            return permission.key
        })

        return { permissions: permissions }
    }

    const CheckPermission = (permissionName: string): boolean => {        
        const { permissions } = Permission()
        const perm = permissions.find((permission: string) => {
            return permission === permissionName
        })

        return perm || infoUser.role_selected.key === ADMIN ? true : false        
    }

    const CheckPermissionWithArray = (permissionsProps: string[]): boolean => {
        const { permissions } = Permission()
        if (checkIfUserIsAdmin()) {
            return true
        }
        if (permissions.some(item => permissionsProps.includes(item))) {
            return true
        }

        return false
    }


    const IsAdmin = () => {
        if (infoUser.role_selected.id !== 1) {
            return navigate('/403')
        }
    }

    const checkIfUserIsAdmin = (): boolean => {
        return (infoUser.role_selected.key === ADMIN)
    }

    const ChangeGroup = (pathname: string, { value }: any) => {            
        const split = pathname.split('/')
        if (split[1] === 'grupo') {
            split[2] = value                     
            changeContextGroup({ id: value, user_id: infoUser.user.id }).then(response => {
                const payload = {
                    user: response.data.user,
                    role_selected: response.data.role_selected,
                    group: response.data.group,
                    chair_selected: response.data.chair_selected,
                    company_selected: response.data.company_selected,
                    group_selected: response.data.group_selected,
                }
                
                setInfoUser(payload)
                localStorage.setItem('@App:user', JSON.stringify(payload))
                ValidateGroup(value, response.data.role_selected.key)
            })
        }
        return split.join('/')
    }

    const ValidateGroup = (groupId: number, key: string) => {        
        const validate = groups.find((group) => {
            return group.id === Number(groupId)
        })
            
        if (Boolean(validate) || key === ADMIN) {
            return validate ?? true
        }
        return navigate('/')
    }

    return (
        <GuardContext.Provider
            value={{ CheckPermission, IsAdmin, ChangeGroup, ValidateGroup, infoUser, CheckPermissionWithArray, checkIfUserIsAdmin }}
        >
            {children}
        </GuardContext.Provider >
    )
}

export function useGuard() {
    const context = useContext(GuardContext)
    return context
}

export default useGuard