import { useTranslation } from "react-i18next"
import { ITableComponentCols } from "../../../components/Table/TableComponent"
import { IGuestShowResponse } from "../../../interfaces/guests/guests.interface"
import { getDateLocale, phoneMask } from "../../../utils/Helper"
import { IMeta } from "../../../interfaces/generic/generic.interface"
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent"
import { TableCustomComponent } from "../../../components/Table"
import { Button, Col } from "react-bootstrap"
import { ITableSearchFormData } from "../../../components/Table/TableSearchComponent"
import { useNavigate } from "react-router-dom"
import useGuard from "../../../contexts/Guard"
interface IGuestsList {
    data: any[]
    meta: IMeta
    callbackPagination: (params: IPaginationsCallback) => void
    callbackSearch: (params: ITableSearchFormData) => void
}

export const ListGuests: React.FC<IGuestsList> = ({
    data,
    meta,
    callbackPagination,
    callbackSearch
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const { CheckPermissionWithArray } = useGuard()
    const columns: ITableComponentCols[] = [
        {
            title: 'Contato',
            render: (row: IGuestShowResponse) => (
                <span>{row.name}</span>
            )
        },
        {
            title: 'Empresa',
            render: (row: IGuestShowResponse) => (
                <span>{row.company}</span>
            )
        },
        {
            title: 'Telefone',
            render: (row: IGuestShowResponse) => (
                <span>{phoneMask(row.phone)}</span>
            )
        },
        {
            title: 'Data da Reunião',
            render: (row: IGuestShowResponse) => (
                <span>{getDateLocale(row.meeting_day, 'pt-BR')}</span>
            )
        },
        {
            title: 'Presença',
            render: (row: IGuestShowResponse) => (
                <span>{t(row.present)}</span>
            )
        },
        {
            title: '',
            permissions: ['guests.re-invite'],
            render: (row: IGuestShowResponse) => (
                <>
                    {CheckPermissionWithArray(['guests.re-invite']) && (
                        <div>
                            <Button
                                variant="primary"
                                type="button"
                                className=" me-4"
                                onClick={() => {
                                    navigate(`reconvidar/${row.id}/${row.name}`);
                                }}
                            >
                                Reconvidar
                            </Button>
                        </div>
                    )}
                </>

            )
        }
    ]

    return (
        <>
            <TableCustomComponent.Root>
                <Col>
                    <TableCustomComponent.Search
                        callback={callbackSearch}
                    />
                </Col>
                <Col>
                    <TableCustomComponent.Table
                        cols={columns}
                        data={data}
                        striped
                    />
                </Col>
                <Col>
                    <TableCustomComponent.Pagination
                        callback={callbackPagination}
                        {...meta}
                    />
                </Col>
            </TableCustomComponent.Root>
        </>
    )
}