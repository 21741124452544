import { useTranslation } from "react-i18next";
import { ITableComponentCols } from "../../../components/Table/TableComponent"
import { IIndicationsResponse } from "../../../interfaces/indications/indications.interface";
import { getDateLocale, phoneMask } from "../../../utils/Helper";
import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import useGuard from "../../../contexts/Guard";
import { TableCustomComponent } from "../../../components/Table";
import { IMeta } from "../../../interfaces/generic/generic.interface";
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent";
import { ITableSearchFormData } from "../../../components/Table/TableSearchComponent";

interface IListIndicationsProps {
    data: IIndicationsResponse[]
    meta: IMeta
    callbackPagination: (params: IPaginationsCallback) => void
    callbackSearch: (params: ITableSearchFormData) => void
}

export const ListIndications: React.FC<IListIndicationsProps> = ({
    data,
    meta,
    callbackPagination,
    callbackSearch
}) => {
    const { t } = useTranslation();
    const { infoUser } = useGuard()

    const columns: ITableComponentCols[] = [
        {
            title: 'Contato',
            render: (row: IIndicationsResponse) => (
                <span>{row.contact}</span>
            )
        },
        {
            title: 'Telefone',
            render: (row: IIndicationsResponse) => (
                <span>{phoneMask(row.phone)}</span>
            )
        },
        {
            title: 'Indicado para',
            render: (row: IIndicationsResponse) => (
                <span>{row.name}</span>
            )
        },
        {
            title: 'Data da indicação',
            render: (row: IIndicationsResponse) => (
                <span>{getDateLocale(row.indication_date, 'pt-BR')}</span>
            )
        },
        {
            title: 'Status',
            render: (row: IIndicationsResponse) => (
                <span>{t(row.status)}</span>
            )
        },
        {
            title: 'Ações',
            permissions: ['indications.update'],
            render: (row: IIndicationsResponse) => (
                <>
                    {row.status === 'INDICATION' ? (
                        <Link to={`/grupo/${infoUser.group_selected.id}/indicacoes/${row.id}`}>
                            <Button
                                type="button"
                                className="w-50"
                            >
                                {t('actionEdit')}
                            </Button>
                        </Link>
                    ) : (
                        <Link to={`/grupo/${infoUser.group_selected.id}/indicacoes/${row.id}`}>
                            <Button
                                type="button"
                                className="w-50"
                            >
                                Ver
                            </Button>
                        </Link>
                    )}
                </>
            )
        },
    ]

    return (
        <>
            <TableCustomComponent.Root>
                <Col>
                    <TableCustomComponent.Search
                        callback={callbackSearch}
                    />
                </Col>
                <Col>
                    <TableCustomComponent.Table
                        data={data}
                        cols={columns}
                        striped
                    />
                </Col>
                <Col>
                    <TableCustomComponent.Pagination
                        callback={callbackPagination}
                        {...meta}
                    />
                </Col>
            </TableCustomComponent.Root>
        </>
    )
}