import { useEffect, useState } from "react"
import { IListUserByGroupResponse } from "../interfaces/list/list.interface"
import { ISelectDefault } from "../interfaces/form/form.interface"
import { listUserByGroup } from "../api/list/list"

interface IUserByGroupProps {
    id_group: number
}

export function useUserByGroup({ id_group }: IUserByGroupProps) {
    const [users, setUsers] = useState<IListUserByGroupResponse[]>([])
    const [selectUser, setSelectUser] = useState<ISelectDefault[]>([])

    const fetchUsers = async (id_group: number) => {
        const users = await listUserByGroup(id_group)

        if (users) {
            setUsers(users.data)

            const selectUser = users.data.map((user: IListUserByGroupResponse) => {
                return {
                    label: user.user_name,
                    value: user.user_id
                }
            })

            setSelectUser(selectUser)
        }
    }
    
    useEffect(() => {
        if (!id_group) return
        fetchUsers(id_group)
    }, [id_group])

    return { users, selectUser }
}