import { Form, FormControlProps } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import { FormCustom } from "."

interface IFormTextProps extends FormControlProps {
    field: string,
    showError?: boolean
}

export const FormControl: React.FC<IFormTextProps> = ({
    field,
    showError = true,
    ...rest
}) => {
    const { register, formState: { errors } } = useFormContext()    
    return (
        <>
            <Form.Control
                className="rounded-4"
                isInvalid={errors[field] ? true : false}
                {...rest}
                {...register(field)}
            />
            {
                showError ? (
                    <FormCustom.ErrorMessage field={field} />
                ) : ''
            }
        </>
    )
}