import { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import './upload.css'
import { blobToBase64, clearBase64 } from "../../utils/Helper"
import { useHomeScreen } from "../../pages/admin/home-screen/hook/homeScreen"

export interface IUploadFile {
  eventResponse: (file: number) => void
}

export const UploadFile: React.FC<IUploadFile> = ({
  eventResponse
}) => {
  const {
    saveImages
  } = useHomeScreen()

  const onDrop = useCallback(async (acceptedFiles: any) => {
    const file = acceptedFiles[0]
    let base64String = await blobToBase64(file)
    base64String = clearBase64(base64String as string)

    const fileId = await saveImages({
      file: base64String as string,
      name: file.name,
      size: file.size,
    })

    eventResponse(fileId)    
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="upload-drag-container">
        <div className="upload-drag-container__cicle">
          <div className="upload-drag-container__cicle__plus"><span>+</span></div>
        </div>        
      </div>
      <div className="upload-drag__description">
        <p>Procure ou arraste sua imagem</p>
      </div>
    </div>
  )
}