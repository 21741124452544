import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

interface CurrencyInputProps {
    initialValue?: string;
    field: string,
    disabled?: boolean
}

export const FormCurrency: React.FC<CurrencyInputProps> = ({ initialValue, field, disabled }) => {
    const formatCurrency = (value: string | undefined): string => {
        if (!value) {
            return ''
        }
        const numericValue = value.replace(/[^0-9]/g, '');

        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(Number(numericValue) / 100);

        return formattedValue;
    };
    
    const [amount, setAmount] = useState<string>(formatCurrency(initialValue) || '');

    const { setValue } = useFormContext()

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const inputValue = e.target.value;
        setAmount(formatCurrency(inputValue));
    };

    useEffect(() => {
        if (amount) {
            setValue(field, amount)
        }
    }, [amount])

    return (
        <Form.Control
            type="text"
            id="amount"
            disabled={disabled}
            value={amount}
            className="input-currency"
            onChange={handleInputChange}
        />
    );
};