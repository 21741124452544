export enum StatusBussines {
    INDICATION = 'INDICATION',
    REFUSED = 'REFUSED',
    CLOSED = 'CLOSED',
    NOTCLOSED = 'NOTCLOSED',
}

export enum StatusUser {
  MEMBER = 'MEMBER',
  INACTIVE_MEMBER = 'INACTIVE_MEMBER',
  FORMER_MEMBER = 'FORMER_MEMBER',
}

export enum StatusUserWithGuest {
  MEMBER = 'MEMBER',
  INACTIVE_MEMBER = 'INACTIVE_MEMBER',
  FORMER_MEMBER = 'FORMER_MEMBER',
  GUEST = 'GUEST',
  EXEMPT_MEMBER = 'EXEMPT_MEMBER'
}