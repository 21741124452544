import './createTrainingMeeting.css';
import { Button, Form } from "react-bootstrap"
import useGuard from "../../../contexts/Guard"
import { FormCustom } from "../../../components/Form"

interface ICreateTrainingMeetingProps {
    goBack: () => void
}

export const CreateTrainingMeeting: React.FC<ICreateTrainingMeetingProps> = ({
    goBack
}) => {
    const { infoUser } = useGuard()

    return (
        <div>
            <h3 className="title-group-training">{infoUser.group_selected.name}</h3>
            <Form.Group className="mb-3 w-25">
                <Form.Label htmlFor="meeting_date">Data</Form.Label>
                <FormCustom.Control 
                    field="meeting_date"
                    id="meeting_date"
                    type="date"
                />
                <FormCustom.ErrorMessage field="meeting_date" />
            </Form.Group>
            <Button 
                type="submit"
                variant="primary"
                className="mb-3 mr-1 m-1 button-training-submit"                
            >Criar</Button>
            <p className='alert-training'><span>Importante:</span> Ao criar a reunião, você poderá acessa-lá em reuniões de treinamentos ou clicando <span onClick={goBack} className='goback'>aqui</span></p>
        </div>
    )
}