
export function searchFactory() {
  return {
    createUrlParams: function (obj: Object): string {
      if (!obj) {
        return ''
      }

      let search = '?'

      Object.keys(obj).forEach((key, index) => {
        search += `${key}=${(<{ [key: string]: string }>obj)[key]}`
        if (index < Object.keys(obj).length - 1) {
          search += '&'
        }
      })
      return search
    }
  };
}

