export const permissionAdminEnum = [
  'user.index',
  'user.store',
  'user.update',
  'user.delete',
  'group.index',
  'group.store',
  'group.update',
  'group.delete',
  'chair.index',
  'chair.store',
  'chair.update',
  'chair.delete',
  'company.index',
  'company.store',
  'company.update',
  'company.delete',
  'role.index',
  'role.store',
  'role.update',
  'role.delete',
  'reason.index',
  'reason.store',
  'reason.update',
  'reason.delete',
  'point.index',
  'point.update',
  'subChair.index',
  'subChair.store',
  'subChair.update',
  'subChair.delete',
]