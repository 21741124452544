import { Button, Col } from "react-bootstrap"
import { ITableComponentCols } from "../../../components/Table/TableComponent"
import { ITrainingMeetingListResponse } from "../../../interfaces/trainingMeeting/trainingMeeting.interface"
import { Link } from "react-router-dom"
import { getDateLocale } from "../../../utils/Helper"
import { IMeta } from "../../../interfaces/generic/generic.interface"
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent"
import { TableCustomComponent } from "../../../components/Table"

export interface IListTrainingMeetingProps {
    data: any[]
    meta: IMeta
    callbackPagination: (params: IPaginationsCallback) => void
}

export const ListTrainingMeeting: React.FC<IListTrainingMeetingProps> = ({
    data,
    meta,
    callbackPagination
}) => {

    const columns: ITableComponentCols[] = [
        {
            title: 'Data',
            render: (row: ITrainingMeetingListResponse) => (
                <span>{getDateLocale(row.meeting_date, 'pt-BR')}</span>
            )
        },
        {
            title: 'Membros presentes',
            render: (row: ITrainingMeetingListResponse) => (
                <span>{row.present_users}</span>            
            )
        },
        {
            title: 'Ações',
            render: (row: ITrainingMeetingListResponse) => (
                <>
                { row.presence ? (
                    <Link to={`presence/training-meeting/${row.id}`}>
                        <Button className="button-show" variant="primary">Ver</Button>
                    </Link>
                ) : (
                    <Link to={`presence/training-meeting/${row.id}`}>
                        <Button variant="primary">Presença</Button>
                    </Link>                        
                ) }
                </>
            )
        }
    ]

    return (
        <>
            <TableCustomComponent.Root>
                <Col>
                    <TableCustomComponent.Table 
                        cols={columns}
                        data={data}
                        striped                      
                    />
                </Col>
                <Col>
                    <TableCustomComponent.Pagination 
                        callback={callbackPagination}
                        {...meta}
                    />
                </Col>
            </TableCustomComponent.Root>
        </>
    )
}