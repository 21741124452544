import './lisTraningMeetingPresence.css'
import { useParams } from "react-router-dom"
import { FormCustom } from "../../../components/Form"
import { useEffect, useState } from "react"
import { listAllGroup, listUserByGroup } from "../../../api/list/list"
import { IListUserByGroupResponse } from "../../../interfaces/list/list.interface"
import { z } from "zod"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { ISelectDefault } from "../../../interfaces/form/form.interface"
import { showTrainingMeetingPresence, storePresence } from "../../../api/trainingMeeting/training-meeting"
import { ITrainingMeetingPresenceParams, ITrainingMeetingPresenceResponse } from "../../../interfaces/trainingMeeting/trainingMeeting.interface"
import { Button, Form } from "react-bootstrap"
import { getDateLocale } from "../../../utils/Helper"
import { useTranslation } from 'react-i18next'

const createPresenceSchema = z.object({
    id_group: z.coerce.number({ required_error: 'Grupo é obrigatório' }).positive(),
    id_training: z.coerce.number({ required_error: 'Treinamento é obrigatório' }).positive(),
    presence: z.array(z.object({
        id_group: z.coerce.number({ required_error: 'Grupo é obrigatório' }).positive(),
        id_user: z.coerce.number({ required_error: 'Usuário é obrigatório' }).positive(),
        presence: z.coerce.boolean({ required_error: 'Presença é obrigatório' })
    }))
})

export type CreatePresenceFormData = z.infer<typeof createPresenceSchema>

export const ListTrainingMeetingPresence: React.FC = () => {
    const { t } = useTranslation();
    const { id_training, groupId } = useParams()
    
    const [user, setUser] = useState<IListUserByGroupResponse[]>([])
    const [group, setGroup] = useState<ISelectDefault[]>([])
    const [trainingMeetingPresence, setTrainingMeetingPresence] = useState<ITrainingMeetingPresenceResponse>({} as ITrainingMeetingPresenceResponse)

    const getPresenceList = (params: ITrainingMeetingPresenceParams) => {
        showTrainingMeetingPresence(params).then((response) => {
            setTrainingMeetingPresence({} as ITrainingMeetingPresenceResponse)          
            setTimeout(() => {
                setTrainingMeetingPresence(response.data)
                setValue('id_training', +id_training!)
            }, 1000)
        })
    }

    const getUser = () => {
        listUserByGroup(+groupId!).then((response) => {
            setUser(response.data)
        })
    }

    const getGroup = () => {
        listAllGroup().then((response) => {
            const selectGroup = response.data.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
            setGroup(selectGroup)
            setTimeout(() => {
                setValue('id_group', +groupId!)
            }, 200)
        })
    }

    const createPresenceForm = useForm<CreatePresenceFormData>({
        resolver: zodResolver(createPresenceSchema),        
    })

    const { append, remove } = useFieldArray({
        control: createPresenceForm.control,
        name: 'presence'
    })

    const {
        handleSubmit,
        setValue,
        watch,
        getValues
    } = createPresenceForm

    const selectGroup = watch('id_group')

    const handleOnChange = (e: any, user_id: number) => {        
        const { presence, id_group } = getValues()

        if (e.target.checked) {
            append({
                id_group: id_group,
                id_user: user_id,
                presence: e.target.checked
            })
            return
        }


        const presenceFindIndex = presence.findIndex((item) => item.id_user === user_id)

        if (presenceFindIndex) {
            remove(presenceFindIndex)
        }
    }

    const handleSetValueDefault = (id_user: number) => {
        const { presence, id_group } = getValues()

        const presenceFindIndex = presence.findIndex((item) => item.id_user === id_user && item.id_group === id_group)
 
        if (presenceFindIndex !== -1) {        
            return true
        }

        return false
    }

    const onSubmit = (data: CreatePresenceFormData) => {

        if (!data.presence.length) return
        storePresence({
            id_training_meeting: data.id_training,
            presence: data.presence
        }).then((response) => {
            if (response) {
                getPresenceList({
                    id_group: data.id_group,
                    id_training_meeting: data.id_training
                })
            }
        })         
    }

    useEffect(() => {
        getUser()
        getGroup()
    }, [])

    useEffect(() => {
        if (selectGroup) {
            getPresenceList({
                id_group: +selectGroup,
                id_training_meeting: +id_training!
            })
        }
    }, [selectGroup])


    return (
        <div className='border border-secondary border-2 rounded pt-4 mt-2 training-meeting-list'>
            <FormProvider {...createPresenceForm}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <h3 className='training-meeting-title'>Presença</h3>
                    <FormCustom.Select 
                        field="id_group"
                        options={group}
                        className='training-meeting-select'
                    />

                    <Form.Group>
                        <Form.Label className='training-meeting-title'>Data</Form.Label>
                        <p>{getDateLocale(trainingMeetingPresence.date_meeting, 'pt-BR')}</p>
                    </Form.Group>
                
                    <div className="training-meeting-container">
                        <div className="training-meeting-container__header">
                            <p className='training-meeting-title'>Membros</p>
                            <p className='training-meeting-title'>Presença?</p>
                        </div>
                        <div className="training-meeting-container__list">
                            {
                                trainingMeetingPresence.data?.map((item, index) => {                                
                                    return (
                                    <Form.Group key={index} className='training-meeting-container__list__form'>
                                        <Form.Label>{item.name}</Form.Label>
                                        <Form.Check  defaultChecked={trainingMeetingPresence.disabled ? item.presence :  handleSetValueDefault(item.id_user)}  onChange={(e) => handleOnChange(e, item.id_user)} disabled={trainingMeetingPresence.disabled} type="switch" />
                                    </Form.Group>
                                    )
                                })
                            }
                            
            
                        </div>
                    </div>
                    <div className='week-footer'>                        
                        {
                            trainingMeetingPresence?.disabled ? (
                                <>                                    
                                    <p>A lista de presença desta reunião já foi enviada e por isso não pode mais ser atualizada.</p>
                                </>
                            ) : (
                                <>
                                    <p><span>Importante: </span>Ao enviar a lista de participação, esta reunião não poderá mais ser atualizada</p>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="mb-3 mr-1 m-1"
                                    >
                                        {t("submit")}
                                    </Button>
                                </>
                            )
                        }                           
                    </div>
                </Form>
            </FormProvider>
        </div>
    )
}