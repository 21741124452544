import { IExtractSpecificPeriodParams } from '../../interfaces/extractSpeficPeriod/extractSpeficPeriod.interface'
import api from '../Api'

export const getExtractSpeficPeriod = async ({end_date, id_group, start_date}: IExtractSpecificPeriodParams) => {
  return await api.get(`report/extract-period/group/${id_group}`, {
    params: {
      end_date,
      start_date
    }
  })
}