import { useTranslation } from 'react-i18next';

type ITitlePage = {
    title: string;
}

export const TitlePage = ({ title }: ITitlePage) => {
  const { t } = useTranslation();

  return (
    <h1 className="fs-3 fw-bold mb-4">
      {t(title).toUpperCase()}
    </h1>
  );
};
