import { IResponseSearchByCep } from '../../interfaces/cep/cep.interface'
import api from '../Api'


export const getAddresByCep = async (cep: string) => {
  return await api.get<IResponseSearchByCep>(`cep/${cep}`)
}

export const getUfs = async () => {
  return await api.get('cep/ibge/ufs')
}