import { IDeleteLeadershipParams, IResponseLeaderShow, IStoreLeadershipGuestsParams, IStoreLeadershipParams } from "../../interfaces/leadership/leadership.interface"
import api from '../Api'

export const storeLeadership = async (params: IStoreLeadershipParams) => {
    return await api.post(`/leadership/group/${params.id_group}/user/${params.id_user}`, { meeting_date: params.meeting_date, guests: params.guests})
}

export const showLeadership = async ({ id_group, id_user }: IStoreLeadershipGuestsParams) => {
    return await api.get<IResponseLeaderShow[]>(`/leadership/group/${id_group}/user/${id_user}`)
}

export const deleteLeadership = async ({id_leadership_meetings, id_reason, id_user }: IDeleteLeadershipParams) => {
    return await api.delete(`/leadership/${id_leadership_meetings}/user/${id_user}/reason/${id_reason}`)
}