import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ListWeekMeeting } from "./components/ListWeekMeeting"
import { CreateWeekMeeting } from "./components/CreateWeekMeeting"
import { getMeetingWeekList, storeMeetingWeek } from "../../api/meetingWeek/meetingWeek"
import { IListMeetingWeekResponse } from "../../interfaces/meetingWeek/meetingWeek.interface"
import { blockContributionDate, tranformeValideStringDate } from "../../utils/Helper"
import { searchFactory } from "../../factory/searchFactory"
import { IResponseGeneric } from "../../interfaces/generic/generic.interface"
import { IPaginationsCallback } from "../../components/Table/PaginationComponent"
import useGuard from "../../contexts/Guard"

const createMeetingWeekSchema = z.object({
    meeting_day: z.coerce.date().refine((data) => blockContributionDate(data), { message: 'Não é possível criar reuniões com mais de 1 mês de atraso!' }),
    id_group: z.coerce.number({ required_error: 'grupo é obrigatório' }).positive(),
})

export type CreateNeetingWeekFormData = z.infer<typeof createMeetingWeekSchema>

const WeeklyMeetings: React.FC = () => {

    const { groupId } = useParams()
    const { CheckPermissionWithArray } = useGuard()

    const [activeTab, setActiveTab] = useState<number>(0);
    const [meetingWeeks, setMeetingWeeks] = useState<IResponseGeneric<IListMeetingWeekResponse>>(
        {} as IResponseGeneric<IListMeetingWeekResponse>
    )

    const createWeekMeetingForm = useForm<CreateNeetingWeekFormData>({
        resolver: zodResolver(createMeetingWeekSchema),
        defaultValues: {
            id_group: +groupId!
        }
    })

    const handleGoBack = () => {
        setActiveTab(0)
    }

    const {
        handleSubmit,
        reset
    } = createWeekMeetingForm

    const onSubmit = (data: CreateNeetingWeekFormData) => {
        storeMeetingWeek({
            meeting_day: tranformeValideStringDate(data.meeting_day),
            id_group: data.id_group
        }).then((response) => {
            if (response) {
                reset()
                getListMeetingWeek()
            }
        })
    }

    const getListMeetingWeek = () => {
        getMeetingWeekList({
            id_group: +groupId!,
            params: searchFactory().createUrlParams({
                page: 1,
                perPage: 10
            })
        }).then((response) => {
            const { data } = response
            setMeetingWeeks(data)
        })
    }

    const getListMeetingWeekPagination = (params: IPaginationsCallback) => {
        const paramsUrl = searchFactory().createUrlParams(params)

        getMeetingWeekList({
            id_group: +groupId!,
            params: paramsUrl
        }).then((response) => {
            const { data } = response
            setMeetingWeeks(data)
        })
    }

    useEffect(() => {
        getListMeetingWeek()
    }, [groupId])

    return (
        <div className="m-2">
            <Container fluid>
                <Row className="border border-secondary border-2 rounded pt-4">
                    <Col>
                        <Tabs
                            activeKey={activeTab}
                            defaultActiveKey={0}
                            onSelect={(index) => setActiveTab(+index!)}
                            transition={false}
                            className="mb-3"
                        >

                            <Tab eventKey={0} title='Listagem de Reuniões Semanais'>
                                <ListWeekMeeting
                                    data={meetingWeeks.data}
                                    meta={meetingWeeks.meta}
                                    callbackPagination={getListMeetingWeekPagination}
                                />
                            </Tab>

                            {CheckPermissionWithArray(['weekly-meetings.store']) && (
                                <Tab eventKey={1} title='Criar Reunião Semanal'>
                                    <FormProvider {...createWeekMeetingForm}>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <CreateWeekMeeting goBack={handleGoBack} />
                                        </Form>
                                    </FormProvider>
                                </Tab>
                            )}
                        </Tabs>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default WeeklyMeetings