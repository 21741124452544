import { useCallback, useEffect, useState } from "react";
import { ISelectDefault } from "../../../../interfaces/form/form.interface";
import { useParams } from "react-router-dom";
import { getAllCompanys } from "../../../../api/company/Company";
import { ICompanyStorage } from "../../../../interfaces/company/IGetCompanys";

export function useCompany() {

  const { groupId } = useParams();

  const [company, setCompany] = useState<ICompanyStorage[]>([])
  const [companySelect, setCompanySelect] = useState<ISelectDefault[]>([])

  const fetchCompany = useCallback(() => {
    getAllCompanys({ groupId: +groupId! }).then((response) => {
      setCompany(response.data)
      const companySelect = response.data.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })

      setCompanySelect(companySelect)
    })
  }, [])

  useEffect(() => {
    fetchCompany()
  }, [])

  return {
    company,
    companySelect
  }

}