import { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Row, Tab, Tabs } from "react-bootstrap"
import { IReasonResponse } from "../../interfaces/reason/reason.interface";
import { listAllGroup, listReason, listUserByGroup } from "../../api/list/list";
import { z } from "zod";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Presence } from "../../components/presence/Presence";
import { ListUser } from "../../components/listUser/ListUser";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import useGuard from "../../contexts/Guard";
import { IListParamsSelected, IListUserByGroupResponse, listAllGroupResponse } from "../../interfaces/list/list.interface";
import { deleteLeadership, showLeadership, storeLeadership } from "../../api/leadership/leadership";
import { IResponseLeaderShow } from "../../interfaces/leadership/leadership.interface";
import { useParams } from "react-router-dom";
import { ITableComponentCols, TableComponent } from "../../components/Table/TableComponent";
import { ModalComponent } from "../../components/ModalComponent";
import { blockContributionDate } from "../../utils/Helper";
import { FormCustom } from "../../components/Form";

const leadershipCreateSchema = z.object({
    date: z.coerce.date().refine((data) => blockContributionDate(data), { message: 'Não é possível criar uma reunião com mais de 1 mês de atraso!' }),
    groupId: z.coerce.number(),
    guests: z.array(z.object({
        user_name: z.string(),
        user_id: z.coerce.number(),
        group_name: z.string(),
        group_id: z.coerce.number(),
    })),
})

const leadershipDeleteSchema = z.object({
    id_reason: z.coerce.number(),
})


export type ICreateLeadershipFormData = z.infer<typeof leadershipCreateSchema>;
export type IDeleteLeadershipFormData = z.infer<typeof leadershipDeleteSchema>;


export const LeadershipMeeting: React.FC = () => {
    const { infoUser, CheckPermissionWithArray } = useGuard();
    const { t } = useTranslation();
    const { groupId } = useParams();

    const [activeTab, setActiveTab] = useState<number>(0);

    const [reasons, setReasons] = useState<IReasonResponse[]>([]);
    const [userMember, setUserMember] = useState<IListUserByGroupResponse[]>([]);
    const [groups, setGroups] = useState<listAllGroupResponse[]>([]);
    const [leadership, setLeadership] = useState<IResponseLeaderShow[]>([]);

    const [leadershipDelete, setLeadershipDelete] = useState<IResponseLeaderShow>({} as IResponseLeaderShow);
    const [showModal, setShowModal] = useState(false);


    const columns: ITableComponentCols[] = [
        {
            title: 'Membros',
            render: (data: IResponseLeaderShow) => {
                return (
                    <span>{data.guests.map(item => item.user?.name).join(', ')}</span>
                )
            }
        },
        {
            title: 'Dia da reunião',
            render: (data: IResponseLeaderShow) => (
                <span>{data.meeting_date}</span>
            )
        },
        {
            title: 'Ações',
            permissions: ['leadership_meetings_delete'],
            render: (data: IResponseLeaderShow) => (
                <Button
                    className="mr-1 m-1"
                    variant="danger"
                    onClick={() => handleOpenModalRemove(data)}
                >
                    Deletar
                </Button>
            )
        },
    ]

    const handleOpenModalRemove = (data: IResponseLeaderShow) => {
        setLeadershipDelete(data)
        setShowModal(true)
    }

    const getReasons = () => {
        listReason().then((response) => {
            setReasons(response.data)
            const first = [...response.data].pop()!
            setValueDelete('id_reason', first.id)
        })
    }

    const getLeadership = () => {
        showLeadership({
            id_group: infoUser.group_selected.id,
            id_user: infoUser.user.id,
        }).then((response) => {
            setLeadership(response.data);
        })
    }


    const listUsers = (group_id: number) => {
        listUserByGroup(group_id).then((response) => {
            setUserMember(response.data);
        });
    };

    const listGroup = () => {
        listAllGroup().then((response) => {
            setGroups(response.data);
            setTimeout(() => {
                setValue("groupId", infoUser.group_selected.id);
            }, 1000);
        });
    };

    const leadershipForm = useForm<ICreateLeadershipFormData>({
        resolver: zodResolver(leadershipCreateSchema),
        defaultValues: {
            groupId: infoUser.group_selected.id,
        },
    });

    const { handleSubmit, register, watch, control, setValue, reset } = leadershipForm;
    const watchGroupId = watch("groupId");

    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "guests",
    });


    const leadershipDeleteForm = useForm<IDeleteLeadershipFormData>({
        resolver: zodResolver(leadershipDeleteSchema),
    })

    const {
        handleSubmit: handleSubmitDelete,
        register: registerDelete,
        setValue: setValueDelete,
    } = leadershipDeleteForm

    const handleAdd = (data: IListParamsSelected) => {
        const isExist = fields.findIndex((item) => item.user_id === data.user_id && item.group_id === data.group_id);
        if (isExist !== -1) {
            handleRemove(isExist);
            return;
        }
        append(data);
    };

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const handleActionsModal = (action: boolean) => {
        if (!action) {
            handleCloseModal()
            return
        }

        const data = leadershipDeleteForm.getValues()

        deleteLeadership({
            id_leadership_meetings: leadershipDelete.id,
            id_user: infoUser.user.id,
            id_reason: data.id_reason,
        }).then((r) => {
            getLeadership()
            handleCloseModal()
        })

    }

    const handleRemove = (index: number) => {
        if (index === 0) return
        remove(index);
    };

    const setCreatedUserLeadership = () => {
        append({
            user_id: infoUser.user.id,
            group_id: infoUser.group_selected.id,
            user_name: infoUser.user.name,
            group_name: infoUser.group_selected.name,
        })
    }

    const onSubimit = (form: ICreateLeadershipFormData) => {
        const guests = form.guests.map((item) => {
            return {
                id_user: item.user_id,
                id_group: item.group_id,
            }
        })

        storeLeadership({
            id_group: infoUser.group_selected.id,
            id_user: infoUser.user.id,
            meeting_date: form.date,
            guests
        }).then(() => {
            reset()
            setCreatedUserLeadership()
            getLeadership()
        })
    }

    useEffect(() => {
        listGroup();
        listUsers(+groupId!);
        setCreatedUserLeadership()
        getLeadership()
        getReasons()
    }, [groupId]);

    useEffect(() => {
        if (watchGroupId) {
            listUsers(+watchGroupId);
        }
    }, [watchGroupId]);


    return (
        <>
            <Container fluid className="p-4">
                <Col>
                    <Tabs
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(Number(k))}
                    >
                        <Tab eventKey={0} title="Listagem de Reunião de Liderança">
                            <TableComponent
                                cols={columns}
                                data={leadership}
                                striped
                            />
                        </Tab>

                        {CheckPermissionWithArray(['leadership_meetings_store']) && (
                            <Tab eventKey={1} title="Criar Reunião de Liderança">
                                <Form onSubmit={handleSubmit(onSubimit)}>
                                    <FormProvider {...leadershipForm}>
                                        <div className="rdn-form">
                                            <FormGroup className="form-date">
                                                <label htmlFor="data">Data</label>
                                                <Form.Control
                                                    type="date"
                                                    id="data"
                                                    {...register("date")}
                                                />
                                                <FormCustom.ErrorMessage field="date" />
                                            </FormGroup>
                                            <FormGroup>
                                                <label htmlFor="presence">Presentes</label>
                                                <Presence data={fields} remove={handleRemove} />
                                            </FormGroup>
                                            <FormGroup>
                                                <label htmlFor="group">Grupo</label>
                                                <Form.Select
                                                    className="form-select"
                                                    id="group"
                                                    {...register("groupId")}
                                                >
                                                    {groups.map((group, index) => (
                                                        <option key={index} value={group.id}>
                                                            {group.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </FormGroup>
                                            <FormGroup>
                                                <ListUser
                                                    data={userMember}
                                                    add={handleAdd}
                                                    fields={fields}
                                                />
                                            </FormGroup>
                                        </div>
                                        <Row className="justify-content-md-end">
                                            <Col xl={3} sm={3} md={3} className="mt-3">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    className="float-end mb-3 mr-1 m-1"
                                                >
                                                    {t("submit")}
                                                </Button>
                                                <Button
                                                    variant="secondary"
                                                    type="button"
                                                    className="float-end mb-3 mr-1 m-1"
                                                // onClick={handleGoBack}
                                                >
                                                    {t("goback")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </FormProvider>
                                </Form>
                            </Tab>
                        )}

                    </Tabs>
                </Col>
            </Container>

            {showModal && (
                <ModalComponent.Root
                    show={showModal}
                    onHide={handleCloseModal}
                    size="lg"
                    className="form-rdn-delete"
                >
                    <ModalComponent.Content
                        className="form-rdn-delete-content-wrapper"
                    >
                        <div className="form-rdn-delete-content">
                            <h3>Realmente deseja excluir essa reunião ?</h3>
                            <Container fluid className="form-rdn-wrapper">
                                <div className="form-rdn-delete-btn">
                                    <ModalComponent.Actions
                                        variant="primary"
                                        className="form-rnd-delete-btn-width"
                                        onClick={() => handleActionsModal(true)}
                                    >
                                        Sim
                                    </ModalComponent.Actions>
                                    <ModalComponent.Actions
                                        variant="danger"
                                        className="form-rnd-delete-btn-width"
                                        onClick={() => handleActionsModal(false)}
                                    >
                                        Não
                                    </ModalComponent.Actions>
                                </div>
                            </Container>
                        </div>
                    </ModalComponent.Content>
                </ModalComponent.Root>
            )}
        </>
    )
}