import './cardInfo.css'


export interface ICardInfoProps {
  icon: string
  title: string
  children: React.ReactNode    
}

export const CardInfo: React.FC<ICardInfoProps> = ({
  children,
  icon,
  title
}) => {
  
  return (
    <div className="card-info__container">
      <div className="card-info__container__header">
        <h4>{title}</h4>
        <span><img src={icon} alt="icon"/></span>
      </div>
      <div className="card-info__container__body">
        {children}
      </div>
    </div>
  )
}