import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import { IStoreTrainingMeeting, IStoreTrainingMeetingPresenceParams, ITrainingMeetingListParams, ITrainingMeetingListResponse, ITrainingMeetingPresenceParams, ITrainingMeetingPresenceResponse } from '../../interfaces/trainingMeeting/trainingMeeting.interface'
import api from '../Api'

export const storeTrainingMeeting = async (payload: IStoreTrainingMeeting) => {
    return await api.post(`/training-meeting/group/${payload.id_group}`, { ...payload })
}

export const getTrainingMeetingList = async ({ id_group, params }: ITrainingMeetingListParams) => {
    return await api.get<IResponseGeneric<ITrainingMeetingListResponse>>(`/training-meeting/group/${id_group}${params}`)
}

export const showTrainingMeetingPresence = async (params: ITrainingMeetingPresenceParams) => {
    return await api.get<ITrainingMeetingPresenceResponse>(`/training-meeting/group/${params.id_group}/training_meeting/${params.id_training_meeting}`) 
}

export const storePresence = async (params: IStoreTrainingMeetingPresenceParams) => {
    return await api.post(`/training-meeting/id_training_meeting/${params.id_training_meeting}`, { presence: params.presence })
}