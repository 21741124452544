import { Form, FormSelectProps } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { ISelectDefault } from "../../interfaces/form/form.interface";
import { FormCustom } from ".";

interface IFormSelect extends FormSelectProps {
    field: string
    options: ISelectDefault[]
    showError?: boolean
}

export const FormSelect: React.FC<IFormSelect> = ({
    field,
    showError = true,
    options = [],
    ...rest
}) => {
    const { register, formState: { errors} } = useFormContext()

    return (
        <>
            <Form.Select
                className="rounded-4"
                isInvalid={errors[field] ? true : false}
                {...register(field)}
                {...rest}
            >
                <option disabled selected></option>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </Form.Select>
            {
                showError ? (
                    <FormCustom.ErrorMessage field={field} />
                ) : ''
            }
        </>
    )
}