import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import { IDeleteRdnParams, IParamsShowRdn, IResponsePaginatorShow, IStoreRdnParams } from '../../interfaces/rdn/rdn.interface'
import api from '../Api'

export const storeRdn = async ({ id_group, id_user, meeting_date, guests }: IStoreRdnParams) => {
    return await api.post(`/rdn/group/${id_group}/user/${id_user}`, { meeting_date, guests })
}

export const showRdn = async ({ id_group, page, name }: IParamsShowRdn) => {
    return await api.get<IResponseGeneric<IResponsePaginatorShow>>(`/rdn/group/${id_group}`, { params: {page, name }})
}

export const deleteRdn = async ({ id_rdn, id_reason, id_user }:IDeleteRdnParams) => {
    return await api.delete(`/rdn/${id_rdn}/user/${id_user}/reason/${id_reason}` )
}

export const verificationRdn = async ({ id_group, id_user, meeting_date, guests  }: IStoreRdnParams) => {
    return await api.post<{rdnExist: boolean}>(`/rdn/verification`, { id_group, id_user, meeting_date, guests })
}