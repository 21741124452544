import { Button, Form, InputGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { loginFormData } from "../Login";
import useAuth from "../../../contexts/Auth";


interface ILoginFormProps {
  handleLanguage: string
}

export function LoginForm({ handleLanguage }: ILoginFormProps) {
  const { t } = useTranslation()
  const { handleSubmit, register } = useFormContext()
  const [showPass, setShowPass] = useState(true)

  const { Login } = useAuth()


  const togglePassword = () => {
    setShowPass(!showPass)
  }

  function onSubmit (data: any) {
    Login({ email: data.email, password: data.password, lang: handleLanguage })
  }
  return (
    <div className="mb-3">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label className="text-center text-primary">
            {t('email')}
          </Form.Label>
          <Form.Control {...register("email")} type="email" placeholder={t('email')} />
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="password"
        >
          <Form.Label className="text-primary">
            {t('password')}
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control {...register("password")} type={showPass ? "password" : "text"} placeholder={t('password')} />
            <InputGroup.Text onClick={togglePassword} >
              <div>
                {showPass ? <EyeSlash size={20} className="text-primary" /> : <Eye size={20} className="text-primary" />}
              </div>
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group
          className="mb-3"
          controlId="resend"
        >
          <p className="small">
            <Link className="text-primary" to="/forgot">
              {t('recoveryPassword')}
            </Link>
          </p>
        </Form.Group>
        <div className="d-grid">
          <Button variant="primary" type="submit">
            {t('login')}
          </Button>
        </div>
      </Form>
    </div>
  )
}