import { useParams } from "react-router-dom";
import { IDasboardImagesStoreParams, IDashboardImagesResponse } from "../../../../interfaces/dashboard/dashboard.interface";
import { useEffect, useState } from "react";
import { getImages, getImagesAdmin, storeImages } from "../../../../api/dashboard/dashboard";
import { userAdmin } from "../../../../utils/Helper";


export function useHomeScreen() {
  const { groupId } = useParams()

  const [imagesAdmin, setImagesAdmin] = useState<IDashboardImagesResponse[]>([])
  const [images, setImages] = useState<string[]>([])

  const fetchImagesAdmin = async () => {    
    getImagesAdmin(+groupId!).then((response) => {
      if (!response) return     
      setTimeout(() => {
        setImagesAdmin(response.data)
      }, 100)
    })        
  }

  const fetchImages = async () => {    
    getImages(+groupId!).then((response) => {
      if (!response) return     
      setTimeout(() => { 
        const urls = response.data.map((item) => {
          return item.url
        })
        setImages(urls)
      }, 100)
    })        
  }

  const saveImages = async ({
    file,    
    name,
    size,
  }: IDasboardImagesStoreParams): Promise<number> => {
    const { data } = await storeImages({
      file,
      id_group: +groupId!,
      name,
      size,
    })
    
    return data.id
  }
   
  useEffect(() => {
    if (userAdmin()) {
      fetchImagesAdmin()
    }
    fetchImages()
  }, [])

  return {
    imagesAdmin,
    saveImages,
    images
  }
}