import './presentationReport.css'
import { Button, Form, FormGroup } from "react-bootstrap";
import { FormCustom } from "../../../components/Form";
import { z } from 'zod'
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { LoadingReport } from '../components/LoadingReport';
import { useEffect, useState } from 'react';
import { IApresentationReportParams, IReportApresentationResponseAll, IReportApresentationWeekInfo } from '../../../interfaces/report/IApresentation';
import { getApresentationReport } from '../../../api/report/report';
import { searchFactory } from '../../../factory/searchFactory';
import useAuth from '../../../contexts/Auth';
import { getCurrency, isCurrency } from '../../../utils/Helper';

const presentationSchema = z.object({
  start: z.string().nonempty('Data inicial obrigatório'),
  end: z.string().nonempty('Data final obrigatório')
})

export type ApresentationFormData = z.infer<typeof presentationSchema>

export function PresentationReport() {

  const { t } = useTranslation()
  const { groupId } = useParams()
  const [isloading, setIsloading] = useState(false)
  const { infoUser } = useAuth()
  const [apresentation, setApresentation] = useState<IReportApresentationResponseAll>({} as IReportApresentationResponseAll)
  const [weekInfo, setWeekInfo] = useState<IReportApresentationWeekInfo>({} as IReportApresentationWeekInfo)

  const urlAvatarDefault = process.env.REACT_APP_PUBLIC_URL + '/assets/img/profile/avatar.png'

  const presentationForm = useForm<ApresentationFormData>({
    resolver: zodResolver(presentationSchema)
  })

  const fetchApresentation = async (params: IApresentationReportParams) => {
    setIsloading(true)
    
    const start = new Date(params.start)
    const end = new Date(params.end)
    var timeDiff = Math.abs(end.getTime() - start.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const paramsQuery = searchFactory().createUrlParams({
      start: params.start,
      end: params.end
    })
    const { data } = await getApresentationReport({
      id_group: params.id_group,
      paramsQuery
    })

    if (data) {
      const week = Math.floor(diffDays / 7)
      const divider = data.chair.total_member > 0 ? data.chair.total_member : 1
      setWeekInfo({
        week,
        chair_value: +data.chair.total > 0 ? +data.chair.total / divider : 0,
        average: +data.chair.total > 0 ? +data.chair.total / (week > 0 ? week : 1) : 0
      })
      setApresentation(data)
    }
    setIsloading(false)
  }

  const onSubimit = async (data: ApresentationFormData) => {
    await fetchApresentation({
      id_group: +groupId!,
      end: data.end,
      start: data.start
    })
  }

  const {
    handleSubmit
  } = presentationForm

  useEffect(() => {
    setApresentation({} as IReportApresentationResponseAll)
    setWeekInfo({} as IReportApresentationWeekInfo)
  }, [infoUser.group_selected.name])

  return (
    <Form onSubmit={handleSubmit(onSubimit)}>
      <FormProvider {...presentationForm}>
        <div className="presentation-report__container">
          <div className="presentation-report__container__header">
            <h1>{t('values_aprensetation')}</h1>
            <FormGroup>
              <Form.Label>{t('start_date')}</Form.Label>
              <FormCustom.Control type="date" field="start" />
            </FormGroup>
            <FormGroup>
              <Form.Label>{t('end_date')}</Form.Label>
              <FormCustom.Control type="date" field="end" />
            </FormGroup>
            <div className='presentation-report__container__header__btn-header'>
              <Button
                variant="primary"
                type="submit"
              >
                {t("lookFor")}
              </Button>
            </div>
          </div>
          {
            !isloading ? (
              <>
                {
                  Object.hasOwn(weekInfo, 'week') && (
                    <div className="presentation-report__container__report_all">
                      <h3>{t('numbers_referring_period')}</h3>
                      <div className='presentation-report__container__report_all__wraper'>
                        <div className='presentation-report__container__report_all__left'>
                          <h4>{t('SUMMARY')}</h4>
                          <p>{t('Guest')}: {apresentation?.resume?.totalGuest}</p>
                          <p>{t('RDN')}: {apresentation?.resume?.totalRdn}</p>
                          <p>{t('INDICATIONS')}: {apresentation?.resume?.totalIndication}</p>
                          <p>{t('BUSINESS_CLOSED')}: {apresentation?.resume?.totalBusinees}</p>
                        </div>
                        <div className='presentation-report__container__report_all__rigth'>
                          <h4>{t('chairValues')}</h4>
                          <p>{t('total')}: {getCurrency(+apresentation?.chair?.total)}</p>
                          <p>{t('weeks')}: {weekInfo?.week}</p>
                          <p>{t('average_per_week')}: {getCurrency(weekInfo?.average)}</p>
                          <p>{t('chair_value')}: {getCurrency(weekInfo?.chair_value)}</p>
                        </div>
                      </div>
                    </div>
                  )
                }
                <div className="presentation-report__container__report">
                  {
                    apresentation?.ranking?.map((item) => (
                      <div className="presentation-report__container__report__card">
                        <h3>{t(item.internal_name).toUpperCase()}</h3>
                        <div className="presentation-report__container__report__card__img">
                          <img src={item.photo_url ? item.photo_url : urlAvatarDefault} alt="" />
                        </div>
                        <div className="presentation-report__container__report__wraper">
                          {
                            item.ranking.map((rank, index) => {
                              const length = item.ranking.length - 1
                              return (
                                <>
                                  <div className="presentation-report__container__report__card__ranking">
                                    <div><span>{t('member')}: {rank.name} ({infoUser.group_selected.name})</span></div>
                                    <div><span>{t('result')}: {isCurrency(rank.total)}</span></div>
                                  </div>
                                  {
                                    index !== length && (
                                      <hr />
                                    )
                                  }
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </>
            ) : (
              <LoadingReport />
            )
          }
        </div>
      </FormProvider>
    </Form>
  );
}
