import { Card, Container } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const Contact: React.FC = () => {

    const { t } = useTranslation()

    return (
        <Container className="mt-3">
            <Card>
                <Card.Img variant="top" className="" src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/contact/support.jpg`} />
                <Card.Body className="bg-white">
                    <Card.Title>
                        {t('supportTitle')}
                    </Card.Title>
                    <Card.Text>
                        {t('supportDescription')}
                    </Card.Text>
                </Card.Body>
                <Card.Footer className="text-white">
                    <span className="fw-bold pe-2">
                        Email:
                    </span>
                    <a className="text-white" href="mailto:nexus@itaccept.zohodesk.com">nexus@itaccept.zohodesk.com</a>
                </Card.Footer>
            </Card>
        </Container>
    )
}

export default Contact