import './point.css'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Tab, Tabs, Form } from 'react-bootstrap'
import { PointCard } from './components/PointCard';
import { z } from 'zod';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getScore, updateScore } from '../../../api/score/score';
import { useParams } from 'react-router-dom';
import { IPunctuationResponseAll } from '../../../interfaces/score/score.interface';
import { useTranslation } from 'react-i18next';
import useGuard from '../../../contexts/Guard';

const createPointSchema = z.object({
    config: z.array(
        z.object({
            internal_name: z.string(),
            recurrence: z.coerce.number().optional(),
            configs: z.array(
                z.object({
                    punctuation_x: z.coerce.number(),
                    punctuation_y: z.coerce.number()
                })
            ),
        })
    )
})

export type CreatePointFormData = z.infer<typeof createPointSchema>

const Point: React.FC = () => {
    const { t } = useTranslation();
    const { groupId } = useParams();
    const [activeTab, setActiveTab] = useState<number>(0);
    const [score, setScore] = useState<IPunctuationResponseAll[]>([]);
    const { CheckPermissionWithArray } = useGuard()

    const formPoints = useForm<CreatePointFormData>({
        resolver: zodResolver(createPointSchema)
    })

    const { handleSubmit, control } = formPoints

    const { append, fields } = useFieldArray({
        control,
        name: 'config'
    })

    const getScoreApi = () => {
        getScore(+groupId!).then((response) => {
            setScore(response.data)
            buildFields(response.data)
        })
    }

    const buildFields = (score: IPunctuationResponseAll[]) => {
        score.forEach((item) => {
            append({
                internal_name: item.internal_name,
                recurrence: item.recurrence,
                configs: item.configs.map((config) => ({
                    punctuation_x: +config.punctuation_x,
                    punctuation_y: +config.punctuation_y,
                }))
            })
        })        
    }

    const canUpdate = (): boolean => {
        return CheckPermissionWithArray(['point.update'])
    }

    const onSubmit = (data: CreatePointFormData) => {        
        updateScore({
            id_group: +groupId!,
            payload: data
        }).then(() => {})
    }

    useEffect(() => {
        getScoreApi()
    }, [groupId])

    return (
        <FormProvider {...formPoints}>
            <div className='m-2'>
                <Container fluid>
                    <Row className='border border-secondary border-2 rouded pt-4'>
                        <Col>
                            <Tabs
                                activeKey={activeTab}
                                defaultActiveKey={0}
                                onSelect={(k) => setActiveTab(Number(k))}
                                transition={false}
                                className='mb-3'
                            >
                                <Tab eventKey={0} title='Parametrizar Pontuação'>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <div className='score-point__container'>
                                            {
                                                fields.map((item, index) => (
                                                    <PointCard
                                                        key={index}
                                                        title={item.internal_name}
                                                        configs={item.configs}
                                                        indexField={index}
                                                        recurrence={item.recurrence ?? null}
                                                    />
                                                ))
                                            }
                                        </div>

                                        <Row className="justify-content-md-end">
                                            <Col xl={3} sm={3} md={3} className="mt-3">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    className="float-end mb-3 mr-1 m-1"
                                                    disabled={!canUpdate()}
                                                >
                                                    {t("submit")}
                                                </Button>                                               
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>
        </FormProvider>
    )
}

export default Point