import { Button, Col } from "react-bootstrap"
import { ITableComponentCols, TableComponent } from "../../../components/Table/TableComponent"
import { IListMeetingWeekResponse } from "../../../interfaces/meetingWeek/meetingWeek.interface"
import { getDateLocale } from "../../../utils/Helper"
import { Link } from "react-router-dom"
import { TableCustomComponent } from "../../../components/Table"
import { IMeta } from "../../../interfaces/generic/generic.interface"
import { IPaginationsCallback } from "../../../components/Table/PaginationComponent"
import useGuard from "../../../contexts/Guard"

interface IWeekMeetingList {
    data: any[]
    meta: IMeta
    callbackPagination: (params: IPaginationsCallback) => void    
}

export const ListWeekMeeting: React.FC<IWeekMeetingList> = ({
    data,
    meta,
    callbackPagination,    
}) => {
    const columns: ITableComponentCols[] = [
        {
            title: 'Data',
            render: (row:IListMeetingWeekResponse) => (
                <span>{getDateLocale(row.date, 'pt-BR')}</span>
            )
        },
        {
            title: 'Membros presentes',
            render: (row:IListMeetingWeekResponse) => (
                <span>{row.present_users}</span>            
            )
        },
        {
            title: 'Convidados presentes',
            render: (row:IListMeetingWeekResponse) => (
                <span>{row.guests_present}</span>                        
            )
        }, 
        {
            title: 'Ações',
            permissions: ['weekly-meetings.presence'],
            render: (row:IListMeetingWeekResponse) => (
                <>
                    { row.disabled ? (
                        <Link to={`presence/week-meeting/${row.id_meeting_week}`}>
                            <Button className="button-show" variant="primary">Ver</Button>
                        </Link>
                    ) : (
                        <Link to={`presence/week-meeting/${row.id_meeting_week}`}>
                            <Button variant="primary">Presença</Button>
                        </Link>                        
                    ) }
                </>
            )
        }
    ]

    return (
        <>
            <TableCustomComponent.Root>
                <Col>
                    <TableCustomComponent.Table 
                        cols={columns}
                        data={data}
                        striped={true}
                    />
                </Col>
                <Col>
                    <TableCustomComponent.Pagination 
                        callback={callbackPagination}
                        {...meta}
                    />
                </Col>
            </TableCustomComponent.Root>           
        </>
    )
}