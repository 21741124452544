import React from "react";
import './forgotPassword.css'
import { Button, Form, FormGroup } from "react-bootstrap";
import { FormCustom } from "../../components/Form";
import { z } from 'zod'
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { forgotPassword } from "../../api/forgotPassword/forgotPassword";

const schemaForgotPassword = z.object({
  email: z.string().email()
})

type IForgotPasswordFormData = z.infer<typeof schemaForgotPassword>

export const ForgotPassword: React.FC = () => {

  const forgotForm = useForm<IForgotPasswordFormData>({
    resolver: zodResolver(schemaForgotPassword)
  })

  const onSubmit = async ({ email }:IForgotPasswordFormData) => {
    await forgotPassword({email})    
  }

  const {
    handleSubmit
  } = forgotForm

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...forgotForm}>
        <div style={{
          backgroundImage: "url(/assets/img/login/nexus-bg.jpg)",
          minHeight: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }} className="forgot_container">
          <div className="forgot_container_card">
            <div className="forgot_container_log">
              <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/login/nexus-logo.png`} />
            </div>
            <p>Digite seu e-mail. Você receberá um e-mail com instruções sobre como redefinir a sua senha</p>
            <FormGroup>
              <FormCustom.Control field="email" />
            </FormGroup>
            <div className="forgot_btn">
              <Button
                type="submit"
                variant="primary"
              >
                Obter nova senha
              </Button>
            </div>
          </div>
        </div>
      </FormProvider>
    </Form>
  )
}