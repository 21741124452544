import { Button, ButtonProps } from "react-bootstrap"

interface ModalActionsProps extends ButtonProps {
    children: React.ReactNode
}

export const ModalActions: React.FC<ModalActionsProps> = ({
    children,
    ...props
}) => {
    return (
        <Button
            {...props}
        >
            {children}
        </Button>
    )
}