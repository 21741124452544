import React, { createContext, useContext, useEffect, useState } from 'react'
import api from '../api/Api'
import { ILogin } from '../interfaces/login/ILogin'
import { IAuthContext } from '../interfaces/login/IAuthContext'
import i18n from '../i18n/I18n'
import { catchToast } from '../utils/Helper'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { IAuthGroup, IAuthUserPayload, IResponseTokeLogin } from '../interfaces/user/IUser'

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

export const AuthProvider: React.FC<any> = ({ children }: any) => {

    const [infoUser, setInfoUser] = useState<IAuthUserPayload>( {} as IAuthUserPayload)
    const [groups, setGroups] = useState<IAuthGroup[]>([])
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const Login = ({ email, password, lang }: ILogin) => {
        api.post(
            '/login',
            {
                email: email,
                password: password,
                lang: lang
            }
        ).then((response) => {            
            const { data } = response.data
           
            if (!data) {
                setShowModal(true)
                return
            }

            setInfoUser(data)
            ChangeLanguage(data.user.lang)
            setGroups(data.group)            
            api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token.token}`
            localStorage.setItem('@App:user', JSON.stringify(data))
            localStorage.setItem('@App:token', response.data.token.token)
        }).catch((error: AxiosError) => {
            catchToast(error)
        })
    }

    const ChangeLanguage = (lang: string) => {
        const languages = [
            { name: "pt-BR", key: "BR" },
            { name: "pt-PT", key: "PT" },
            { name: "es-ES", key: "ES" },
            { name: "en-US", key: "US" },
        ]

        const selectedLang = languages.find((l) => {
            return l.key === lang
        })

        if (selectedLang) {
            i18n.changeLanguage(selectedLang.name)
        }
    }


    const Logout = () => {
        localStorage.removeItem('@App:user')
        localStorage.removeItem('@App:token')
        setInfoUser({} as IAuthUserPayload)
    }

    useEffect(() => {
        const storagedUser = localStorage.getItem('@App:user')
        const storagedToken = localStorage.getItem('@App:token')

        if (storagedToken && storagedUser) {
            const storageUserParse: IAuthUserPayload = JSON.parse(storagedUser)
            setInfoUser(storageUserParse)
            setGroups(storageUserParse.group)            
            api.defaults.headers.common['Authorization'] = `Bearer ${storagedToken}`
        }
    }, [navigate])

    return (
        <AuthContext.Provider value={{
            signed: Boolean(infoUser.user), infoUser, Login, Logout, groups, ChangeLanguage, setInfoUser,
            showModal, handleCloseModal
        }}>
            {children}
        </AuthContext.Provider >
    )
}

export function useAuth() {
    return useContext(AuthContext)
}

export default useAuth