import './tableRootcomponent.css'
import { Container } from "react-bootstrap"
interface ITableRootComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

export const TableRootComponent: React.FC<ITableRootComponentProps> = ({
  children,
  ...props
}) => {
  return (
    <Container 
      fluid 
      className="table-responsive table-root-component"
      {...props}
    >
      {children}      
    </Container>
  )
}