import { useState } from "react";
import { Container, Row, Tab, Tabs } from "react-bootstrap"
import { ListBussines } from "./components/ListBussines";
import { useParams } from "react-router-dom";
import { useBussines } from "./hook/bussines";

export const Bussines: React.FC = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const { groupId } = useParams()
    const { bussines, fetchPaginationBussines, fetchSearhBussines } = useBussines(+groupId!)

    return (
        <div className="m-2">
        <Container fluid>
            <Row className="border border-secondary border-2 rounded p-4">
                <Tabs
                    activeKey={activeTab}
                    defaultActiveKey={0}
                    onSelect={(index) => setActiveTab(+index!)}
                    className="mb-3"
                >
                    <Tab eventKey={0} title="Listagem de Negócios">
                        <ListBussines 
                            data={bussines.data}
                            meta={bussines.meta}
                            callbackPaginator={fetchPaginationBussines}
                            callbackSearch={fetchSearhBussines}
                        />
                    </Tab>                   
                </Tabs>
            </Row>
        </Container>
    </div>
    )
}