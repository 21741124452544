import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import { IDeleteReasonParams, IGetReasonParams, IReasonResponse, IShowReasonParams, IUpdateReasonParams, iCreateReasonParams } from '../../interfaces/reason/reason.interface'
import api from '../Api'

export const getReason = async ({ groupId, params }: IGetReasonParams) => {
  return await api.get<IResponseGeneric<IReasonResponse>>(`group/${groupId}/reason${params}`)
}

export const saveReason = async ({ groupId, name }: iCreateReasonParams) => {
  return await api.post<IReasonResponse>(`group/${groupId}/reason`, { name })
}

export const showReason = async ({ groupId, idReason }: IShowReasonParams) => {
  return await api.get<IReasonResponse>(`group/${groupId}/reason/${idReason}`)
}

export const updateReason = async ({ groupId, idReason, name }: IUpdateReasonParams) => {
  return await api.put(`group/${groupId}/reason/${idReason}`, { name })
}

export const destroy = async ({ idReason, groupId }: IDeleteReasonParams) => {
  return await api.delete(`group/${groupId}/reason/${idReason}`)
}