import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import { IResponseSubChairs, IUpdateSubChairsParams } from '../../interfaces/sub-chair/IGetSubChairs'
import { ISaveSubChair } from '../../interfaces/sub-chair/ISaveSubChair'
import api from '../Api'

export const getSubChairs = async (groupId: number, chairId: number, params: string = '') => {
    let url = `group/${groupId}/subChair/chair/${chairId}`
    if (params) {
        url += params
    }
    return await api.get<IResponseGeneric<IResponseSubChairs>>(url)
}

export const getSubChairList = async(chairId: number) => {
    let url = `list/subchair/${chairId}`
    return await api.get<IResponseSubChairs[]>(url)
}

export const saveSubChair = async ({ groupId, data }: ISaveSubChair) => {
    return await api.post(`group/${groupId}/subChair`, data)
}

export const updateSubChair = async ({ groupId, data }: IUpdateSubChairsParams) => {
    return await api.put(`group/${groupId}/subChair/${data.subChairId}`, data)
}
export const deleteSubChair = async (groupId: number, subCharId: number) => {
    return await api.delete(`group/${groupId}/subChair/${subCharId}`)
}




