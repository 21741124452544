import { ChangeEvent } from "react"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { StatusUserWithGuest } from "../../../../interfaces/enum/status.enum"
import { FormGroup } from "@mui/material"
import { RoleEnum } from "../../../../interfaces/enum/roles.enum"
import useAuth from "../../../../contexts/Auth"

type SelectGroupProps = {
  onChange: (value: ChangeEvent<HTMLSelectElement>) => void
  isDisable: boolean
}

export const SelectUserStatus = ({ onChange, isDisable }: SelectGroupProps) => {
  const { t } = useTranslation()
  const { infoUser } = useAuth()

  let options = [
    { value: StatusUserWithGuest.MEMBER, label: t('member') },
    { value: StatusUserWithGuest.INACTIVE_MEMBER, label: t('inactive_member') },
    { value: StatusUserWithGuest.FORMER_MEMBER, label: t('former_member') },
    { value: StatusUserWithGuest.EXEMPT_MEMBER, label: t('EXEMPT_MEMBER') },
    { value: StatusUserWithGuest.GUEST, label: t('guest') },
  ]

  const isAdmOrDirector = [RoleEnum.ADMIN, RoleEnum.REGIONAL_DIRECTOR].includes(infoUser.role_selected.key.toUpperCase() as RoleEnum)
  const isMember = infoUser.role_selected.key === 'member'  

  if (isMember) {
    options = options.filter((item) => {
      
      if (item.value === StatusUserWithGuest.MEMBER || item.value === StatusUserWithGuest.GUEST) {
        return item
      }      
    })
  }

  const getDefaultOptionSelected = (value: string): boolean => {
    if (isAdmOrDirector) {
      return value === 'all'
    }

    return value === StatusUserWithGuest.MEMBER
  }


  if (isAdmOrDirector) {
    !options.some(item => item.value === 'all' as StatusUserWithGuest) &&
      options.unshift({
        value: 'all' as StatusUserWithGuest,
        label: t('all')
      })
  }

  return (
    <FormGroup className="d-flex align-items-start mt-3 w-30 mx-2">
      <Form.Label>
        {t('filterByUserStatus')}
      </Form.Label>
      
      <Form.Select
        onChange={onChange}
        disabled={isDisable}
      >
        {
          options.map(item => (
            <option 
              key={item.value} 
              value={item.value}
              selected={getDefaultOptionSelected(item.value)}
            >
              { item.label }
            </option>
          ))
        }
      </Form.Select>
    </FormGroup>
  )
}