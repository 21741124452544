import html2canvas from "html2canvas"
import { downloadImage } from "../../utils/Helper";
import { Button } from "react-bootstrap";

type IButtonPrintScreenProps = {
  text: string
  refElement: React.RefObject<HTMLElement>
  fileNameDownload: string
  isDisable?: boolean
}

export const ButtonPrintScreen = ({ text, refElement, fileNameDownload, isDisable }: IButtonPrintScreenProps) => {
    const handleCapture = async () => {
      const canvas = await html2canvas(refElement.current!);
      const dataURL = canvas.toDataURL();
      downloadImage(dataURL, fileNameDownload);
    };

    return (
      <Button
        variant="primary"
        type="submit"
        className="fw-semibold text-uppercase"
        onClick={handleCapture}
        disabled={isDisable}
      >
        <span>
          {text}
        </span>
      </Button>
    )
}