import { IResponseGeneric } from '../../interfaces/generic/generic.interface'
import { IGetDateOptionsResponse, IGuestGetParams, IGuestMembers, IGuestShowResponse, IReinviteGuest, IStoreGuests } from '../../interfaces/guests/guests.interface'
import api from '../Api'

export const storeGuests = async (payload: IStoreGuests) => {
    return await api.post(`/guests/group/${payload.id_group_created}`, { ...payload })
}

export const getOptionsDate = async (group_id: number) => {
    return await api.get<IGetDateOptionsResponse[]>(`/guests/group/${group_id}/date`)
}

export const getGuestsList = async ({ group_id, params }: IGuestGetParams) => {
    return await api.get<IResponseGeneric<IGuestShowResponse>>(`/guests/group/${group_id}${params}`)
}

export const getGuestNotBecomeMember = async (group_id: number) => {
    return await api.get<IGuestMembers[]>(`/guests/group/${group_id}/members`)
}

export const guestReinvite = async ({ 
    id_group, 
    id_group_created, 
    meeting_day, 
    id_guest 
}: IReinviteGuest) => {
    return await api.post(`/guests/group/${id_group_created}/reinvite/${id_guest}`, {
        id_group,
        meeting_day
    })
}