import { useTranslation } from "react-i18next"

export const LoadingReport = () => {
    const { t } = useTranslation()

    return (
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <div className="spinner-border text-warning mt-5" style={{ width: '3rem', height: '3rem' }} />

            <span className="mt-3 fw-semibold fs-5">
                { t('waitingLoaging') }
            </span>
        </div>
    )
}