import { Carousel } from '../../../../components/Corousel/Corousel';
import { useDashboardContext } from '../../../../contexts/Dashboard';
import { getCurrency } from '../../../../utils/Helper';
import { useNavigate, useParams } from "react-router-dom"

import './mainDashboard.css';

export const MainDashboard: React.FC = () => {

  const { overallPerformance, images } = useDashboardContext()
  const navigate = useNavigate()
  const { groupId } = useParams()

  const redirect = (url: string) => {
    navigate(url)
  }
  
  return (
    <>
      <div className='main-dashboard-container'>
        <h2>Desempenho Geral</h2>

        <div onClick={() => redirect(`/grupo/${groupId}/bussines`)} className='main-dashboard-container__cards'>

          <div className='main-dashboard-container__cards__container__left'>
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/honesty.svg`} alt="icon" />
          </div>
          <div className='main-dashboard-container__cards__container__rigth'>
            <div className='main-dashboard-container__cards__container__rigth__info'>
              <p>Negócios Fechados (Quantidade)</p>
              <p>{overallPerformance.businees_closed_quantity}</p>
            </div>
            <div className='main-dashboard-container__cards__container__rigth__info'>
              <p>Negócios Fechados (Valor)</p>
              <p>{getCurrency(overallPerformance.businees_closed_valor)}</p>
            </div>
          </div>

        </div>

        <div onClick={() => redirect(`/grupo/${groupId}/indicacoes`)} className='main-dashboard-container__cards'>

          <div className='main-dashboard-container__cards__container__left'>
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/up-sign.svg`} alt="icon" />
          </div>
          <div className='main-dashboard-container__cards__container__rigth'>
            <div className='main-dashboard-container__cards__container__rigth__info'>
              <p>Quantidade de Indicações (Quantidade)</p>
              <p>{overallPerformance.indications}</p>
            </div>
            <div className='main-dashboard-container__cards__container__rigth__info'>
              <p>Indicações de Negócios fechados (Quantidade)</p>
              <p>{overallPerformance.indications_businnes_closed_quantity}</p>
            </div>
            <div className='main-dashboard-container__cards__container__rigth__info'>
              <p>Indicações de Negócios fechados (Valor)</p>
              <p>{getCurrency(overallPerformance.indications_businnes_closed_valor)}</p>
            </div>
          </div>

        </div>

        <div onClick={() => redirect(`/grupo/${groupId}/convidados`)} className='main-dashboard-container__cards'>

          <div className='main-dashboard-container__cards__container__left'>
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/networking.svg`} alt="icon" />
          </div>
          <div className='main-dashboard-container__cards__container__rigth'>
            <div className='main-dashboard-container__cards__container__rigth__info'>
              <p>Quantidade de Convidados</p>
              <p>{overallPerformance.guests}</p>
            </div>
            <div className='main-dashboard-container__cards__container__rigth__info'>
              <p>Quantidade de convidados convertido em membro</p>
              <p>{overallPerformance.guest_return_member}</p>
            </div>
          </div>

        </div>

        <div onClick={() => redirect(`/grupo/${groupId}/rdn`)} className='main-dashboard-container__cards'>

          <div className='main-dashboard-container__cards__container__left'>
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/talk.svg`} alt="icon" />
          </div>
          <div className='main-dashboard-container__cards__container__rigth'>
            <div className='main-dashboard-container__cards__container__rigth__info'>
              <p>Quantidade de RDN</p>
              <p>{overallPerformance.rdn}</p>
            </div>
            <div className='main-dashboard-container__cards__container__rigth__info'>
              <p>Quantidade membros indicados</p>
              <p>{overallPerformance.member_indications}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='main-dashboard-container__carousel'>
        {
          images.length > 0 && (                   
            <Carousel urls={images} />
          )
        }
      </div>
    </>
  )
}