import React from 'react';
import './Alert.css';

export const Alert: React.FC = () => {
  return (
    <div className='alert-container'>
      <div className="alert-container__header">
        <span>Aviso Nexus</span>
        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/icon/bell.svg`} alt="icon" />
      </div>
      <p>Agende ao menos duas RDNs por semana.</p>
      <p>Lance sempre a produção da semana.</p>
      <p>Faça indicações qualificadas para os membros.</p>
    </div>
  )
}