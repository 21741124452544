export enum RankingType {
  ACTUAL_MONTHLY = 'current_month',
  ONE_MONTHLY = 'one_month',
  TWO_MONTHLY = 'two_months',
  THREE_MONTHLY = 'three_months',
  FOUR_MONTHLY = 'four_months',
  FIVE_MONTHLY = 'five_months',
  SIX_MONTHLY = 'six_months',
  YEARLY = 'yearly',
  SEMI_ANNUAL = 'semi-annual',
  QUARTER = 'quarter'
}

export interface IRank {
  name: string
  point: number
}

export interface IRankingReport {
  period: string
  items: IRank[]
}

export interface IRankingReportResponse {
  data: IRankingReport
}

export interface IGetRankMonthlyReport {
  groupId: number
  type: RankingType
}