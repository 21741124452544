import { Modal } from "react-bootstrap"

interface ModalContentProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode
} 

export const ModalContent: React.FC<ModalContentProps> = ({
    children,
    ...props
}) => {
    return (        
        <Modal.Body
            {...props}
        >
            {children}
        </Modal.Body>        
    )
}