import React, { useState, useEffect, useCallback } from 'react'
import './styles/editChair.css'

import { useParams } from 'react-router-dom'
import { Button, Col, Container, Form, } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { getChair, updateChair } from '../../../api/chair/Chair'
import { deleteSubChair, getSubChairs, saveSubChair, updateSubChair } from '../../../api/sub-chair/SubChair'
import ConfirmationModal from '../../../components/custom/ConfirmationModal'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, FormProvider } from 'react-hook-form'
import { FormCustom } from '../../../components/Form'
import { IChairStorage, ISubChairResponse } from '../../../interfaces/chair/IGetChairs'
import { IResponseGeneric } from '../../../interfaces/generic/generic.interface'
import { IEditSubchairFormData, ModalEditSubChair } from './components/ModaEditSubChair'
import useGuard from '../../../contexts/Guard'
import { TableCustomComponent } from '../../../components/Table'
import { ITableComponentCols } from '../../../components/Table/TableComponent'
import { IPaginationsCallback } from '../../../components/Table/PaginationComponent'
import { ITableSearchFormData } from '../../../components/Table/TableSearchComponent'
import { searchFactory } from '../../../factory/searchFactory'

const editChairSchema = z.object({
    name: z.string({ required_error: 'form_name_required' }).nonempty('form_name_required')
})

const createSubChairSchema = z.object({
    chairId: z.coerce.number(),
    name: z.string({ required_error: 'form_subchairs_name_required' }).nonempty('form_subchairs_name_required')
})

type IEditChairFormData = z.infer<typeof editChairSchema>
type ICreateSubChairFormData = z.infer<typeof createSubChairSchema>

const EditChair: React.FC = () => {
    const { t } = useTranslation()
    const { groupId, chairId } = useParams()
    const [data, setData] = useState<IChairStorage>({} as IChairStorage)
    const [dataSubChair, setDataSubChair] = useState<IResponseGeneric<ISubChairResponse>>({} as IResponseGeneric<ISubChairResponse>)
    const [showModal, setShowModal] = useState(false);
    const [showModalEditSubchair, setShowModalEditSubchair] = useState(false);
    const [subChair, setSubChair] = useState<ISubChairResponse>({} as ISubChairResponse);
    const [searchParams, setSearchParams] = useState('')
    const { CheckPermissionWithArray } = useGuard()

    const columns: ITableComponentCols[] = [
        {
            title: t('subChairName'),
            render: (data: ISubChairResponse) => (
                <p>{data.name}</p>
            )
        },
        {
            title: 'Ações',
            permissions: ['subChair.delete', 'subChair.update'],
            render: (data: ISubChairResponse) => (
                <>
                    <div>
                        {CheckPermissionWithArray(['subChair.update']) && (
                            <Button
                                variant="primary"
                                type="button"
                                className="me-4"
                                onClick={() => {
                                    editSubChair(data)
                                }}
                            >
                                {t('actionEdit')}
                            </Button>
                        )}
                        {CheckPermissionWithArray(['subChair.delete']) && (
                            <Button
                                variant="danger"
                                type="button"
                                onClick={() => {
                                    openModalConfirmation(data)
                                }}
                            >
                                {t('actionDelete')}
                            </Button>
                        )}
                    </div>
                </>
            )
        }
    ]

    const editChairForm = useForm<IEditChairFormData>({
        resolver: zodResolver(editChairSchema)
    })

    const createSubchairForm = useForm<ICreateSubChairFormData>({
        resolver: zodResolver(createSubChairSchema)
    })

    const {
        register: registerEditChair,
        handleSubmit: handleSubmitEditChair,
        setValue: setValueEditChair
    } = editChairForm

    const {
        register: registerSubChair,
        handleSubmit: handleSubmitSubChair,
        setValue: setValueCreateSubChair,
    } = createSubchairForm

    const getData = useCallback(() => {
        getChair(Number(groupId), Number(chairId)).then((response) => {
            setData(response.data)
            setValueEditChair('name', response.data.name)
            setValueCreateSubChair('chairId', response.data.id)
        })
    }, [groupId, chairId])

    const getDataSubChair = useCallback(() => {
        getSubChairs(Number(groupId), Number(chairId)).then((response) => {
            setDataSubChair(response?.data)
        });
    }, [groupId, chairId])

    const getDataSubChairPaginator = (meta: IPaginationsCallback) => {
        getSubChairs(Number(groupId), Number(chairId), searchFactory().createUrlParams({
            page: meta.page,
            name: searchParams
        })).then((response) => {
            setDataSubChair(response?.data)
        });
    }

    const getDataSubChairSearch = (meta: ITableSearchFormData) => {
        setSearchParams(meta.search)
        getSubChairs(Number(groupId), Number(chairId), searchFactory().createUrlParams({
            name: meta.search
        })).then((response) => {
            setDataSubChair(response?.data)
        });
    }

    const openModalConfirmation = (row: any) => {
        setSubChair(row);
        setShowModal(true);
    }

    function handleDecline() {
        setShowModal(false);
        setSubChair({} as ISubChairResponse);
    }

    const handleSubChairDelete = () => {
        deleteSubChair(Number(groupId), subChair.id).then(() => {
            setSubChair({} as ISubChairResponse);
            setShowModal(false);
            getDataSubChair()
        })
    }

    const editSubChair = (row: ISubChairResponse) => {
        setSubChair(row)
        setShowModalEditSubchair(true)
    }

    const onSubmitEditChair = (event: IEditChairFormData) => {
        updateChair({ groupId: +groupId!, chairId: data.id, data: { name: event.name } })
            .then((response) => {
                console.log(response.data)
            })
    }

    const onSubmitCreateSubChair = async (event: ICreateSubChairFormData) => {
        saveSubChair({
            groupId: +groupId!, data: {
                name: event.name, chairId: data.id
            }
        }).then(() => {
            getDataSubChair()
        })
    }

    const onCancelEditSubChair = () => {
        setShowModalEditSubchair(false)
    }

    const onSaveEditSubChair = async (payload: IEditSubchairFormData) => {
        setShowModalEditSubchair(false)
        updateSubChair({
            groupId: +groupId!,
            data: {
                name: payload.name,
                subChairId: subChair.id
            }
        }).then(() => {
            setSubChair({} as ISubChairResponse)
            getDataSubChair()
        })
    }

    useEffect(() => {
        getData()
        getDataSubChair()
    }, [getData, getDataSubChair])

    const hasUpdatePermission = CheckPermissionWithArray(['chair.update'])

    return (
        <>
            <div className="vh-100 edit-chair">
                <Container fluid>
                    <Form onSubmit={handleSubmitEditChair(onSubmitEditChair)}>
                        <div className='chair'>
                            <FormProvider {...editChairForm}>
                                <Form.Group>
                                    <Form.Label htmlFor='name_chair'>{t('chair')}</Form.Label>
                                    <Form.Control disabled={!hasUpdatePermission} type='text' id='name_chair' {...registerEditChair('name')} />
                                    <FormCustom.ErrorMessage field='name' />
                                </Form.Group>
                                <div className='btn-edit-chair'>
                                    <Button disabled={!hasUpdatePermission} style={{ width: '95px' }} variant="primary" type="submit">{t('actionEdit')}</Button>
                                </div>
                            </FormProvider>
                        </div>
                    </Form>
                </Container>
                {CheckPermissionWithArray(['subChair.store']) && (
                    <Container fluid>
                        <Form onSubmit={handleSubmitSubChair(onSubmitCreateSubChair)} >
                            <div className='subchair'>
                                <FormProvider {...createSubchairForm}>
                                    <Form.Group>
                                        <Form.Label htmlFor='subchair'>{t('subchair')}</Form.Label>
                                        <Form.Control type='text' id='subchair' {...registerSubChair('name')} />
                                        <FormCustom.ErrorMessage field='name' />
                                    </Form.Group>
                                    <div className='btn-create-subchair'>
                                        <Button style={{ width: '95px' }} variant="primary" type="submit" >{t('add')}</Button>
                                    </div>
                                </FormProvider>
                            </div>
                        </Form>
                    </Container>
                )}

                {CheckPermissionWithArray(['subChair.delete', 'subChair.update', 'subChair.store', 'subChair.index']) && (
                    <div>
                        <TableCustomComponent.Root>
                            <Col>
                                <TableCustomComponent.Search callback={getDataSubChairSearch} />
                            </Col>
                            <Col>
                                <TableCustomComponent.Table
                                    striped
                                    cols={columns}
                                    data={dataSubChair?.data}
                                />
                            </Col>
                            <Col>
                                <TableCustomComponent.Pagination
                                    {...dataSubChair.meta}
                                    callback={getDataSubChairPaginator}
                                />
                            </Col>
                        </TableCustomComponent.Root>
                    </div>
                )}
            </div >

            <ConfirmationModal
                message={t("messageConfirmationModal")}
                show={showModal}
                onConfirm={handleSubChairDelete}
                onDecline={handleDecline}
            />

            <ModalEditSubChair
                show={showModalEditSubchair}
                infoSubChair={subChair}
                onCancel={onCancelEditSubChair}
                onSave={onSaveEditSubChair}
            />
        </>
    )
}

export default EditChair